<div class="relative" [ngClass]="{ 'h-fit w-fit': SizeStrategy.FIT, 'h-full w-full': SizeStrategy.FULL }">
    <div [ngClass]="{ 'cursor-pointer': link(), 'h-fit w-fit': SizeStrategy.FIT, 'h-full w-full': SizeStrategy.FULL }" (click)="openLink()">
        @if (media(); as media) {
            @switch (media.type) {
                @case (MediaType.PHOTO) {
                    <ng-container [ngTemplateOutlet]="imageMedia" [ngTemplateOutletContext]="{ imageUrl: media.url }"></ng-container>
                }
                @case (MediaType.VIDEO) {
                    <ng-container [ngTemplateOutlet]="videoMedia" [ngTemplateOutletContext]="{ media }"></ng-container>
                }
            }
        } @else {
            <ng-container
                [ngTemplateOutlet]="imageMedia"
                [ngTemplateOutletContext]="{ imageUrl: 'default_post' | imagePathResolver }"></ng-container>
        }
    </div>
    @if (icon(); as icon) {
        <div class="absolute left-2 top-2 flex h-fit w-fit rounded-sm bg-malou-color-background-text-1/70">
            <mat-icon class="!h-4 !w-4 !fill-white" [ngClass]="customIconClass()" [svgIcon]="icon"> </mat-icon>
        </div>
    }

    @if (statusIcon(); as statusIcon) {
        <div
            class="absolute right-2 top-2 flex h-fit w-fit items-center justify-center rounded-sm p-[2px]"
            [ngClass]="customStatusIconContainerClass()">
            <mat-icon class="!h-3.5 !w-3.5 !fill-white" [ngClass]="customStatusIconClass()" [svgIcon]="statusIcon"> </mat-icon>
        </div>
    }

    @if (tag(); as tag) {
        <div class="absolute bottom-2 left-0 flex w-full justify-center px-2">
            <div
                class="malou-text-9--semibold flex w-full cursor-pointer items-center justify-center rounded-[3px] bg-malou-color-primary px-2 py-1 text-white"
                (click)="onTagClick()">
                {{ tag }}
            </div>
        </div>
    }
</div>

<ng-template let-media="media" #videoMedia>
    @let mediaUrl = media.url;

    @if (mediaUrl) {
        <div class="relative h-full w-full">
            @if (media.thumbnailUrl; as thumbnailUrl) {
                @if (shouldLoadVideo()) {
                    <video
                        class="h-full w-full !touch-none object-cover"
                        preload="none"
                        playsinline
                        muted
                        [poster]="thumbnailUrl"
                        [ngClass]="customMediaClass()">
                        <source
                            type="video/mp4"
                            [src]="mediaUrl"
                            (error)="refresh($event)"
                            (loadedmetadata)="onLoadedVideoMetadata($event)" />
                    </video>
                } @else {
                    <ng-container [ngTemplateOutlet]="imageMedia" [ngTemplateOutletContext]="{ imageUrl: thumbnailUrl }"></ng-container>
                }
            } @else {
                <video
                    class="h-full w-full !touch-none object-cover"
                    preload="metadata"
                    playsinline
                    muted
                    [ngClass]="customMediaClass()"
                    (loadedmetadata)="onLoadedVideoMetadata($event)">
                    <source type="video/mp4" [src]="mediaUrl + '#t=0.1'" (error)="refresh($event)" />
                </video>
            }
            @if (showVideoControls()) {
                <div class="absolute bottom-2 left-2 grid place-items-center rounded-full" matRipple (click)="playMedia($event)">
                    <mat-icon class="!h-[30px] !w-[30px] !text-[30px] text-white"> play_arrow </mat-icon>
                </div>
            }
        </div>
    } @else {
        @if (media.thumbnailUrl; as thumbnailUrl) {
            <img
                class="h-full w-full !touch-none object-cover"
                alt="{{ 'posts.image_alt' | translate }}"
                [ngClass]="customMediaClass()"
                [src]="thumbnailUrl" />
        } @else {
            <img
                class="h-full w-full !touch-none object-cover"
                alt="{{ 'posts.image_alt' | translate }}"
                [ngClass]="customMediaClass()"
                [lazyLoad]="'default_post' | imagePathResolver" />
        }
    }
</ng-template>

<ng-template let-imageUrl="imageUrl" #imageMedia>
    @if (shouldLazyLoadMedia()) {
        <img
            class="h-full w-full !touch-none object-cover"
            alt="{{ 'posts.image_alt' | translate }}"
            matTooltipClass="multiline-tooltip"
            [defaultImage]="'default_post' | imagePathResolver"
            [lazyLoad]="imageUrl"
            [ngClass]="customMediaClass()"
            [matTooltip]="tooltipText()"
            [matTooltipDisabled]="!tooltipText()"
            (onStateChange)="refreshIfFailed($event)" />
    } @else {
        <img
            class="h-full w-full !touch-none object-cover"
            alt="{{ 'posts.image_alt' | translate }}"
            matTooltipClass="multiline-tooltip"
            [src]="imageUrl"
            [ngClass]="customMediaClass()"
            [matTooltip]="tooltipText()"
            [matTooltipDisabled]="!tooltipText()" />
    }
</ng-template>
