import { Pipe, PipeTransform } from '@angular/core';

export enum Illustration {
    NotFound = '404',
    Burger = 'Burger',
    Cape = 'Cape',
    Cles = 'Cles',
    Cook = 'Cook',
    Cutlery = 'Cutlery',
    Default = 'default-picture-grey',
    Goggles = 'Goggles',
    GogoolOk = 'Gogool_ok',
    GreyBurger = 'Grey-burger',
    Icecream = 'Icecream',
    KarlOk = 'Karl_ok',
    Karl = 'Karl',
    Placeholder = 'Placeholder',
    Plate = 'Plate',
    Screen = 'Screen',
    Stove = 'Stove',
    Taster = 'Taster',
    ManTaster = 'ManTaster',
    WheelOfFortune = 'wheel-of-fortune',
    Search = 'Search',
    Chef = 'Chef',
    Google = 'Google',
    Stars = 'Stars',
}

@Pipe({
    name: 'illustrationPathResolver',
    standalone: true,
})
export class IllustrationPathResolverPipe implements PipeTransform {
    transform(key: string): string {
        return `/assets/illustrations/${key}.png`;
    }
}
