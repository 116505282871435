import { FeedbackMessageVisibility, Role, SocialAttachmentsMediaTypes } from '../../constants';

export enum SocialPostsListFilter {
    ALL = 'all',
    DRAFT = 'draft',
    ERROR = 'error',
    FEEDBACK = 'feedback',
}

export enum CallToActionType {
    BOOK = 'BOOK',
    ORDER = 'ORDER',
    SHOP = 'SHOP',
    LEARN_MORE = 'LEARN_MORE',
    SIGN_UP = 'SIGN_UP',
    CALL = 'CALL',
    NONE = 'NONE',
}

export enum MapstrCtaButtonType {
    SEE = 'SEE',
    BOOK = 'BOOK',
    ORDER = 'ORDER',
    MENU = 'MENU',
    JOIN = 'JOIN',
    MORE = 'MORE',
}

export const postCallToActionTypeValues = Array.from(new Set([...Object.values(MapstrCtaButtonType), ...Object.values(CallToActionType)]));
export type PostCallToActionType = (typeof postCallToActionTypeValues)[number];
export interface PostCallToAction {
    actionType: PostCallToActionType;
    url: string;
}

export interface SocialPostCallToAction {
    actionType: MapstrCtaButtonType;
    url: string;
}

export const callToActionTypesWithDefaultUrls = [CallToActionType.CALL, CallToActionType.BOOK, CallToActionType.ORDER];

export enum PostSource {
    SOCIAL = 'social',
    SEO = 'seo',
}

export interface PostSocialAttachment {
    urls: {
        original: string;
    };
    socialId?: string;
    type: SocialAttachmentsMediaTypes;
    thumbnailUrl?: string;
}

export interface PostLocation {
    id: string;
    name: string;
    link: string;
    location?: {
        city?: string;
        country?: string;
        latitude: number;
        longitude: number;
        street?: string;
        zip?: string;
    };
}

export interface PostErrorData {
    code: string;
    rawData: string;
}

export interface PostFeedbacks {
    id: string;
    postId: string;
    isOpen: boolean;
    participants: {
        participant: PostFeedbacksUser;
        types: string[];
    }[];
    feedbackMessages: PostFeedbackMessage[];
}

export interface PostFeedbacksUser {
    id: string;
    name: string;
    lastname: string;
    profilePictureUrl?: string;
    email: string;
    role?: Role;
}

export interface PostFeedbackMessage {
    id: string;
    author: PostFeedbacksUser;
    createdAt: Date;
    text?: string;
    type: string;
    visibility: FeedbackMessageVisibility;
}
