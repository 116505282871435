<div class="flex flex-col px-8">
    <div class="flex items-center gap-x-2">
        <div class="flex gap-x-1">
            <span class="malou-text-18--bold">{{ 'admin.users.users' | translate }}</span>
            <span class="malou-text-18--regular">({{ dataSource.filteredData.length }})</span>
        </div>
        <div class="grow">
            <app-search [placeholder]="'common.search' | translate" (searchChange)="onSearchChange($event)"></app-search>
        </div>
        <div>
            <button
                class="malou-btn-raised--primary !h-12 md:!hidden"
                data-testid="admin-users-open-create-user-btn"
                mat-raised-button
                (click)="upsert(UpsertKind.INSERT)">
                {{ 'common.add' | translate }}
            </button>
        </div>
    </div>

    <ng-container [ngTemplateOutlet]="tableTemplate"></ng-container>
</div>

<ng-template #tableTemplate>
    <mat-table
        class="malou-mat-table"
        matSort
        matSortDisableClear
        [trackBy]="trackByIdFn"
        [dataSource]="dataSource"
        [matSortActive]="DEFAULT_SORT.active"
        [matSortDirection]="DEFAULT_SORT.direction"
        #table="matTable">
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'admin.users.name' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let user; table: table">
                {{
                    (user.name && user.name.length > 0 ? user.name : '-') +
                        ' ' +
                        (user.lastname && user.lastname.length > 0 ? user.lastname : '-')
                }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'admin.users.email' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let user; table: table">
                {{ user.email }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="role">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'admin.users.role' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let user; table: table">
                {{ user.role }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="organizations">
            <mat-header-cell *matHeaderCellDef>
                {{ 'admin.users.organizations' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let user; table: table">
                <div class="flex flex-row flex-wrap">
                    @for (
                        organization of user.organizations | slice: 0 : MAX_ITEMS_PER_COLUMN;
                        track trackByIdFn($index, organization);
                        let last = $last
                    ) {
                        <div>
                            {{ organization.name }}

                            @if (!last) {
                                ,&nbsp;
                            }
                            @if (last && user.organizations.length > MAX_ITEMS_PER_COLUMN) {
                                <span
                                    class="malou-text-13--semibold malou-color-text-primary"
                                    (click)="displayOrganizationListModal(user.organizations)"
                                    >+{{ user.organizations.length - MAX_ITEMS_PER_COLUMN }}</span
                                >
                            }
                        </div>
                    }
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="restaurants">
            <mat-header-cell *matHeaderCellDef>
                {{ 'admin.users.businesses' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let user; table: table">
                <div class="flex flex-row flex-wrap">
                    @for (
                        restaurant of user.restaurants | slice: 0 : MAX_ITEMS_PER_COLUMN;
                        track trackByIdFn($index, restaurant);
                        let last = $last
                    ) {
                        <div>
                            <a class="malou-color-primary malou-text-12--semibold" [routerLink]="'/restaurants/' + restaurant.restaurantId">
                                {{ restaurant.restaurantDetails.name }}
                            </a>

                            @if (!last) {
                                ,&nbsp;
                            }
                            @if (last && user.restaurants.length > MAX_ITEMS_PER_COLUMN) {
                                <span class="malou-text-13--semibold malou-color-text-primary" (click)="displayRestaurantsListModal(user)"
                                    >+{{ user.restaurants.length - MAX_ITEMS_PER_COLUMN }}</span
                                >
                            }
                        </div>
                    }
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="caslRole">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-small" mat-sort-header>
                {{ 'admin.users.casl_role' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let user; table: table" class="malou-mat-table-cell-small">
                {{ user.caslRole }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="active">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xsmall" mat-sort-header>
                {{ 'admin.users.active' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let user; table: table" class="malou-mat-table-cell-xsmall">
                <app-slide-toggle [checked]="user.verified" (onToggle)="updateActive(user._id, !$event)"> </app-slide-toggle>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xsmall" mat-sort-header>
                {{ 'admin.users.id' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let user; table: table" class="malou-mat-table-cell-xsmall">
                {{ user._id }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-fit-content"></mat-header-cell>
            <mat-cell *matCellDef="let user; table: table" class="malou-mat-table-cell-fit-content">
                <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.ELLIPSIS" [matMenuTriggerFor]="actionsMenu"></mat-icon>
                <mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #actionsMenu="matMenu">
                    <button class="flex gap-x-3" mat-menu-item (click)="upsert(UpsertKind.UPDATE, user)">
                        <mat-icon class="!h-4" color="primary" [svgIcon]="SvgIcon.EDIT"></mat-icon>
                        <span class="malou-text-14--regular">{{ 'common.edit' | translate }}</span>
                    </button>
                    <button class="flex gap-x-3" mat-menu-item (click)="changePassword(user._id)">
                        <mat-icon class="!h-4" color="primary" [svgIcon]="SvgIcon.EYE"></mat-icon>
                        <span class="malou-text-14--regular">{{ 'admin.users.change_password' | translate }}</span>
                    </button>
                    <button class="flex gap-x-3" mat-menu-item (click)="delete(user._id)">
                        <mat-icon class="!h-4" color="warn" [svgIcon]="SvgIcon.TRASH"></mat-icon>
                        <span class="malou-text-14--regular">{{ 'common.delete' | translate }}</span>
                    </button>
                </mat-menu>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="DISPLAYED_COLUMNS"></mat-header-row>
        <mat-row *matRowDef="let user; columns: DISPLAYED_COLUMNS; table: table; let i = index"></mat-row>
    </mat-table>
    <app-paginator [shouldBeHidden]="dataSource.filteredData.length < 5" [pageSize]="10" [pageSizeOptions]="[5, 10, 20]"></app-paginator>
</ng-template>
