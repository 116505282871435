<div class="flex h-full flex-col gap-y-4 py-4">
    @if (!screenSizeService.isPhoneScreen) {
        <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>
    }

    <div class="flex h-full flex-col gap-y-4 overflow-y-scroll px-8.5">
        @if (screenSizeService.isPhoneScreen) {
            <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>
        }
        @if (((restaurants$ | async)?.length ?? 0) > 1) {
            @if (((platformKeys$ | async)?.length ?? 0) !== 0 || !hasPlatformsLoadedOnce) {
                <ng-container
                    [ngTemplateOutlet]="
                        reviewsRatingKpisDisplayedColumnsCount() > 3 ? firstGridArrangement : secondGridArrangement
                    "></ng-container>
            } @else {
                <div class="flex flex-col items-center py-6">
                    <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
                    <span class="malou-text-14--bold mb-2">{{ 'common.no_data' | translate }}</span>
                    <span class="malou-text-10--regular">{{ 'aggregated_statistics.errors.platforms_not_selected' | translate }}</span>
                </div>
            }
        } @else {
            <div class="flex flex-col items-center py-6">
                <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
                <span class="malou-text-14--bold mb-2">{{ 'common.no_data' | translate }}</span>
                <span class="malou-text-10--regular">{{ 'aggregated_statistics.errors.select_at_least_2_businesses' | translate }}</span>
            </div>
        }
    </div>
</div>

<ng-template #firstGridArrangement>
    <div class="h-[456px] w-full">
        <app-reviews-rating-kpis
            (displayedColumnsCount)="reviewsRatingKpisDisplayedColumnsCount.set($event)"
            (tableSortOptionsChange)="onTableSortOptionsChange(InsightsChart.AGGREGATED_REVIEW_RATINGS_KPIS, $event)"
            (isLoadingEvent)="isReviewsRatingsKpisLoading.set($event)">
        </app-reviews-rating-kpis>
    </div>
    <div class="mt-6 flex gap-6 xl:flex-col" [class.flex-col]="isRestaurantsCountUiLimitExceeded$ | async">
        <div class="min-w-0 flex-1">
            <app-reviews-count
                (chartSortByChange)="onSortByChange(InsightsChart.AGGREGATED_REVIEWS_COUNT, $event)"
                (tableSortByChange)="onTableSortOptionsChange(InsightsChart.AGGREGATED_REVIEWS_COUNT, $event)"
                (viewModeChange)="onViewModeChange(InsightsChart.AGGREGATED_REVIEWS_COUNT, $event)"
                (isLoadingEvent)="isReviewsCountLoading.set($event)"></app-reviews-count>
        </div>
    </div>
    <div class="mt-6 flex gap-6 xl:flex-col" [class.flex-col]="isRestaurantsCountUiLimitExceeded$ | async">
        <div class="min-w-0 flex-1">
            <app-reviews-ratings-average
                (chartSortByChange)="onSortByChange(InsightsChart.AGGREGATED_REVIEWS_RATING_AVERAGE, $event)"
                (tableSortByChange)="onTableSortOptionsChange(InsightsChart.AGGREGATED_REVIEWS_RATING_AVERAGE, $event)"
                (viewModeChange)="onViewModeChange(InsightsChart.AGGREGATED_REVIEWS_RATING_AVERAGE, $event)"
                (isLoadingEvent)="isReviewsRatingsAverageLoading.set($event)"></app-reviews-ratings-average>
        </div>
    </div>
    <app-review-analyses
        (tagsEvolutionSortByChange)="onSortByChange(InsightsChart.AGGREGATED_REVIEW_ANALYSES_TAG_EVOLUTION, $event)"
        (isLoadingEvent)="isReviewsAnalysesLoading.set($event)"></app-review-analyses>
</ng-template>

<ng-template #secondGridArrangement>
    <div class="mt-6 flex gap-6 xl:flex-col" [class.flex-col]="isRestaurantsCountUiLimitExceeded$ | async">
        <div class="h-[542px] min-w-0 flex-1">
            <app-reviews-rating-kpis
                (displayedColumnsCount)="reviewsRatingKpisDisplayedColumnsCount.set($event)"
                (tableSortOptionsChange)="onTableSortOptionsChange(InsightsChart.AGGREGATED_REVIEW_RATINGS_KPIS, $event)"
                (isLoadingEvent)="isReviewsRatingsKpisLoading.set($event)">
            </app-reviews-rating-kpis>
        </div>
        <div class="min-w-0 flex-1">
            <app-reviews-ratings-average
                (chartSortByChange)="onSortByChange(InsightsChart.AGGREGATED_REVIEWS_RATING_AVERAGE, $event)"
                (tableSortByChange)="onTableSortOptionsChange(InsightsChart.AGGREGATED_REVIEWS_RATING_AVERAGE, $event)"
                (viewModeChange)="onViewModeChange(InsightsChart.AGGREGATED_REVIEWS_RATING_AVERAGE, $event)"
                (isLoadingEvent)="isReviewsRatingsAverageLoading.set($event)">
            </app-reviews-ratings-average>
        </div>
    </div>

    <div class="min-w-0 flex-1">
        <app-reviews-count
            (chartSortByChange)="onSortByChange(InsightsChart.AGGREGATED_REVIEWS_COUNT, $event)"
            (tableSortByChange)="onTableSortOptionsChange(InsightsChart.AGGREGATED_REVIEWS_COUNT, $event)"
            (viewModeChange)="onViewModeChange(InsightsChart.AGGREGATED_REVIEWS_COUNT, $event)"
            (isLoadingEvent)="isReviewsCountLoading.set($event)"></app-reviews-count>
    </div>
    <app-review-analyses
        (tagsEvolutionSortByChange)="onSortByChange(InsightsChart.AGGREGATED_REVIEW_ANALYSES_TAG_EVOLUTION, $event)"
        (isLoadingEvent)="isReviewsAnalysesLoading.set($event)"></app-review-analyses>
</ng-template>

<ng-template #filtersTemplate>
    <div class="flex items-end gap-4 px-8.5 md:flex-col md:items-center md:px-0">
        <div class="flex-1 sm:w-full">
            <app-statistics-filters [page]="PlatformFilterPage.E_REPUTATION"></app-statistics-filters>
        </div>
        <button
            class="malou-btn-raised--primary !h-12 sm:w-full"
            mat-raised-button
            [disabled]="((restaurants$ | async)?.length ?? 0) < 2 || isLoading()"
            (click)="openDownloadStatisticsModal()">
            {{ 'aggregated_statistics.common.download_statistics' | translate }}
        </button>
    </div>
</ng-template>
