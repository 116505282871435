import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    CreateScanBodyDto,
    GetScanInsightsBodyDto,
    PatchScanBodyDto,
    ScanDto,
    ScanForAggregatedInsightsDto,
    SearchScanQueryDto,
} from '@malou-io/package-dto';
import { ApiResultV2 } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import { formatArrayKeysForQueryParams } from ':shared/helpers/query-params';

@Injectable({
    providedIn: 'root',
})
export class ScansService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/scans`;

    constructor(private readonly _http: HttpClient) {}

    search(query: SearchScanQueryDto): Observable<ApiResultV2<ScanDto[]>> {
        return this._http.get<ApiResultV2<ScanDto[]>>(this.API_BASE_URL, { params: formatArrayKeysForQueryParams(query) });
    }

    getInsightsForRestaurants(body: GetScanInsightsBodyDto): Observable<ApiResultV2<ScanForAggregatedInsightsDto>> {
        return this._http.post<ApiResultV2<ScanForAggregatedInsightsDto>>(`${this.API_BASE_URL}/insights-for-restaurants`, body);
    }

    create(body: CreateScanBodyDto): Observable<ApiResultV2<ScanDto>> {
        return this._http.post<ApiResultV2<ScanDto>>(this.API_BASE_URL, body);
    }

    patch(id: string, body: PatchScanBodyDto): Observable<ApiResultV2<ScanDto>> {
        return this._http.patch<ApiResultV2<ScanDto>>(`${this.API_BASE_URL}/${id}`, body);
    }
}
