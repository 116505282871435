import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, Inject, OnInit, QueryList, signal, ViewChildren } from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormGroup,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { cloneDeep, compact, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import {
    DEFAULT_MAX_IMAGE_SIZE,
    isNotNil,
    PictureSize,
    PlatformDefinitions,
    PlatformKey,
    PostPublicationStatus,
    STORY_MAX_VIDEO_SIZE,
} from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { BindingIdKey, MediaEditionState, times } from ':core/constants';
import { PlatformsService } from ':core/services/platforms.service';
import { PostsService } from ':core/services/posts.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSize, ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { updatePostsBindingId } from ':modules/posts/posts.actions';
import { CheckedGreaterThan } from ':modules/social-posts/new-social-post-modal/new-social-post-modal.component';
import { PostSummaryComponent } from ':modules/stories/edit-story-modal/post-summary/post-summary.component';
import * as StoriesActions from ':modules/stories/store/stories.actions';
import { selectImageEditions } from ':modules/stories/store/stories.selector';
import { UserState } from ':modules/user/store/user.reducer';
import { User } from ':modules/user/user';
import { ButtonComponent } from ':shared/components/button/button.component';
import { CloseWithoutSavingModalComponent } from ':shared/components/close-without-saving-modal/close-without-saving-modal.component';
import { FeedbacksPanelComponent } from ':shared/components/feedbacks-panel/feedbacks-panel.component';
import { InputDatePickerComponent } from ':shared/components/input-date-picker/input-date-picker.component';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { MediaPickerButtonComponent } from ':shared/components/media-picker-button/media-picker-button.component';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { StoryMediaEditorComponent } from ':shared/components/story-media-editor/story-media-editor.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { CropOption } from ':shared/enums/crop-options';
import { getTimeStringFromDate, isBeforeToday, isControlValueInTimeFormat, isPastHour } from ':shared/helpers';
import { HtmlToMedia } from ':shared/helpers/html-to-media';
import { KillSubscriptions } from ':shared/interfaces';
import { IFormArray } from ':shared/interfaces/form-control-record.interface';
import { ActionsAfterEditStoriesClosed, ApiResult, Media, Platform, Post, PostDto, Restaurant, Story } from ':shared/models';
import { Feedback } from ':shared/models/feedback';
import { ImageEdition } from ':shared/models/image-edition';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { FormatTimePipe } from ':shared/pipes/format-time.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';

enum DialogStep {
    ONE,
    TWO,
}

interface AppState {
    user: UserState;
}

enum PostDateStatus {
    NOW = 'now',
    LATER = 'later',
    DRAFT = 'draft',
}

interface StoryPlatform {
    key: PlatformKey;
    checked: boolean;
    connected: boolean;
}

const DEFAULT_TIME_BETWEEN_POSTS_IN_SECONDS = 10;

@AutoUnsubscribeOnDestroy()
@Component({
    selector: 'app-edit-story-modal',
    templateUrl: './edit-story-modal.component.html',
    styleUrls: ['./edit-story-modal.component.scss'],
    standalone: true,
    imports: [
        CdkDrag,
        CdkDropList,
        NgClass,
        NgTemplateOutlet,
        FormsModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        MatRadioModule,
        MatSelectModule,
        MatOptionModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        ReactiveFormsModule,
        TranslateModule,
        ButtonComponent,
        CloseWithoutSavingModalComponent,
        FeedbacksPanelComponent,
        InputDatePickerComponent,
        InputTextComponent,
        StoryMediaEditorComponent,
        MediaPickerButtonComponent,
        MalouSpinnerComponent,
        PlatformLogoComponent,
        PostSummaryComponent,
        AsyncPipe,
        IncludesPipe,
        ApplyPurePipe,
        FormatTimePipe,
    ],
})
export class EditStoryModalComponent implements OnInit, KillSubscriptions {
    readonly SvgIcon = SvgIcon;
    @ViewChildren('postSummary') postSummaryComponents!: QueryList<PostSummaryComponent>;

    readonly STORY_MAX_VIDEO_SIZE = STORY_MAX_VIDEO_SIZE;
    readonly DEFAULT_MAX_IMAGE_SIZE = DEFAULT_MAX_IMAGE_SIZE;

    readonly killSubscriptions$: Subject<void> = new Subject();

    readonly posts$: BehaviorSubject<Post[]> = new BehaviorSubject([]);
    readonly restaurant$ = this._restaurantsService.restaurantSelected$;
    readonly dialogStep$ = new BehaviorSubject(DialogStep.ONE);

    readonly currentUser$ = this._store.select('user');
    readonly showPicEditor$ = combineLatest([
        this.screenSizeService.resize$.pipe(
            map((resized) => resized.size),
            takeUntil(this.killSubscriptions$)
        ),
        this.dialogStep$,
    ]).pipe(
        map(([size, step]) => {
            if (size !== ScreenSize.IsSmallScreen && size !== ScreenSize.IsMediumScreen) {
                return true;
            }
            return step === DialogStep.ONE;
        })
    );
    readonly showPostForm$ = combineLatest([
        this.screenSizeService.resize$.pipe(
            map((resized) => resized.size),
            takeUntil(this.killSubscriptions$)
        ),
        this.dialogStep$,
    ]).pipe(
        map(([size, step]) => {
            if (size !== ScreenSize.IsSmallScreen && size !== ScreenSize.IsMediumScreen) {
                return true;
            }
            return step === DialogStep.TWO;
        })
    );

    readonly PostDateStatus = PostDateStatus;
    readonly DialogStep = DialogStep;

    readonly MIN_DATE = new Date();

    postDateFormGroup: FormGroup<{
        postDate: FormControl<Date>;
        postTime: FormControl<string>;
    }>;
    times: string[] = times;
    postDateStatus: FormControl<PostDateStatus>;
    loadingMedia = false;
    restaurant: Restaurant;
    currentUser: UserState;
    showCloseModal = false;
    restaurantManagers: User[];
    selectedStory: Post | null;
    malouStoryId: string | null;
    selectedMedias: Media[];
    originalMedias: Record<string, Media[]> = {};
    plannedPublicationDate: Date;
    isEditing = false;
    imageEditionsByStoryId: Record<string, ImageEdition> = {};
    postIdsWithWrongAspectRatio: string[] = [];
    mobilePostDto: PostDto | undefined;

    storyPlatformsForm: IFormArray<StoryPlatform>;

    readonly postsExistingKeys = signal<PlatformKey[]>([]);

    readonly isPastHour = isPastHour;

    readonly isUpdatingStory = signal<boolean>(false);
    readonly selectedPlatformKeys = signal<PlatformKey[]>([]);

    constructor(
        private readonly _dialogRef: MatDialogRef<EditStoryModalComponent>,
        private readonly _dialog: MatDialog,
        public readonly screenSizeService: ScreenSizeService,
        private readonly _fb: UntypedFormBuilder,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _postsService: PostsService,
        private readonly _translate: TranslateService,
        private readonly _toastService: ToastService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _store: Store<AppState>,
        private readonly _htmlToMedia: HtmlToMedia,
        private readonly _platformsService: PlatformsService,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            posts: Post[];
            isDisabled: boolean;
            restaurantManagers: User[];
        }
    ) {
        this.restaurantManagers = this.data.restaurantManagers;
        this.selectedStory = this.data.posts[0] ?? null;
        this.malouStoryId = this.selectedStory?.malouStoryId ?? null;
        this.setOriginalMedias();
        this.setSelectedMedias();
        this.postIdsWithWrongAspectRatio = this._getPostWithWrongAspectRatio(this.data.posts.slice(1)).map((p) => p._id);
        this.posts$.next(this.data.posts);
        this.restaurant$
            .pipe(filter(isNotNil), takeUntil(this.killSubscriptions$))
            .subscribe((restaurant) => (this.restaurant = restaurant));
        this.plannedPublicationDate = this.data.posts[0]?.plannedPublicationDate;
        this.postDateStatus = new FormControl<PostDateStatus>(
            this.selectedStory.published === PostPublicationStatus.DRAFT ? PostDateStatus.DRAFT : PostDateStatus.LATER
        ) as FormControl<PostDateStatus>;
        const publicationDate =
            this.selectedStory.plannedPublicationDate ??
            DateTime.now()
                .plus({
                    days: 1,
                    hours: 1,
                })
                .toJSDate();
        this.postDateFormGroup = this._fb.group({
            postDate: [publicationDate],
            postTime: [getTimeStringFromDate(publicationDate), isControlValueInTimeFormat(this._translate)],
        });
        this.postsExistingKeys.set(uniq(this.data.posts?.map((post) => post.key).filter(isNotNil)) ?? []);
        this.storyPlatformsForm = this._fb.array([], CheckedGreaterThan(0));
        this._store
            .select(selectImageEditions)
            .pipe(filter(isNotNil), takeUntil(this.killSubscriptions$))
            .subscribe((imageEditions) => {
                const posts = this.posts$.getValue();
                this.imageEditionsByStoryId = Object.entries(imageEditions).reduce((acc, curr) => {
                    const [mediaId, imageEdition] = curr;
                    const post = posts.find((p) => p.attachments?.[0]?.id === mediaId);
                    if (post) {
                        acc[post._id] = imageEdition;
                    }
                    return acc;
                }, {});
            });
    }

    get isPostDatePast(): boolean {
        const postDate = this.postDateFormGroup.get('postDate')?.value;
        return postDate ? isBeforeToday(new Date(postDate)) : false;
    }

    get postSchedule(): PostDateStatus {
        return this.postDateStatus?.value;
    }

    get storyPlatforms(): Partial<StoryPlatform>[] {
        return this.storyPlatformsForm?.value;
    }

    get getCheckedPlatformCtrls(): { [key: string]: AbstractControl }[] {
        return this.storyPlatformsForm.controls.map((fg: UntypedFormGroup) => fg?.controls).filter((c) => !!c.checked.value);
    }

    get checkedPlatforms(): PlatformKey[] {
        return this.getCheckedPlatformCtrls.map((c) => c.key.value);
    }

    get postTime(): string | undefined {
        return this.postDateFormGroup.get('postTime')?.value;
    }

    get postDate(): Date | undefined {
        return this.postDateFormGroup.get('postDate')?.value;
    }

    get storyOnError(): boolean {
        return this.postSummaryComponents?.toArray()?.some((p) => p.videoError());
    }

    get publishButtonTrackingId(): string {
        return this.postDateStatus?.value ? 'tracking_story_publish_button_' + this.postDateStatus.value : '';
    }

    get formErrors(): string[] {
        const errors: string[] = [];
        if (this.selectedStory?.published === PostPublicationStatus.PUBLISHED) {
            return errors;
        }
        if (!this.selectedMedias.length) {
            errors.push(this._translate.instant('stories.edit_story.add_image_video'));
        }
        if (!this.storyPlatforms?.filter((p) => !!p.checked).length) {
            errors.push(this._translate.instant('stories.edit_story.choose_one_platform'));
        }
        if (this.postSchedule === PostDateStatus.LATER && isPastHour({ hourWithMinute: this.postTime, date: this.postDate })) {
            errors.push(this._translate.instant('stories.edit_story.invalid_time'));
        }
        if (!!this.selectedMedias.length && this.hasMediasErrors()) {
            errors.push(this._translate.instant('stories.edit_story.edit_or_delete_medias'));
        }
        return errors;
    }

    ngOnInit(): void {
        this._dialogRef
            .backdropClick()
            .pipe(takeUntil(this.killSubscriptions$))
            .subscribe({
                next: () => this.openSaveBeforeClose(),
            });

        this.currentUser$.pipe(takeUntil(this.killSubscriptions$)).subscribe((user) => {
            this.currentUser = user;
        });

        this._platformsService
            .getConnectedPlatformsAcceptingStories()
            .pipe(takeUntil(this.killSubscriptions$))
            .subscribe((socialNetworksPlatforms) => {
                this._initPlatformsDataForFilter(socialNetworksPlatforms);
            });

        this.postDateStatus.valueChanges.subscribe((status) => {
            if (status === PostDateStatus.NOW) {
                this.postDateFormGroup.get('postDate')?.patchValue(new Date());
                this.postDateFormGroup.get('postTime')?.patchValue(getTimeStringFromDate(new Date()));
            }
        });

        this.postDateFormGroup.valueChanges
            .pipe(
                debounceTime(500),
                map(({ postDate, postTime }: { postDate: Date; postTime: string }) => {
                    const [hour, minute] = postTime.split(':');
                    const newDate = new Date(postDate);
                    newDate.setHours(parseInt(hour, 10));
                    newDate.setMinutes(parseInt(minute, 10));
                    return newDate;
                })
            )
            .subscribe((newDate) => {
                this.plannedPublicationDate = newDate;
                this.updatePlannedPublicationDates$(this.posts$.getValue(), this.plannedPublicationDate).subscribe({});
            });

        this.storyPlatformsForm.valueChanges
            .pipe(
                debounceTime(500),
                map((platforms: StoryPlatform[]) => platforms.filter((p) => p.checked).map((p) => p.key))
            )
            .subscribe((platformKeys) => {
                this.updatePlatformKeys$(this.posts$.getValue(), platformKeys).subscribe({});
                this.selectedPlatformKeys.set(platformKeys);
            });
    }

    togglePlatformChecked(platformKey: string): void {
        const platform = this.storyPlatforms.find((p) => p.key === platformKey);
        if (platform) {
            platform.checked = !platform.checked;
        }
        this.storyPlatformsForm.patchValue(this.storyPlatforms);
    }

    openSaveBeforeClose(): void {
        if (!this.selectedStory) {
            this.cancel({ reload: false });
            return;
        }
        if (this._isNewImageEdition(this.imageEditionsByStoryId[this.selectedStory._id])) {
            this.showCloseModal = true;
            return;
        }
        this.cancel({ reload: true });
    }

    cancel(next: ActionsAfterEditStoriesClosed): void {
        this.killSubscriptions$.next();
        this._dialog.closeAll();
        const shouldDeleteAfterClose = false;
        this._dialogRef.close({ next, shouldDeleteAfterClose });
    }

    confirmClose(): void {
        this._store.dispatch(StoriesActions.deleteImageEdition({ mediaId: this.selectedMedias[0].id }));
        this.cancel({ reload: true });
    }

    returnToStoryEdition(): void {
        this.showCloseModal = false;
    }

    processError(error: Error | string): void {
        this.loadingMedia = false;
        const errorMessage = typeof error === 'string' ? error : (error.message ?? error.toString());
        this._toastService.openErrorToast(errorMessage);
    }

    clickedPost(post: Post): void {
        if (!this.selectedStory) {
            return;
        }
        const previousPostId = this.selectedStory._id;
        const imageEdition = this.imageEditionsByStoryId[previousPostId];
        if (this._isNewImageEdition(imageEdition)) {
            this._saveImagesModifications$({
                imageEdition,
                storyId: previousPostId,
                medias: cloneDeep(this.selectedMedias),
            }).subscribe();
        }
        this.selectedStory = post;
        // way better with a signal
        this.setOriginalMedias();
        this.setSelectedMedias();
        this._checkPost(post);
    }

    setSelectedMedias(): void {
        if (!this.selectedStory) {
            this.selectedMedias = [];
            return;
        }
        const originalMedias = this.originalMedias[this.selectedStory._id];
        this.selectedMedias = originalMedias?.length ? originalMedias : (this.selectedStory.attachments?.map((m) => new Media(m)) ?? []);
    }

    setOriginalMedias(): void {
        if (!this.selectedStory) {
            return;
        }
        const originalMedias = this.originalMedias[this.selectedStory._id];
        if (originalMedias?.length) {
            return;
        }
        this.originalMedias = {
            ...this.originalMedias,
            [this.selectedStory._id]: this.selectedStory.attachments?.map((m) => new Media(m)) ?? [],
        };
    }

    onEditedCurrentMedia(medias: Media[], options?: { shouldForceCreation?: boolean }): void {
        if (!this.selectedStory || options?.shouldForceCreation) {
            this.createNewStoryFromMedias(medias);
            return;
        }
        const [firstMedia, ...otherMedias] = medias;

        this._postsService.updatePost(this.selectedStory._id, { attachments: [firstMedia] }).subscribe({
            next: ({ data: updatedPostData }) => {
                const updatedPost = new Post(updatedPostData);
                updatedPost.initWorkingPic(PictureSize.SMALL);
                const newPosts = this.posts$.getValue().map((p) => {
                    if (p._id === updatedPostData._id) {
                        return updatedPost;
                    }
                    return p;
                });
                this.posts$.next(newPosts);
                if (otherMedias.length) {
                    this.createNewStoryFromMedias(otherMedias);
                }
                const postIndex = this.posts$.getValue().findIndex((p) => p._id === updatedPostData._id) ?? 0;
                this.selectedStory = newPosts?.[postIndex];
                this.setSelectedMedias();
            },
        });
    }

    onUpdateCurrentFeedback(feedback: Feedback): void {
        this.posts$.next(
            this.posts$.getValue()?.map((post) => {
                if (post._id === this.selectedStory?._id) {
                    return new Story({ ...post, feedbackId: feedback._id, feedback });
                }
                return post;
            })
        );
    }

    onEditingChange(editionState: MediaEditionState): void {
        this.isEditing = editionState === MediaEditionState.EDITING;
    }

    getPublishButtonText(): string {
        switch (this.postDateStatus?.value) {
            case PostDateStatus.NOW:
                return this._translate.instant('social_posts.new_social_post.publish');
            case PostDateStatus.LATER:
                return this._translate.instant('social_posts.new_social_post.later');
            default:
                return this._translate.instant('social_posts.new_social_post.save_as_draft');
        }
    }

    changeSelectedTime(event: MatSelectChange): void {
        this.postDateFormGroup.get('postTime')?.patchValue(event.value);
    }

    canPublish(): boolean {
        if (this.isEditing || this.storyOnError || !this.selectedMedias.length || this.postIdsWithWrongAspectRatio.length) {
            return false;
        }
        if (this.postSchedule === PostDateStatus.DRAFT) {
            return this._isPublicationDateValid();
        } else if (this.postSchedule === PostDateStatus.NOW) {
            return (
                !this.hasMediasErrors() &&
                !this.formErrors.length &&
                this.posts$.getValue()?.[0]?.published !== PostPublicationStatus.PUBLISHED
            );
        }
        return (
            !this.hasMediasErrors() &&
            !this.formErrors.length &&
            this.posts$.getValue()?.[0]?.published !== PostPublicationStatus.PUBLISHED &&
            this._isPublicationDateValid()
        );
    }

    hasMediasErrors(): boolean {
        const posts = this.posts$.getValue();
        return posts.some((post) => !post.attachments?.[0]);
    }

    createNewStoryFromMedias(medias: Media[]): void {
        const malouStoryId: string = this.malouStoryId ?? uuidv4();

        this.loadingMedia = true;
        forkJoin(
            medias.map((m) =>
                this._postsService
                    .createStory$(this.restaurant._id, {
                        attachmentId: m.id,
                        keys: this.checkedPlatforms,
                        malouStoryId,
                    })
                    .pipe(catchError(() => EMPTY))
            )
        ).subscribe({
            next: (results) => {
                this.malouStoryId = malouStoryId;

                const postsCreated: Post[] = [];
                results.forEach((r) => {
                    if (r.data) {
                        const post = new Post(r.data);
                        post.initWorkingPic('small');
                        postsCreated.push(post);
                    }
                });
                const posts = this.posts$.getValue().concat(postsCreated);
                this.updatePlannedPublicationDates$(posts, this.plannedPublicationDate).subscribe({
                    next: (newPosts) => {
                        this.loadingMedia = false;
                        this.posts$.next(newPosts);
                        this.selectedStory = newPosts?.[0];
                        this.setSelectedMedias();
                        this.postIdsWithWrongAspectRatio = compact(
                            this._getPostWithWrongAspectRatio(postsCreated).map((p) => {
                                const firstAttachment = p.attachments?.[0];
                                if (!firstAttachment) {
                                    return null;
                                }
                                this._store.dispatch(
                                    StoriesActions.editImageEdition({
                                        imageEdition: {
                                            isNew: true,
                                        },
                                        mediaId: firstAttachment.id,
                                    })
                                );
                                return p._id !== this.selectedStory?._id ? p._id : null;
                            })
                        );
                    },
                    error: (err) => {
                        this.loadingMedia = false;
                        this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                    },
                });
            },
        });
    }

    onDeletedPost(postId: string): void {
        this._postsService.deletePost(postId).subscribe({
            next: () => {
                this.postIdsWithWrongAspectRatio = this.postIdsWithWrongAspectRatio.filter((id) => id !== postId);
                this.updatePlannedPublicationDates$(
                    this.posts$.getValue()?.filter((p) => p._id !== postId),
                    this.plannedPublicationDate
                ).subscribe((newPosts) => {
                    this.posts$.next(newPosts);
                    this.selectedStory = newPosts?.[0] ?? null;
                    this.setSelectedMedias();
                    if (this.selectedStory) {
                        this._checkPost(this.selectedStory);
                    } else {
                        this.malouStoryId = null;
                    }
                });
            },
        });
    }

    drop(event: CdkDragDrop<string[]>): void {
        const posts = this.posts$.getValue();
        moveItemInArray(posts, event.previousIndex, event.currentIndex);
        this.updatePlannedPublicationDates$(posts, this.plannedPublicationDate).subscribe((newPosts) => {
            this.posts$.next(newPosts);
        });
    }

    updatePlannedPublicationDates$(posts: Post[], firstDate: Date): Observable<Post[]> {
        if (!posts.length) {
            return of([]);
        }

        const newPosts = posts.map((post, index) => {
            const newDate = DateTime.fromJSDate(firstDate)
                .plus({ seconds: index * DEFAULT_TIME_BETWEEN_POSTS_IN_SECONDS })
                .toJSDate();
            return new Post({ ...post, plannedPublicationDate: newDate });
        });

        return forkJoin(
            newPosts.map((np) =>
                this._postsService.updatePost(np._id, { plannedPublicationDate: np.plannedPublicationDate }).pipe(map(() => np))
            )
        );
    }

    updatePlatformKeys$(posts: Post[], platformKeys: PlatformKey[]): Observable<Post[]> {
        return !posts?.length
            ? of([])
            : forkJoin(posts.map((np) => this._postsService.updatePost(np._id, { keys: platformKeys }).pipe(map(() => np))));
    }

    nextDialogStep(): void {
        if (!this.selectedStory) {
            return;
        }
        this._saveImagesModifications$({
            storyId: this.selectedStory._id,
            medias: cloneDeep(this.selectedMedias),
        })
            .pipe(map((res) => res?.data))
            .subscribe({
                next: (res) => {
                    this.mobilePostDto = res;
                    this.dialogStep$.next(DialogStep.TWO);
                },
            });
    }

    save(): void {
        if (!this.malouStoryId) {
            return;
        }
        const { malouStoryId } = this;

        if (this.postDateStatus.value === PostDateStatus.DRAFT) {
            this._disableForm();
            this.isUpdatingStory.set(true);
            combineLatest([this._getMobilePostDtoOrSaveModifications$(), this._postsService.cancelStory$({ malouStoryId })]).subscribe({
                next: () => {
                    this._enableForm();
                    this.isUpdatingStory.set(false);
                    this._deleteAllImageEditions();
                    this.cancel({
                        reload: true,
                    });
                },
                error: (err) => {
                    console.error('err :>> ', err);
                    this._enableForm();
                    this.isUpdatingStory.set(false);
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                },
            });
            return;
        }
        this._disableForm();
        this.isUpdatingStory.set(true);
        this._getMobilePostDtoOrSaveModifications$()
            .pipe(
                switchMap((res) =>
                    this._postsService
                        .prepareStory$({
                            malouStoryId,
                            keys: this.checkedPlatforms,
                        })
                        .pipe(map((_) => res))
                )
            )
            .subscribe({
                next: () => {
                    this._enableForm();
                    this.isUpdatingStory.set(false);
                    this._deleteAllImageEditions();
                    if (this.postDateStatus.value === PostDateStatus.NOW && malouStoryId) {
                        this._store.dispatch(
                            updatePostsBindingId({
                                bindingId: malouStoryId,
                                bindingIdKey: BindingIdKey.MALOU_STORY_ID,
                            })
                        );
                    }
                    this.cancel({
                        reload: true,
                    });
                },
                error: (err) => {
                    console.error('err', err);
                    this._enableForm();
                    this.isUpdatingStory.set(false);
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                },
            });
    }

    private _isPublicationDateValid(): boolean {
        if (!this.postDate) {
            return false;
        }
        return this.postDate >= new Date();
    }

    private _getMobilePostDtoOrSaveModifications$(): Observable<PostDto | undefined> {
        return this.mobilePostDto
            ? of(this.mobilePostDto)
            : this._saveImagesModifications$({
                  storyId: this.selectedStory?._id,
                  medias: cloneDeep(this.selectedMedias),
              }).pipe(map((res) => res?.data));
    }

    onImageEditionChange(imageEdition: ImageEdition): void {
        if (this.selectedStory) {
            this.imageEditionsByStoryId[this.selectedStory._id] = imageEdition;
        }
    }

    private _initPlatformsDataForFilter(socialNetworksPlatforms: Platform[]): void {
        const selectedKeys: PlatformKey[] = [];
        PlatformDefinitions.getPlatformKeysWithStories().forEach((platformKey) => {
            const platform = socialNetworksPlatforms.find((pl) => pl.key === platformKey);
            this.storyPlatformsForm.push(
                this._fb.group({
                    key: platformKey,
                    connected: !!platform,
                    checked: [
                        {
                            value: !!platform && this._isInitiallySelected(platform.key),
                            disabled: !platform,
                        },
                    ],
                })
            );
            if (!!platform && this._isInitiallySelected(platform.key)) {
                selectedKeys.push(platformKey);
            }
        });
        this.selectedPlatformKeys.set(selectedKeys);
    }

    private _isInitiallySelected(key: PlatformKey): boolean {
        if (!this.selectedStory) {
            return false;
        }
        if (this.postsExistingKeys().length) {
            return this.postsExistingKeys().includes(key);
        }
        return this.selectedStory.key === key || this.selectedStory.keys.includes(key);
    }

    private _saveImagesModifications$({
        imageEdition,
        storyId,
        medias,
    }: {
        imageEdition?: ImageEdition;
        storyId: string | undefined;
        medias: Media[];
    }): Observable<ApiResult<PostDto> | null> {
        if (!storyId || medias[0]?.isVideo() || !medias?.[0]) {
            return of(null);
        }
        imageEdition ??= this.imageEditionsByStoryId[storyId];
        if (this._hasCorrectAspectRatio(medias[0]) && !this._isNewImageEdition(imageEdition)) {
            return of(null);
        }
        const htmlElement = document.getElementById('backgroundImageContainer');
        if (!htmlElement) {
            return of(null);
        }
        return this._htmlToMedia.transform(htmlElement, medias[0]).pipe(
            switchMap((currentMedia) => {
                const editedMedias = medias.map((m) => (m.id === currentMedia.originalMediaId ? currentMedia : m));
                this._store.dispatch(
                    StoriesActions.editImageEdition({
                        imageEdition: {
                            ...imageEdition,
                            isNew: false,
                        },
                        mediaId: currentMedia.originalMediaId ?? currentMedia.id,
                    })
                );
                return this._postsService.updatePost(storyId, { attachments: editedMedias });
            })
        );
    }

    private _isNewImageEdition(imageEdition: ImageEdition): boolean {
        return imageEdition?.isNew;
    }

    private _hasCorrectAspectRatio(media: Media): boolean {
        const aspectRatio = media.getAspectRatio(PictureSize.IG_FIT);
        return aspectRatio ? Math.abs(aspectRatio - CropOption.VERTICAL) <= 0.01 : false;
    }

    private _deleteAllImageEditions(): void {
        this._store.dispatch(StoriesActions.resetImageEditions());
    }

    private _getPostWithWrongAspectRatio(posts: Post[]): Post[] {
        return posts.filter((p) => {
            const firstAttachment = p.attachments?.[0];
            const isVideo = firstAttachment?.isVideo();
            return firstAttachment && !isVideo && !this._hasCorrectAspectRatio(firstAttachment);
        });
    }

    private _checkPost(post: Post): void {
        if (this.postIdsWithWrongAspectRatio.includes(post._id)) {
            this.postIdsWithWrongAspectRatio = this.postIdsWithWrongAspectRatio.filter((id) => id !== post._id);
        }
    }

    private _disableForm(): void {
        this.postDateFormGroup.disable();
        this.postDateStatus.disable();
        this.storyPlatformsForm.disable();
    }

    private _enableForm(): void {
        this.postDateFormGroup.enable();
        this.postDateStatus.enable();
        this.storyPlatformsForm.enable();
    }
}
