import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { DuplicateAndSelectPlatformsComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/duplicate-and-select-platforms/duplicate-and-select-platforms.component';
import { PreviewsFeedNotesComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/previews-feed-notes/previews-feed-notes.component';
import { SocialPostContentFormComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-content-form.component';
import { UpsertSocialPostModalFooterComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/upsert-social-post-modal-footer/upsert-social-post-modal-footer.component';
import { UpsertSocialPostAiContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post-ai.context';
import { UpsertSocialPostHashtagsContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post-hashtags.context';
import { UpsertSocialPostContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post.context';
import {
    UpsertSocialPostModalProps,
    UpsertSocialPostModalResult,
} from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/upsert-social-post-modal.interface';
import { UpsertSocialPost } from ':modules/posts-v2/social-posts/models/upsert-social-post';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-upsert-social-post-modal',
    templateUrl: './upsert-social-post-modal.component.html',
    styleUrls: ['./upsert-social-post-modal.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        TranslateModule,
        DuplicateAndSelectPlatformsComponent,
        PreviewsFeedNotesComponent,
        SocialPostContentFormComponent,
        UpsertSocialPostModalFooterComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpsertSocialPostModalComponent {
    private readonly _upsertSocialPostContext = inject(UpsertSocialPostContext);
    private readonly _upsertSocialPostAiContext = inject(UpsertSocialPostAiContext);
    private readonly _upsertSocialPostHashtagsContext = inject(UpsertSocialPostHashtagsContext);

    readonly SvgIcon = SvgIcon;

    readonly shouldOpenFeedbacks = signal(false);

    constructor(
        private readonly _dialogRef: MatDialogRef<UpsertSocialPostModalComponent, UpsertSocialPostModalResult>,
        @Inject(MAT_DIALOG_DATA)
        data: UpsertSocialPostModalProps
    ) {
        this._upsertSocialPostContext.init(data.postId);
        this._upsertSocialPostHashtagsContext.init();
        this._upsertSocialPostAiContext.initInteractions(data.postId);

        if (data.shouldOpenFeedbacks) {
            this.shouldOpenFeedbacks.set(true);
        }
    }

    close(): void {
        // TODO send data to the parent component
        this._confirmClose(null);
    }

    onCancel(): void {
        const initialPost = this._upsertSocialPostContext.initialPost();

        let actionBeforeClose$: Observable<UpsertSocialPost | null>;

        if (initialPost) {
            actionBeforeClose$ = this._upsertSocialPostContext.savePost(initialPost);
        } else {
            actionBeforeClose$ = this._upsertSocialPostContext.deletePost();
        }

        actionBeforeClose$.subscribe((res) => {
            this._confirmClose(res ?? null);
        });
    }

    private _confirmClose(post: UpsertSocialPost | null): void {
        this._upsertSocialPostAiContext.closePostCaptionAiGeneration();
        this._dialogRef.close({ post });
    }
}
