<div class="hide-scrollbar h-full overflow-y-auto">
    <ng-container *ngTemplateOutlet="shouldShowLoading() ? loadingShimmer : posts"></ng-container>
</div>

<ng-template #loadingShimmer>
    <div class="h-full w-full overflow-hidden">
        <app-skeleton skeletonClass="h-full w-full secondary-bg p-6.5 !rounded-[0]" [useContainer]="false"></app-skeleton>
    </div>
</ng-template>

<ng-template #posts>
    <div class="h-full w-full overflow-hidden bg-white">
        @if (isIgConnected()) {
            @if (feed().length) {
                <div
                    class="hide-scrollbar h-full w-full overflow-y-auto"
                    cdkDropListGroup
                    infinite-scroll
                    [infiniteScrollDistance]="2"
                    [scrollWindow]="false"
                    [infiniteScrollContainer]="scrollContainerSelector"
                    (scrolled)="onScroll()"
                    #scrollContainerSelector>
                    <div class="relative flex w-full flex-wrap gap-y-[2px] transition-all">
                        @for (feedItem of feed(); track feedItem.postId) {
                            <div
                                cdkDropList
                                [cdkDropListData]="feedItem"
                                [cdkDropListEnterPredicate]="cdkDropListEnterPredicate"
                                [ngClass]="{
                                    'aspect-square': !isFeed45Enabled(),
                                    'aspect-[4/5]': isFeed45Enabled(),
                                    'border border-malou-color-state-error': feedItem.published === PostPublicationStatus.ERROR,
                                }"
                                (click)="onFeedItemClicked(feedItem)"
                                (cdkDropListEntered)="onDropListEntered($event)"
                                (cdkDropListDropped)="onDropListDropped($event)">
                                <div
                                    class="media-box"
                                    cdkDrag
                                    [cdkDragStartDelay]="{ touch: 200, mouse: 0 }"
                                    [cdkDragData]="feedItem"
                                    [cdkDragDisabled]="
                                        !draggable() ||
                                        ([PostPublicationStatus.PUBLISHED, PostPublicationStatus.ERROR] | includes: feedItem.published)
                                    "
                                    [ngClass]="{
                                        'border border-malou-color-state-error': feedItem.published === PostPublicationStatus.ERROR,
                                    }"
                                    #postRef>
                                    <app-social-post-media-item
                                        customMediaClass="!w-full !h-full !rounded-none"
                                        [ngClass]="
                                            !draggable() ||
                                            ([PostPublicationStatus.PUBLISHED, PostPublicationStatus.ERROR] | includes: feedItem.published)
                                                ? 'cursor-default'
                                                : 'cursor-grab'
                                        "
                                        [containerSizeStrategy]="SizeStrategy.FULL"
                                        [media]="feedItem.thumbnail"
                                        [showVideoControls]="false"
                                        [statusIcon]="getFeedItemStatusIcon | applyPure: feedItem"
                                        [customStatusIconContainerClass]="{
                                            'bg-malou-color-state-error': feedItem.published === PostPublicationStatus.ERROR,
                                            'bg-malou-color-state-warn': feedItem.published === PostPublicationStatus.PENDING,
                                            'bg-malou-color-primary': feedItem.published === PostPublicationStatus.DRAFT,
                                        }"
                                        (refreshMedia)="onRefreshPost(feedItem)"></app-social-post-media-item>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            } @else {
                <div class="grid h-full w-full place-items-center px-7.5">
                    <div class="flex flex-col items-center gap-y-6">
                        <img height="100" width="100" [src]="'Goggles' | illustrationPathResolver" />
                        <div class="malou-text-14--bold text-center">
                            {{ 'social_posts.feed_empty' | translate }}
                            <div class="malou-text-10--regular mt-3 text-center">
                                {{ 'social_posts.not_connected_subtext' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            }
        } @else {
            <div class="grid h-full w-full place-items-center px-7.5">
                <div class="flex flex-col items-center gap-y-5">
                    <img height="100" width="100" [src]="'Pizza' | illustrationPathResolver" />
                    <div class="malou-text-14--bold text-center leading-7 text-malou-color-text-1">
                        {{ 'social_posts.feed.instagram_not_connected' | translate }}
                    </div>
                    <button class="malou-btn-raised--primary" mat-raised-button (click)="navigateToPlatforms()">
                        {{ 'social_posts.feed.connect_instagram' | translate }}
                    </button>
                </div>
            </div>
        }
    </div>
</ng-template>
