import { AspectRatio, aspectRatioToNumberMap } from '../../../constants';

const PREFERRED_PORTRAIT_ASPECT_RATIO = AspectRatio.PORTRAIT;
const PREFERRED_LANDSCAPE_ASPECT_RATIO = AspectRatio.LANDSCAPE;

interface Area {
    left: number;
    top: number;
    width: number;
    height: number;
}

export class TransformDataComputerService {
    static computeArea(originalAspectRatio: number, targetAspectRatio: number): Area {
        let left: number, top: number, width: number, height: number;
        if (originalAspectRatio > targetAspectRatio) {
            top = 0;
            height = 1;
            width = targetAspectRatio / originalAspectRatio;
            left = (1 - width) / 2;
        } else if (originalAspectRatio < targetAspectRatio) {
            left = 0;
            width = 1;
            height = originalAspectRatio / targetAspectRatio;
            top = (1 - height) / 2;
        } else {
            top = 0;
            left = 0;
            width = 1;
            height = 1;
        }
        return {
            left,
            top,
            width,
            height,
        };
    }

    static computeClosestPreferredAspectRatio(originalAspectRatio: number): AspectRatio {
        const aspectRatio = [PREFERRED_PORTRAIT_ASPECT_RATIO, PREFERRED_LANDSCAPE_ASPECT_RATIO].reduce((acc, cur) => {
            const aspectRatioNumber = aspectRatioToNumberMap[cur];
            if (!aspectRatioNumber) {
                return acc;
            }
            return Math.abs(aspectRatioNumber - originalAspectRatio) < Math.abs(aspectRatioNumber - originalAspectRatio) ? cur : acc;
        });
        return aspectRatio;
    }
}
