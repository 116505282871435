<div class="relative min-w-0 grow overflow-hidden rounded-md">
    <div
        class="hide-scrollbar flex gap-x-1.5 overflow-x-auto"
        cdkDropList
        cdkDropListOrientation="horizontal"
        [cdkDropListAutoScrollStep]="10"
        (cdkDropListDropped)="onDroppedMedia($event)"
        (scroll)="onScroll()"
        #scrollableDiv>
        @for (media of medias(); track media.id; let index = $index) {
            <div
                class="group relative h-[75px] w-[75px] shrink-0 cursor-move rounded-md"
                cdkDrag
                cdkDragLockAxis="x"
                (click)="mediaClicked.emit(media.id)">
                <img class="rounded-md object-cover" fill [ngSrc]="media.thumbnail256OutsideUrl" />
                <div
                    class="invisible absolute right-2 top-2 cursor-pointer rounded-md border border-malou-color-background-darker bg-white p-1 leading-[0] group-hover:visible"
                    (click)="removeMedia(media.id)">
                    <mat-icon class="!h-[16px] !w-[16px]" color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                </div>

                <div
                    class="invisible absolute left-2 top-2 cursor-pointer rounded-md border border-malou-color-background-darker bg-white p-1 leading-[0] group-hover:visible"
                    (click)="editMedia.emit(media.id)">
                    <mat-icon class="!h-[16px] !w-[16px]" color="primary" [svgIcon]="SvgIcon.EDIT"></mat-icon>
                </div>
            </div>
        }
        @for (_ of uploadingMediaCount() | createArray; track $index) {
            <div class="flex h-[75px] w-[75px] shrink-0 items-center justify-center rounded-md bg-white">
                <app-malou-spinner size="small" />
            </div>
        }
    </div>
    <div
        class="absolute left-[6px] top-1/2 -translate-y-1/2 cursor-pointer"
        [ngClass]="{ invisible: atLeftStop() }"
        (click)="onChevronLeftClick()">
        <ng-container
            [ngTemplateOutlet]="scrollButtonTemplate"
            [ngTemplateOutletContext]="{ svgIcon: SvgIcon.CHEVRON_LEFT }"></ng-container>
    </div>
    <div
        class="absolute right-[6px] top-1/2 -translate-y-1/2 cursor-pointer"
        [ngClass]="{ invisible: atRightStop() }"
        (click)="onChevronRightClick()">
        <ng-container
            [ngTemplateOutlet]="scrollButtonTemplate"
            [ngTemplateOutletContext]="{ svgIcon: SvgIcon.CHEVRON_RIGHT }"></ng-container>
    </div>
</div>

<ng-template let-svgIcon="svgIcon" #scrollButtonTemplate>
    <div class="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-white opacity-80">
        <mat-icon class="!h-[16px] !w-[16px]" color="primary" [svgIcon]="svgIcon"></mat-icon>
    </div>
</ng-template>
