import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';

import { PlatformKey } from '@malou-io/package-utils';

import { AggregatedStatisticsState, PlatformFilterPage } from ':modules/aggregated-statistics/store/aggregated-statistics.interface';
import { DatesAndPeriod } from ':shared/models';

const selectFeature = createFeatureSelector<AggregatedStatisticsState>('aggregatedStatistics');

export const selectFilters = createSelector(selectFeature, (state) => state.filters);

export const selectDatesFilter = createSelector(selectFeature, (state): DatesAndPeriod => state.filters.dates);

export const selectPlatformsFilter = (props: {
    page: PlatformFilterPage | undefined;
}): MemoizedSelector<object, PlatformKey[], DefaultProjectorFn<PlatformKey[]>> =>
    createSelector(selectFeature, (state): PlatformKey[] => (props.page ? state.filters.platforms[props.page] : []));

export const selectRestaurantIdsFilter = createSelector(selectFeature, (state): string[] => state.filters.restaurantIds);

export const selectRoiRestaurantIdsFilter = createSelector(selectFeature, (state): string[] => state.filters.roiRestaurantIds);

export const selectReviewsWithAnalysisByRestaurantData = createSelector(
    selectFeature,
    (state) => state.data.reviewsWithAnalysisByRestaurant
);

export const selectPlatformsRatingsByRestaurantData = createSelector(selectFeature, (state) => state.data.platformsRatingsByRestaurant);

export const selectTotemsFilter = createSelector(selectFeature, (state): string[] => state.filters.totemIds);

export const selectReviewCountsData = createSelector(selectFeature, (state) => state.data.reviewCounts);

export const selectAverageReviewsRatingsData = createSelector(selectFeature, (state) => state.data.averageReviewsRatings);

export const selectBoosterStatsData = createSelector(selectFeature, (state) => state.data.boosterStatsData);

export const selectBoosterStatsDataV2 = createSelector(selectFeature, (state) => state.data.boosterStatsDataV2);

export const selectTimeScaleFilter = createSelector(selectFeature, (state) => state.filters.timeScale);

export const selectAggregatedStatisticsSelectedPage = createSelector(selectFeature, (state) => state.page);

export const selectPlatforms = createSelector(selectFeature, (state) => state.filters.platforms);

export const selectIsFiltersLoaded = createSelector(selectFeature, (state) => state.loaded);
