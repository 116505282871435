import { z } from 'zod';

import { FeedbackMessageVisibility, MapstrCtaButtonType, PlatformKey, PostPublicationStatus, PostType } from '@malou-io/package-utils';

import { mediaValidator } from '../media/media.dto';
import { objectIdValidator, urlValidator } from '../utils/validators';
import { postHashtagsValidator } from './post-hashtags.dto';

export const postLocationValidator = z.object({
    id: z.string(),
    name: z.string(),
    link: urlValidator(),
    location: z
        .object({
            city: z.string(),
            country: z.string(),
            latitude: z.number(),
            longitude: z.number(),
            street: z.string(),
            zip: z.string(),
        })
        .optional(),
});

export const feedbackUserValidator = z.object({
    id: z.string(),
    name: z.string(),
    lastname: z.string(),
    profilePictureUrl: z.string().optional(),
    email: z.string(),
});

export const feedbackMessageValidator = z.object({
    id: z.string(),
    author: feedbackUserValidator,
    createdAt: z.date(),
    text: z.string().optional(),
    type: z.string(),
    visibility: z.nativeEnum(FeedbackMessageVisibility),
});

export const postFeedbacksValidator = z.object({
    id: z.string(),
    postId: objectIdValidator,
    isOpen: z.boolean(),
    participants: z.array(
        z.object({
            participant: feedbackUserValidator,
            types: z.array(z.string()),
        })
    ),
    feedbackMessages: z.array(feedbackMessageValidator),
});

export const updateSocialPostBodyValidator = z.object({
    id: objectIdValidator,
    title: z.string().optional(),
    text: z.string(),
    platformKeys: z.array(z.nativeEnum(PlatformKey)),
    published: z.nativeEnum(PostPublicationStatus),
    postType: z.nativeEnum(PostType),
    location: postLocationValidator.optional(),
    callToAction: z
        .object({
            actionType: z.nativeEnum(MapstrCtaButtonType),
            url: urlValidator({ allowEmpty: true }),
        })
        .optional(),
    error: z
        .object({
            code: z.string(),
            rawData: z.string(),
        })
        .optional(),
    socialLink: urlValidator().optional(),
    socialCreatedAt: z.date().optional(),
    feedbacks: postFeedbacksValidator.nullish(),
    plannedPublicationDate: z.date(),
    hashtags: postHashtagsValidator,
    attachments: z.array(mediaValidator),
});

export type SocialPostDto = z.infer<typeof updateSocialPostBodyValidator>;
