import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { SemanticAnalysisSentiment } from '@malou-io/package-utils';

import { Review, SegmentAnalyses } from ':shared/models';
import { PrivateReview } from ':shared/models/private-review';

@Injectable({ providedIn: 'root' })
export class ReviewAnalysisHighlighter {
    constructor(private readonly _sanitizer: DomSanitizer) {}

    getHighlightedReviewTextHtml(review: Review | PrivateReview): SafeHtml {
        if (!review?.semanticAnalysis?.segmentAnalyses?.length || !review.text) {
            return review.text;
        }
        let reviewText = review.text;
        review?.semanticAnalysis?.segmentAnalyses?.forEach((element) => {
            const highlightedSegment = this._getHighlightedSegment(element);
            reviewText = reviewText.toLowerCase().replace(element.segment.toLowerCase(), highlightedSegment);
        });
        return this._sanitizer.bypassSecurityTrustHtml(reviewText);
    }

    setReviewTextHtmlSegmentsHighlights(review: Review | PrivateReview, segmentAnalyses: SegmentAnalyses): SafeHtml {
        const sameSegmentAnalysis = review.semanticAnalysis?.segmentAnalyses?.filter(
            (item) => item.sentiment === segmentAnalyses.sentiment && item.tag === segmentAnalyses.tag
        );

        let highlightedReviewText = review.text;
        sameSegmentAnalysis?.forEach((element) => {
            const highlightedSegment = this._getHighlightedSegment(element);
            highlightedReviewText = highlightedReviewText.toLowerCase().replace(element.segment.toLowerCase(), highlightedSegment);
        });

        return this._sanitizer.bypassSecurityTrustHtml(highlightedReviewText);
    }

    private _getHighlightedSegment(element: SegmentAnalyses): string {
        const color = element.sentiment === SemanticAnalysisSentiment.POSITIVE ? '#34B467' : '#EE116E';
        return `<span style="color: ${color};">${element.segment.toLowerCase()}</span>`;
    }
}
