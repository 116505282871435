import { AsyncPipe, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LazyLoadImageModule, StateChange } from 'ng-lazyload-image';
import { filter, map, take } from 'rxjs/operators';

import { isNotNil, PlatformDefinitions, PlatformKey, PostPublicationStatus, PostType } from '@malou-io/package-utils';

import { ExtendedPostPublicationStatus } from ':core/constants';
import { PostsService } from ':core/services/posts.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSize, ScreenSizeService } from ':core/services/screen-size.service';
import * as fromPlatformsStore from ':modules/platforms/store/platforms.reducer';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { User } from ':modules/user/user';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { SocialPostMediaComponent } from ':shared/components/social-post-media/social-post-media.component';
import { DuplicationDestination } from ':shared/enums/duplication-destination.enum';
import { showFeedbackNotification } from ':shared/helpers/show-feedback-notification';
import { Post, PostDisplayFormat, PostWithJob, Restaurant, SeoPost, SocialPost } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

import { DuplicationPostData } from '../dashboard-calendar.component';

@Component({
    selector: 'app-calendar-post',
    templateUrl: './calendar-post.component.html',
    styleUrls: ['./calendar-post.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        NgStyle,
        LazyLoadImageModule,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
        MatMenuModule,
        TranslateModule,
        SocialPostMediaComponent,
        ApplySelfPurePipe,
        AsyncPipe,
        ImagePathResolverPipe,
        IncludesPipe,
        PlatformLogoComponent,
        ShortTextPipe,
        ApplyPurePipe,
    ],
})
export class CalendarPostComponent implements OnInit {
    @Input() post: PostWithJob;
    @Input() postFormat: PostDisplayFormat = PostDisplayFormat.CARD;
    @Output() delete = new EventEmitter<string>();
    @Output() open = new EventEmitter<string>();
    @Output() duplicate = new EventEmitter<DuplicationPostData>();

    readonly SvgIcon = SvgIcon;
    readonly isGmbConnected$ = this._store.select(fromPlatformsStore.selectCurrentPlatform({ platformKey: PlatformKey.GMB })).pipe(
        take(1),
        map((platform) => platform?.credentials?.length ?? 0 > 0)
    );
    readonly ExtendedPostPublicationStatus = ExtendedPostPublicationStatus;
    readonly DuplicationDestination = DuplicationDestination;
    readonly PostDisplayFormat = PostDisplayFormat;
    readonly PostType = PostType;
    readonly PlatformKey = PlatformKey;

    readonly platformsKeysWithSeoPost = PlatformDefinitions.getSeoPlatformKeysWithDuplicablePosts();
    readonly platformsKeysWithSocialPost = PlatformDefinitions.getSocialPlatformKeysWithDuplicablePosts();

    userInfos: User;
    restaurant: Restaurant;
    isSmallScreen = false;
    isPostEditable: boolean;
    isPostDeletable: boolean;
    isPostDuplicable: boolean;

    constructor(
        private readonly _translate: TranslateService,
        private readonly _store: Store,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _screenSizeService: ScreenSizeService,
        private readonly _postsService: PostsService
    ) {}

    get showFeedbackBadge(): boolean {
        if (!showFeedbackNotification(this.post, this.userInfos?.role)) {
            return false;
        }
        return !!this.post.feedback?.isOpen;
    }

    ngOnInit(): void {
        this._screenSizeService.resize$.subscribe((elt) => {
            this.isSmallScreen = elt.size === ScreenSize.IsSmallScreen;
        });
        this._restaurantsService.restaurantSelected$.subscribe((rest) => {
            this.restaurant = rest ?? this.restaurant;
        });
        this._store
            .select(selectUserInfos)
            .pipe(filter(isNotNil))
            .subscribe((infos) => {
                this.userInfos = infos;
            });
        this.isPostEditable = this.post.canEditPost();
        this.isPostDeletable = this.post.canDeletePost();
        this.isPostDuplicable =
            !this.post.isStory &&
            ![ExtendedPostPublicationStatus.ERROR, ExtendedPostPublicationStatus.REJECTED].includes(this.post.postStatus.type);
        this._postsService.refreshIfPostLacksSocialAttachments(this.post);
    }

    getPostLogoSrc = (key: PlatformKey): string => (this.post.isStory ? `${key}_story` : key);

    isSeoPost = (post: Post): boolean => post.key === PlatformKey.GMB || post.keys?.includes(PlatformKey.GMB);

    deletePost(): void {
        this.delete.emit(this.post._id);
    }

    getPostKeys(): PlatformKey[] {
        if (this.post.keys?.length) {
            return this.post.keys;
        }
        return this.post.key ? [this.post.key] : [];
    }

    openPost(): void {
        this.open.emit(this.post._id);
    }

    getPostTooltip = (post: PostWithJob): string => {
        if (post.published === PostPublicationStatus.ERROR) {
            const postWithClass =
                post.key === PlatformKey.GMB || post.keys.includes(PlatformKey.GMB) ? new SeoPost(post) : new SocialPost(post);
            if (postWithClass.clarifyError) {
                return postWithClass.clarifyError(post.errorData || post.job?.failReason, this._translate) ?? '';
            }
        }
        return this._translate.instant('social_posts.' + post.postStatus?.type);
    };

    duplicatePost(duplicateDestination: DuplicationDestination, platformKeys: PlatformKey[]): void {
        this.duplicate.emit({ duplicateDestination, platformKeys, postId: this.post._id });
    }

    refreshPostIfFailed(event: StateChange): void {
        if (event.reason === 'loading-failed') {
            this.refreshPost();
        }
    }

    refreshPost(): void {
        if (!this._shouldRefresh(this.post)) {
            return;
        }
        this._postsService
            .refresh(this.post?._id)
            .pipe(map((res) => res.data))
            .subscribe({
                next: (p) => {
                    this.post.refreshData({ ...p });
                },
                error: (err) => console.warn('fail silently :>> ', err),
            });
    }

    private _shouldRefresh(post: PostWithJob): boolean {
        return !post.isStory;
    }
}
