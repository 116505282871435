import { Clipboard } from '@angular/cdk/clipboard';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    DestroyRef,
    effect,
    inject,
    input,
    OnInit,
    output,
    Signal,
    signal,
    viewChild,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isNil, omitBy, uniq } from 'lodash';
import {
    BehaviorSubject,
    catchError,
    combineLatest,
    distinctUntilChanged,
    filter,
    forkJoin,
    map,
    Observable,
    of,
    switchMap,
    take,
    tap,
} from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import {
    AI_HARD_LIMIT_CALL_COUNT,
    AiInteractionRelatedEntityCollection,
    AiInteractionType,
    AiTextToOptimizeType,
    APP_DEFAULT_LANGUAGE,
    ApplicationLanguage,
    DEFAULT_LANG_UNKNOWN,
    getTimeDifferenceInHours,
    HeapEventName,
    isNotNil,
    KeywordScoreTextType,
    MalouErrorCode,
    MIN_POSITIVE_REVIEW_RATING,
    PartialRecord,
    PlatformKey,
    PostedStatus,
    UbereatsPromotionValue,
} from '@malou-io/package-utils';

import { NotificationService } from ':core/components/notification-center/services/notifications.service';
import { AiInteractionsService } from ':core/services/ai-interactions.service';
import { AiService } from ':core/services/ai.service';
import { DialogService } from ':core/services/dialog.service';
import { ExperimentationService } from ':core/services/experimentation.service';
import { HeapService } from ':core/services/heap.service';
import { KeywordsService } from ':core/services/keywords.service';
import { LangService } from ':core/services/lang.service';
import { RestaurantAiSettingsService } from ':core/services/restaurant-ai-settings.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { TemplatesService } from ':core/services/templates.service';
import { ToastService } from ':core/services/toast.service';
import { LocalStorage } from ':core/storage/local-storage';
import { CampaignsService } from ':modules/campaigns/campaigns.service';
import { selectCurrentKeywords } from ':modules/keywords/store/keywords.selectors';
import { selectOauthPlatforms } from ':modules/platforms/store/platforms.reducer';
import { PendingReviewReply, ReplyForm, SaveTemplateForm } from ':modules/reviews/answer-review-container/answer-review.interface';
import { AiGenerateReplyComponent } from ':modules/reviews/answer-review-container/answer-review/ai-generate-reply/ai-generate-reply.component';
import { AiModifyReplyComponent } from ':modules/reviews/answer-review-container/answer-review/ai-modify-reply/ai-modify-reply.component';
import { AnswerPrivateReviewComponent } from ':modules/reviews/answer-review-container/answer-review/answer-private-review/answer-private-review.component';
import {
    SaveReplyAsTemplateComponent,
    SaveReplyAsTemplateTheme,
} from ':modules/reviews/answer-review-container/answer-review/save-reply-as-template/save-reply-as-template.component';
import { SelectReviewReplyTemplateComponent } from ':modules/reviews/answer-review-container/answer-review/select-review-reply-template/select-review-reply-template.component';
import { SendUbereatsOfferComponent } from ':modules/reviews/answer-review-container/answer-review/send-ubereats-offer/send-ubereats-offer.component';
import { BasicPreviewComponent } from ':modules/reviews/basic-preview/basic-preview.component';
import { CanBeEditedPipe } from ':modules/reviews/pipe/can-be-edited.pipe';
import { CanHaveMultipleRepliesPipe } from ':modules/reviews/pipe/can-have-multiple-replies.pipe';
import { IsPrivatePipe } from ':modules/reviews/pipe/is-private.pipe';
import { ReplyTextToErrorPipe } from ':modules/reviews/pipe/reply-text-to-error.pipe';
import { ReviewReplyPreviewComponent } from ':modules/reviews/review-reply-preview/review-reply-preview.component';
import { ReviewsContext } from ':modules/reviews/reviews.context';
import { ReviewsService } from ':modules/reviews/reviews.service';
import * as ReviewsActions from ':modules/reviews/store/reviews.actions';
import { selectCurrentReviewReply, selectUnansweredReviewCount } from ':modules/reviews/store/reviews.selectors';
import {
    AVAILABLE_REVIEW_TEMPLATE_VARIABLES,
    TemplateReplacer,
    TemplateVariable,
} from ':modules/templates/template-replacer/template-replacer';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { User } from ':modules/user/user';
import {
    AiGenerationActionsComponent,
    AiGenerationActionsDisplayStyle,
} from ':shared/components/ai-generation-actions/ai-generation-actions.component';
import { ButtonComponent } from ':shared/components/button/button.component';
import { InfiniteTextSlideComponent } from ':shared/components/infinite-text-slide/infinite-text-slide.component';
import { InteractionsBrowserComponent } from ':shared/components/interactions-browser/interactions-browser.component';
import {
    Indication,
    KeywordsScoreGaugeComponent,
    KeywordsScoreGaugeDisplayType,
} from ':shared/components/keywords-score-gauge/keywords-score-gauge.component';
import { KeywordsScoreTipsComponent } from ':shared/components/keywords-score-gauge/keywords-score-tips/keywords-score-tips.component';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { TextAreaComponent } from ':shared/components/text-area/text-area.component';
import { TemplateType } from ':shared/enums/template-type.enum';
import { CommentOptionValue } from ':shared/enums/with-comment.enum';
import { isMalouError } from ':shared/helpers/error';
import { getFormControlRecordFromDefaultValue } from ':shared/helpers/form-control-from-default-value';
import { createTextWithEmoji, CursorPosition, focusAfterEmoji, isEmojiEvent } from ':shared/helpers/text-area-emoji.helpers';
import { INullableFormControlRecord, INullableFormGroup } from ':shared/interfaces/form-control-record.interface';
import { ApiResult, Keyword, Restaurant, Review, ReviewReply, Template } from ':shared/models';
import { Email } from ':shared/models/campaign';
import { CommentOption } from ':shared/models/comment-option';
import { Interaction } from ':shared/models/interaction';
import { PrivateReview, PrivateReviewReply } from ':shared/models/private-review';
import { RestaurantAiSettings } from ':shared/models/restaurant-ai-settings';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { OpenaiErrorService } from ':shared/openai-prompt/openai-errors.service';
import { AiOperation } from ':shared/openai-prompt/openai-prompt.component';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';

@Component({
    selector: 'app-answer-review',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        AiModifyReplyComponent,
        AiGenerateReplyComponent,
        AnswerPrivateReviewComponent,
        BasicPreviewComponent,
        ButtonComponent,
        InfiniteTextSlideComponent,
        InteractionsBrowserComponent,
        KeywordsScoreGaugeComponent,
        KeywordsScoreTipsComponent,
        ReviewReplyPreviewComponent,
        SaveReplyAsTemplateComponent,
        SelectReviewReplyTemplateComponent,
        SkeletonComponent,
        TextAreaComponent,
        MatButtonModule,
        MatIconModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        ApplyPurePipe,
        AsyncPipe,
        CanHaveMultipleRepliesPipe,
        CanBeEditedPipe,
        IsPrivatePipe,
        ReplyTextToErrorPipe,
        ApplySelfPurePipe,
        SendUbereatsOfferComponent,
        NgClass,
        AiGenerationActionsComponent,
    ],
    templateUrl: './answer-review.component.html',
    styleUrls: ['./answer-review.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnswerReviewComponent implements OnInit {
    readonly keywordsScoreGauge = viewChild<KeywordsScoreGaugeComponent>('keywordsScoreGauge');
    readonly answerPrivateReview = viewChild<AnswerPrivateReviewComponent>('answerPrivateReview');

    readonly selectedReview = input.required<Review | PrivateReview>();
    readonly isAggregatedView = input<boolean>(false);
    readonly fromNotificationId = input<string | null>();

    readonly confirmClose = output<void>();
    readonly displayCloseModal = output<boolean>();
    readonly goNext = output<Review | PrivateReview | null>();

    private readonly _destroyRef = inject(DestroyRef);
    private readonly _router = inject(Router);
    private readonly _restaurantAiSettingsService = inject(RestaurantAiSettingsService);
    private readonly _formBuilder = inject(FormBuilder);
    private readonly _translateService = inject(TranslateService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _toastService = inject(ToastService);
    private readonly _aiService = inject(AiService);
    private readonly _openaiErrorService = inject(OpenaiErrorService);
    private readonly _templatesService = inject(TemplatesService);
    private readonly _reviewsService = inject(ReviewsService);
    private readonly _keywordsService = inject(KeywordsService);
    private readonly _dialogService = inject(DialogService);
    private readonly _store = inject(Store);
    private readonly _clipboard = inject(Clipboard);
    private readonly _aiInteractionsService = inject(AiInteractionsService);
    private readonly _experimentationService = inject(ExperimentationService);
    private readonly _reviewsContext = inject(ReviewsContext);
    private readonly _heapService = inject(HeapService);
    private readonly _notificationService = inject(NotificationService);
    private readonly _langService = inject(LangService);
    public readonly screenSizeService = inject(ScreenSizeService);
    public readonly _campaignsService = inject(CampaignsService);

    readonly isUbereatsPromotionOfferFeatureIsEnabled = toSignal(
        this._experimentationService.isFeatureEnabled$('release-ubereats-promotion-offer')
    );
    readonly isReviewRevampFeatureEnabled = toSignal(this._experimentationService.isFeatureEnabled$('release-review-revamp'));

    readonly review$ = toObservable(this.selectedReview);

    readonly SvgIcon = SvgIcon;
    readonly AiGenerationActionsDisplayStyle = AiGenerationActionsDisplayStyle;
    readonly SaveReplyAsTemplateTheme = SaveReplyAsTemplateTheme;
    readonly KeywordsScoreGaugeDisplayType = KeywordsScoreGaugeDisplayType;

    readonly templates = signal<Template[]>([]);
    readonly restaurantTemplatesMap: Record<string, Template[]> = {};

    readonly restaurantKeywordsMap: Record<string, Keyword[]> = {};

    readonly restaurantAiInteractionMap: Record<string, Interaction[]> = {};

    readonly restaurantAiSettingsMap: Record<string, RestaurantAiSettings | undefined> = {};

    readonly isPrivateFormValid = signal(false);

    readonly replyReviewForm: FormGroup;

    private readonly _replyModalTranslate = this._translateService.instant('reviews.reply_modal');
    readonly availableCommentOptions: CommentOption[] = [
        { value: CommentOptionValue.WITH, text: this._replyModalTranslate.with_comment },
        { value: CommentOptionValue.WITHOUT, text: this._replyModalTranslate.without_comment },
        { value: CommentOptionValue.WITH_OR_WITHOUT, text: this._replyModalTranslate.with_or_without_comment },
    ];
    readonly DEFAULT_SAVE_TEMPLATE_VALUE = {
        activated: false,
        replyText: { value: '', disabled: false },
        templateName: '',
        saveTemplateName: '',
        withComment: this.availableCommentOptions.find((option) => option.value === CommentOptionValue.WITH),
        rating: [],
        lang: '',
    };
    readonly templateReplacer = new TemplateReplacer(this._translateService, AVAILABLE_REVIEW_TEMPLATE_VARIABLES);

    readonly replyText$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    readonly langOptions: Signal<(ApplicationLanguage | string)[]> = computed(() =>
        uniq(
            [
                ...Object.values(ApplicationLanguage),
                this.selectedReview()?.lang && this.selectedReview()?.lang !== DEFAULT_LANG_UNKNOWN ? this.selectedReview()?.lang : null,
            ].filter(isNotNil)
        )
    );

    readonly CHIP_LIST = this._buildChipList();
    readonly TEXT_AREA_ID = 'reply-textarea';

    readonly shouldDisplayTemplateUpdateWarning = signal(false);

    readonly isAiResponseLoading = signal(false);
    readonly isTextAreaLoadingAnimationEnabled = signal(false);

    readonly isAiOptimizeButtonDisplayed = signal(false);
    readonly loadingAnimationDefaultText = signal(
        this._translateService.instant('reviews.reply_modal.textarea_loading_text.ai_response_loading')
    );
    readonly loadingAnimationSlideTextList = signal(this._getLoadingAnimationSlideTextList(AiOperation.COMPLETION));
    readonly aiPromptButtonTooltip = signal('');
    readonly aiRateLimitReached = signal(false);

    readonly currentReply = toSignal(this._store.select(selectCurrentReviewReply));

    readonly shouldDisplaySemanticAnalysis: Signal<boolean> = computed(() => this.selectedReview().hasText());

    readonly restaurant$: BehaviorSubject<Restaurant | null> = new BehaviorSubject<Restaurant | null>(null);

    readonly restaurantKeywords$: BehaviorSubject<Keyword[]> = new BehaviorSubject<Keyword[]>([]);

    readonly currentUser = signal<User | undefined>(undefined);

    readonly lang$: BehaviorSubject<string> = new BehaviorSubject<string>(APP_DEFAULT_LANGUAGE);
    readonly gaugeTextType$: Observable<KeywordScoreTextType> = this.review$.pipe(map((review) => this._getTextTypeScore(review)));
    readonly doesTextHaveErrors = signal(false);
    readonly isUpdate = signal(false);
    readonly isFirstTimeReview = signal(true);
    readonly isReplySectionHidden = signal(false);
    readonly nextButtonText = computed(() => {
        const review = this.selectedReview();
        const isReplySectionHidden = this.isReplySectionHidden();
        const isUpdate = this.isUpdate();
        if (isReplySectionHidden) {
            return this._translateService.instant('common.next');
        }
        if (review.canHaveMultipleReplies()) {
            return this._translateService.instant('reviews.reply_modal.add_and_next');
        }
        if (isUpdate) {
            return this._translateService.instant('reviews.reply_modal.update_and_next');
        }
        return this._translateService.instant('reviews.reply_modal.reply_and_next');
    });

    readonly unAnsweredReviewCount = toSignal(this._store.select(selectUnansweredReviewCount), {
        initialValue: 0,
    });

    readonly aiRemainingCredits$: Observable<number> = this._aiService.aiRemainingCredits$;
    readonly shouldDisplayAiInteractionHeadBand = signal(false);
    readonly interactions = signal<Interaction[]>([]);
    readonly resetBrowser = signal<boolean>(false);
    readonly reviewerName$ = this.review$.pipe(map((review) => review.getDisplayName()));
    readonly responseTime$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    readonly shouldDisplayTemplates = signal<boolean>(false);
    readonly reviewId$: Observable<string | null> = this.review$.pipe(map((review) => review?._id ?? null));
    readonly keywordsIndicationList: WritableSignal<Indication[]> = signal([]);
    readonly simplerCombinedReviewsExperimentationEnabled = toSignal(
        this._experimentationService.isFeatureEnabled$('experiment-simpler-combined-reviews')
    );
    readonly isTextAreaContentInitialized = signal(false);

    readonly restaurantAiSettings = signal<RestaurantAiSettings | undefined>(undefined);
    readonly restaurantAiSettings$ = toObservable(this.restaurantAiSettings);

    private _currentInteraction: Interaction | null = null;
    private _previousReview: Review | PrivateReview | undefined = undefined;
    private _initialReplyText = '';
    private _selectedRestaurants: Restaurant[] = [];
    private _oauthPlatformsKeys: string[];

    readonly reviewReviewerName = computed(() => this.selectedReview().getDisplayName());
    readonly reviewOrdersCount = computed(() => this.selectedReview().getEaterTotalOrders());
    readonly reviewOrderTotal = computed(() => this.selectedReview().getOrderTotal());
    readonly reviewOrderCurrencyCode = computed(() => this.selectedReview().getOrderCurrencyCode());

    readonly keywordScoreReviewSpecificData = computed<{ reviewRelatedBricksCount?: number; reviewText: string } | null>(() =>
        this.isReviewRevampFeatureEnabled()
            ? {
                  reviewRelatedBricksCount: this.selectedReview()?.aiRelatedBricksCount,
                  reviewText: this.selectedReview()?.text ?? '',
              }
            : null
    );

    readonly shouldShowKeywordSection = computed(
        () =>
            !this.selectedReview().hasText() ||
            this.selectedReview().rating < MIN_POSITIVE_REVIEW_RATING ||
            isNotNil(this.selectedReview().aiRelevantBricks)
    );

    private _ubereatsPromotionValue = UbereatsPromotionValue.NONE;

    constructor() {
        this.replyReviewForm = this._initReplyForm();

        effect(() => {
            const keywordsScoreGauge = this.keywordsScoreGauge();
            if (keywordsScoreGauge) {
                keywordsScoreGauge.brickLangControl.valueChanges
                    .pipe(
                        switchMap((lang) => this._reviewsService.updateReviewKeywordsLang(this.selectedReview()._id, lang)),
                        takeUntilDestroyed(this._destroyRef)
                    )
                    .subscribe({
                        next: (review) => {
                            this._editReview(this.selectedReview(), review);
                        },
                    });
            }
        });
    }

    get saveTemplateForm(): FormGroup {
        return this.replyReviewForm.get('saveTemplate') as FormGroup;
    }

    get replyText(): FormControl<string> {
        return this.saveTemplateForm.get('replyText') as FormControl;
    }

    get saveTemplateActivated(): FormControl {
        return this.saveTemplateForm.get('activated') as FormControl;
    }

    ngOnInit(): void {
        this._store
            .select(selectUserInfos)
            .pipe(take(1), filter(isNotNil))
            .subscribe((user) => this.currentUser.set(user));

        this._initSelectedRestaurants();
        this._initOnRestaurantChange();
        this._store
            .select(selectOauthPlatforms)
            .pipe(
                map((res) => Object.values(res).map((p) => p.key)),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe((oauthPlatformsKeys) => {
                this._oauthPlatformsKeys = oauthPlatformsKeys;
            });
        this._store.select(selectCurrentKeywords).subscribe((keywords) => {
            this.restaurantKeywords$.next(keywords);
        });
        this.review$.pipe(filter(Boolean), takeUntilDestroyed(this._destroyRef)).subscribe((review) => {
            const isNewReview = this._previousReview?._id !== review?._id;
            const isFirstTimeNewReview = this.isFirstTimeReview() && isNewReview;
            if (this.isFirstTimeReview() && this.restaurantAiSettings()) {
                this.isFirstTimeReview.set(false);
            }
            if (isNewReview) {
                this.isTextAreaContentInitialized.set(false);
            }
            this._previousReview = review;
            this._store.dispatch(ReviewsActions.editCurrentReplyReviewId({ reviewId: review._id }));

            if (!this.isTextAreaContentInitialized()) {
                this._setInteractionsForReview(review);
            }
            if (this.isAggregatedView()) {
                if (review.restaurantId !== this.restaurant$.value?._id) {
                    this._updateReviewRestaurant();
                }
            } else {
                this.restaurant$.next(this._restaurantsService.currentRestaurant);
            }

            this._onReviewChange(isFirstTimeNewReview, isNewReview);
        });
    }

    close(): void {
        const actualText = this.replyText.value ?? '';
        if (actualText !== this._initialReplyText) {
            this.displayCloseModal.emit(true);
        } else {
            this.confirmClose.emit();
        }
    }

    onSaveTemplateActivatedChange(value: boolean): void {
        this.saveTemplateActivated.patchValue(value);
    }

    onTemplateNameChange(templateName: string): void {
        this.saveTemplateForm.patchValue({
            templateName,
        });
    }

    onRatingsChange(ratings: number[]): void {
        this.saveTemplateForm.patchValue({
            rating: ratings,
        });
    }

    onTemplateChange(template: Template | null): void {
        if (!template) {
            return;
        }
        this._editReviewsReply({
            reviewId: this.selectedReview()._id,
            replyText: this._cleanReplyText(this._replaceVariableToReadableText(template.text)),
            templateName: template.name ?? undefined,
            saveTemplateName: template.name ?? undefined,
            rating: template.rating,
        });
        this.shouldDisplayTemplateUpdateWarning.set(
            this.templates()
                .map((t) => t.name)
                .includes(template.name)
        );
    }

    onReplyTextChange(text: string): void {
        if (this.shouldDisplayAiInteractionHeadBand()) {
            this.shouldDisplayAiInteractionHeadBand.set(text === this._currentInteraction?.text);
        }
        this.replyText$.next(text);
        this.isAiOptimizeButtonDisplayed.set(true);
        if (!this.selectedReview()._id) {
            return;
        }
        this._store.dispatch(
            ReviewsActions.editReviewsReply({
                reply: {
                    reviewId: this.selectedReview()._id,
                    replyText: text,
                },
            })
        );
    }

    replyAndGoNext(): void {
        const restaurant = this.restaurant$.value;
        const replyText = this.replyText.value;

        if (!restaurant || !replyText) {
            return;
        }

        let updateReviewValue: PrivateReview | Review;
        if (this.selectedReview().isPrivate()) {
            const privateReviewReply = this._buildPrivateReviewReply(replyText);
            if (!privateReviewReply) {
                return;
            }
            updateReviewValue = new PrivateReview({
                ...this.selectedReview(),
                comments: [privateReviewReply],
            });
        } else {
            const keywordsScoreGauge = this.keywordsScoreGauge();
            if (!keywordsScoreGauge) {
                console.error('Keywords score gauge not found');
                return;
            }
            updateReviewValue = new Review({
                ...this.selectedReview(),
                comments: [this._buildReviewReply(replyText, keywordsScoreGauge, this._ubereatsPromotionValue)],
            });
        }

        const review = this.selectedReview();

        // -- need to do this in an non async way (we cant do it in a subscribe) to avoid the browser blocking the window.open in safari
        if (this._isPlatformThatNeedToBeRepliedManually(review)) {
            const redirectUrl = this._getRedirectUrlToReplyManually(review);
            const queryParams = new URLSearchParams({ redirectUrl });
            navigator.clipboard.writeText(replyText).then(() => {
                window.open(`/reviews-copy-clipboard?${queryParams.toString()}`, '_blank');
            });
        }
        // --

        this._reviewsService
            .canAnswer(restaurant._id)
            .pipe(
                tap(() => {
                    if (this.saveTemplateForm.value.activated) {
                        this._saveTemplate();
                    }

                    if (!review.hasReply()) {
                        this._store.dispatch(ReviewsActions.setUnansweredReviewCount({ count: this.unAnsweredReviewCount() - 1 }));
                    }

                    this._editReview(this.selectedReview(), updateReviewValue);
                    this.shouldGoNext(updateReviewValue);
                }),
                switchMap(() =>
                    forkJoin([
                        this.fromNotificationId() ? this._notificationService.getNotificationById(this.fromNotificationId()!) : of(null),
                        this._reply(updateReviewValue),
                    ])
                )
            )
            .subscribe({
                next: ([notification, { data: currentReview }]) => {
                    this._editReview(this.selectedReview(), currentReview);
                    this._heapService.track(HeapEventName.REVIEW_REPLIED, {
                        restaurantId: restaurant._id,
                        cameFromNotificationId: this.fromNotificationId(),
                        fromAggregatedView: this.isAggregatedView(),
                        notificationChannel: notification?.channel,
                    });
                },
                error: (httpError) => {
                    if (httpError.status === 403) {
                        return;
                    }
                    this._handleError({ httpError, review: updateReviewValue });
                },
            });
    }

    shouldGoNext(review?: Review | PrivateReview): void {
        this.goNext.emit(review ?? null);
    }

    shouldEnableEmojiPicker(review: Review | PrivateReview): boolean {
        return review.key !== PlatformKey.ZENCHEF;
    }

    aiOptimize(): void {
        const restaurant = this.restaurant$.value;
        if (!restaurant) {
            return;
        }
        this.loadingAnimationDefaultText.set(
            this._translateService.instant('reviews.reply_modal.textarea_loading_text.ai_response_loading')
        );
        this.loadingAnimationSlideTextList.set(this._getLoadingAnimationSlideTextList(AiOperation.COMPLETION));
        const textToOptimize = this.replyText.value;
        this.isAiResponseLoading.set(true);
        this.isTextAreaLoadingAnimationEnabled.set(true);
        this._editReviewsReply({ reviewId: this.selectedReview()._id, replyText: '' });
        this._aiService
            .optimizeText({
                relatedEntityId: this.selectedReview()._id,
                textToOptimize: textToOptimize,
                textToOptimizeType: AiTextToOptimizeType.REVIEW_ANSWER,
                restaurantId: restaurant._id,
                lang: this.selectedReview().lang ?? LocalStorage.getLang(),
            })
            .subscribe({
                next: (res) => {
                    this.isTextAreaLoadingAnimationEnabled.set(false);
                    const { data } = res;
                    this.isAiResponseLoading.set(false);
                    this.isAiOptimizeButtonDisplayed.set(false);
                    this._aiService.handleAiInteraction();
                    this._updateInteractionsList({ text: data.optimizedText, isAiInteraction: true, id: uuidv4() });
                    this._editReviewsReply({ reviewId: this.selectedReview()._id, replyText: data.optimizedText });
                    this.lang$.next(data.lang);
                    this.keywordsScoreGauge()?.brickLangControl.setValue(data.lang);
                },
                error: (err) => {
                    console.warn('err >', err);
                    this.isTextAreaLoadingAnimationEnabled.set(false);
                    this.isAiResponseLoading.set(false);
                    this._editReviewsReply({ reviewId: this.selectedReview()._id, replyText: textToOptimize });
                    if (err.error?.status === 429) {
                        this._handleAiLimitReachedError();
                        this._openaiErrorService.openRateLimitErrorDialog();
                    } else {
                        this._toastService.openErrorToast(this._openaiErrorService.clarifyError(err));
                    }
                },
            });
    }

    translateTextWithAi(lang: ApplicationLanguage | string): void {
        const restaurant = this.restaurant$.value;
        if (!lang.length || !restaurant) {
            return;
        }
        this.loadingAnimationDefaultText.set(this._translateService.instant('reviews.reply_modal.textarea_loading_text.ai_can_translate'));
        this.loadingAnimationSlideTextList.set(this._getLoadingAnimationSlideTextList(AiOperation.TRANSLATION));
        this.isTextAreaLoadingAnimationEnabled.set(true);
        this.isAiResponseLoading.set(true);
        const textToTranslate = this.replyText.value;
        this._editReviewsReply({ reviewId: this.selectedReview()._id, replyText: '' });
        this._aiService
            .translateText({
                relatedEntityId: this.selectedReview()._id,
                relatedEntityCollection: AiInteractionRelatedEntityCollection.REVIEWS,
                type: AiInteractionType.REVIEW_ANSWER_TRANSLATION,
                restaurantId: restaurant._id,
                text: textToTranslate,
                lang,
            })
            .subscribe({
                next: (res) => {
                    this.isTextAreaLoadingAnimationEnabled.set(false);
                    const { data: translatedText } = res;
                    this.isAiResponseLoading.set(false);
                    this._editReviewsReply({ reviewId: this.selectedReview()._id, replyText: translatedText });
                    this.lang$.next(lang);
                    this.keywordsScoreGauge()?.brickLangControl.setValue(lang);
                    this._aiService.handleAiInteraction();
                },
                error: (err) => {
                    this.isTextAreaLoadingAnimationEnabled.set(false);
                    this.isAiResponseLoading.set(false);
                    this._editReviewsReply({ reviewId: this.selectedReview()._id, replyText: textToTranslate });
                    console.warn('err >', err);
                    if (err.error?.status === 429) {
                        this._handleAiLimitReachedError();
                        this._openaiErrorService.openRateLimitErrorDialog();
                    } else {
                        this._toastService.openErrorToast(this._openaiErrorService.clarifyError(err));
                    }
                },
            });
    }

    answerReviewWithAi(): void {
        const restaurant = this.restaurant$.value;
        if (!restaurant) {
            return;
        }
        this.loadingAnimationDefaultText.set(
            this._translateService.instant('reviews.reply_modal.textarea_loading_text.ai_response_loading')
        );
        this.loadingAnimationSlideTextList.set(this._getLoadingAnimationSlideTextList(AiOperation.COMPLETION));
        this._editReviewsReply({ reviewId: this.selectedReview()._id, replyText: '' });
        this.isAiResponseLoading.set(true);
        this.isTextAreaLoadingAnimationEnabled.set(true);
        this._aiService
            .answerReview({
                restaurantId: restaurant._id,
                reviewId: this.selectedReview()._id,
            })
            .subscribe({
                next: (response) => {
                    const text = response.data;
                    this.isTextAreaLoadingAnimationEnabled.set(false);
                    this.isAiResponseLoading.set(false);
                    this.isAiOptimizeButtonDisplayed.set(false);
                    this._aiService.handleAiInteraction();
                    this._updateInteractionsList({ text, isAiInteraction: true, id: uuidv4() });
                    this._editReviewsReply({ reviewId: this.selectedReview()._id, replyText: text });
                },
                error: (err) => {
                    console.warn('err >', err);
                    this.isAiResponseLoading.set(false);
                    this.isTextAreaLoadingAnimationEnabled.set(false);
                    if (err.error?.status === 429) {
                        this._handleAiLimitReachedError();
                        this._openaiErrorService.openRateLimitErrorDialog();
                    } else {
                        this._toastService.openErrorToast(this._openaiErrorService.clarifyError(err));
                    }
                },
            });
    }

    addChipToText(chipText: string): void {
        const readableText = this._replaceVariableToReadableText(chipText);
        this.addElementToText(readableText);
    }

    addElementToText(event: string | EmojiEvent): void {
        const currentText = this.replyText.value;
        const textarea = document.getElementById(this.TEXT_AREA_ID) as HTMLTextAreaElement;
        const cursorPosition: CursorPosition = {
            startPosition: textarea?.selectionStart,
            endPosition: textarea?.selectionEnd,
        };
        const textToInsert = isEmojiEvent(event)
            ? event
            : this._computeTextWithSpace(currentText, cursorPosition, this._cleanReplyText(`${event}`));
        const textWithEmoji = createTextWithEmoji(cursorPosition, currentText, textToInsert);
        this._editReviewsReply({ reviewId: this.selectedReview()._id, replyText: textWithEmoji });
        focusAfterEmoji(textarea, cursorPosition, textToInsert);
    }

    onHideAiInteractionReply(review: Review | PrivateReview): void {
        this.shouldDisplayAiInteractionHeadBand.set(false);
        this._editReviewsReply({ reviewId: review._id, replyText: '' });
    }

    goToAiSettings(): void {
        this.close();
        this._router.navigate(['/restaurants', this.restaurant$.value?._id, 'settings', 'automations']);
    }

    onInteractionChanged(interaction: Interaction): void {
        const currentFormText = this._cleanReplyText(this.replyText.value);
        this._updateInteractionsList({
            id: uuidv4(),
            text: currentFormText,
            isAiInteraction: false,
            originalInteractionId: this._currentInteraction?.isAiInteraction
                ? this._currentInteraction?.id
                : this._currentInteraction?.originalInteractionId || undefined,
        });
        this.shouldDisplayAiInteractionHeadBand.set(interaction?.isAiInteraction);
        this._editReviewsReply({ reviewId: this.selectedReview()._id, replyText: this._cleanReplyText(interaction.text) });
        this._currentInteraction = interaction;
    }

    onTextAreaInput(_event: InputEvent): void {
        this.resetBrowser.update((value) => !value);
    }

    updateIsPrivateFormValid(isValid: boolean): void {
        this.isPrivateFormValid.set(isValid);
    }

    onUbereatsOfferSelected(ubereatsOfferSelected: UbereatsPromotionValue): void {
        this._ubereatsPromotionValue = ubereatsOfferSelected;
    }

    private _clarifyError(error: HttpErrorResponse): string {
        if (error?.status === 504) {
            return this._translateService.instant('common.server_error');
        }
        if (error?.error?.message) {
            return error.error.message;
        }
        const clarifiedError = error?.message || String(error);

        return clarifiedError.match(/Missing Permissions/)
            ? this._translateService.instant('reviews.reply_modal.check_permissions_or_retry_reply')
            : clarifiedError;
    }

    private _saveTemplate(): void {
        const restaurant = this.restaurant$.value;
        if (!restaurant) {
            return;
        }
        try {
            this._upsertTemplate$(restaurant._id)
                .pipe(switchMap(() => this._fetchTemplates$()))
                .subscribe({
                    next: (templates) => {
                        this.templates.set(templates);
                    },
                    error: (err) => {
                        this._handleSaveTemplateError(err);
                    },
                });
        } catch (err) {
            this._handleSaveTemplateError(err);
        }
    }

    private _editReviewsReply(reviewReply: PendingReviewReply): void {
        if (!reviewReply) {
            return;
        }
        const cleanReplyText = this._cleanReplyText(reviewReply.replyText ?? '');
        this.replyText$.next(cleanReplyText);
        this.saveTemplateForm.patchValue(reviewReply);
        const reply: PendingReviewReply = <PendingReviewReply>omitBy(reviewReply, isNil);
        if (!reply.reviewId) {
            return;
        }
        this._store.dispatch(ReviewsActions.editReviewsReply({ reply }));
    }

    private _cleanReplyText(text: string): string {
        return text?.replace(/undefined/g, '(À CHANGER)');
    }

    private _computeTextWithSpace(currentText: string, { startPosition, endPosition }: CursorPosition, text: string): string {
        const hasSpaceBefore = currentText[startPosition - 1] === ' ';
        const hasSpaceAfter = currentText[endPosition] === ' ';
        const shouldAddSpaceBefore = currentText[startPosition - 1] && !hasSpaceBefore;
        const shouldAddSpaceAfter = currentText[endPosition] && !hasSpaceAfter;
        return `${shouldAddSpaceBefore ? ' ' : ''}${text}${shouldAddSpaceAfter ? ' ' : ''}`;
    }

    private _replaceVariableToReadableText(text: string): string {
        return this.templateReplacer.replaceToReadableText(text);
    }

    private _getLoadingAnimationSlideTextList(operation: AiOperation): string[] {
        switch (operation) {
            case AiOperation.COMPLETION:
                return [
                    this._translateService.instant('reviews.reply_modal.textarea_loading_text.received_review'),
                    this._translateService.instant('reviews.reply_modal.textarea_loading_text.usual_tone'),
                    this._translateService.instant('reviews.reply_modal.textarea_loading_text.your_keywords'),
                ];
            case AiOperation.TRANSLATION:
                return [
                    this._translateService.instant('common.langs.en').toLowerCase(),
                    this._translateService.instant('common.langs.es').toLowerCase(),
                    this._translateService.instant('common.langs.it').toLowerCase(),
                    this._translateService.instant('reviews.reply_modal.textarea_loading_text.lang_of_your_choice'),
                ];
            default:
                return [];
        }
    }

    private _handleAiLimitReachedError(): void {
        this.aiRateLimitReached.set(true);
        this.aiPromptButtonTooltip.set(this._translateService.instant('reviews.reply_modal.textarea_loading_text.ai_limit_reached'));
    }

    private _validateSaveTemplate(): ValidatorFn {
        return (group: INullableFormGroup<SaveTemplateForm>): ValidationErrors | null => {
            const isActivated = group.get('activated')?.value;
            const replyText = group.get('replyText')?.value;
            const saveTemplateName = group.get('saveTemplateName')?.value;
            const withComment = group.get('withComment')?.value;
            if (isActivated && [replyText, saveTemplateName, withComment].some((value) => !value)) {
                return { required: true };
            }
            return null;
        };
    }

    private _buildChipList(): string[] {
        return this.templateReplacer.getChips();
    }

    private _fetchTemplates$(): Observable<Template[]> {
        const restaurant = this.restaurant$.value;
        if (!restaurant) {
            return of([]);
        }
        return this._templatesService.getTemplatesByRestaurantId(restaurant._id, TemplateType.REVIEW);
    }

    private _isPlatformThatNeedToBeRepliedManually(review: Review | PrivateReview): review is Review {
        if (this._platformHasApi(review.key) || review.key === PlatformKey.PRIVATE) {
            return false;
        }
        return true;
    }

    private _platformHasApi = (platform: PlatformKey): boolean =>
        [
            PlatformKey.FACEBOOK,
            PlatformKey.GMB,
            PlatformKey.ZENCHEF,
            PlatformKey.DELIVEROO,
            PlatformKey.TRIPADVISOR,
            PlatformKey.UBEREATS,
        ].includes(platform);

    private _handleError({ httpError, review }: { httpError: HttpErrorResponse; review: Review | PrivateReview }): void {
        const replyText = review.comments[review.comments.length - 1].text.toString();

        let updatedReview: Review | PrivateReview;
        if (review instanceof PrivateReview) {
            updatedReview = review.copyWith({ comments: review.comments.slice(0, -1), couldNotSendReply: true });
        } else {
            updatedReview = review.copyWith({ comments: review.comments.slice(0, -1), couldNotSendReply: true });
        }

        this._editReview(review, updatedReview);

        if (isMalouError(httpError.error)) {
            if (httpError.error.malouErrorCode === MalouErrorCode.REVIEW_NOT_FOUND) {
                this._dialogService.open({
                    variant: DialogVariant.ALERT,
                    title: this._translateService.instant('reviews.reply_modal.error'),
                    message: this._translateService.instant('reviews.reply_modal.review_doesnt_exist'),
                    primaryButton: {
                        label: this._translateService.instant('reviews.reply_modal.yes_delete'),
                        action: () => {
                            this._reviewsService.deleteReviewById(review._id).subscribe({
                                next: () => {
                                    this._removeReviewFromStore(review._id);
                                    this._store.dispatch(ReviewsActions.fetchUnansweredReviewCount({}));
                                    this.shouldGoNext();
                                    this._toastService.openSuccessToast(this._translateService.instant('reviews.deleted'));
                                },
                                error: (err) => {
                                    console.warn(err);
                                    this._toastService.openErrorToast(this._translateService.instant('common.unknown_error'));
                                },
                            });
                        },
                    },
                    secondaryButton: {
                        label: this._translateService.instant('common.cancel'),
                    },
                });
            }
        } else {
            const shouldCopyText = !this._oauthPlatformsKeys.includes(review.key);
            if (shouldCopyText) {
                this._clipboard.copy(replyText);
                this._toastService.openSuccessToast(this._translateService.instant('common.copied_to_the_clipboard'));
            }
            const title = shouldCopyText
                ? this._translateService.instant('reviews.error_occurred')
                : this._translateService.instant('moderation.error_occurred');
            const message = this._clarifyError(httpError);
            this._dialogService.open({
                variant: DialogVariant.ALERT,
                title,
                message,
                primaryButton: {
                    label: this._translateService.instant('common.close'),
                },
            });
        }
    }

    private _handleSaveTemplateError(error: any): void {
        console.error(error);
        const message = this._translateService.instant('reviews.reply_modal.cant_save_template');
        this._toastService.openErrorToast(message);
    }

    private _reply(review: Review | PrivateReview): Observable<ApiResult<Review | PrivateReview>> {
        if (review.isPrivate()) {
            return this._reviewsService.postPrivateReviewComment(
                review._id,
                review.comments[0],
                this._restaurantsService.currentRestaurant._id
            );
        }
        return this._reviewsService.postReviewComment(review._id, review.comments[0], review.restaurantId);
    }

    private _buildReviewReply(
        text: string,
        keywordsScoreGauge: KeywordsScoreGaugeComponent,
        ubereatsPromotionValue: UbereatsPromotionValue
    ): ReviewReply {
        const existingTemplate = this.templates().find((t) => t.name === this.saveTemplateForm.value.saveTemplateName);
        const reviewReply = new ReviewReply({
            posted: PostedStatus.PENDING,
            text: this._replaceVariableToReadableText(text),
            keywordAnalysis: {
                score: keywordsScoreGauge.score(),
                keywords: keywordsScoreGauge.bricksFound()?.map((brick) => brick),
                count: keywordsScoreGauge.bricksFound()?.length,
            },
            templateIdUsed: existingTemplate?._id,
            isRepliedFromAggregatedView: this.isAggregatedView(),
            ubereatsPromotionValue,
        });
        return reviewReply;
    }

    private _buildPrivateReviewReply(text: string): PrivateReviewReply | null {
        const answerPrivateReview = this.answerPrivateReview();
        if (!answerPrivateReview) {
            console.error('Private review component not found');
            return null;
        }
        const from = answerPrivateReview.privateForm.value.from;
        const mail = answerPrivateReview.privateForm.value.mail;
        const object = answerPrivateReview.privateForm.value.object;
        if (!from || !mail || !object) {
            console.error('From and mail and object are required');
            return null;
        }
        const currentUser = this.currentUser();
        if (!currentUser) {
            console.error('Current user not found');
            return null;
        }

        const privateFormValue = answerPrivateReview.privateForm.value;
        const existingTemplate = this.templates().find((t) => t.name === this.saveTemplateForm.value.saveTemplateName);
        return new PrivateReviewReply({
            author: { _id: currentUser._id, name: currentUser.getFullname() },
            content: new Email({
                from: {
                    name: from,
                    email: mail,
                },
                object: privateFormValue.object ?? undefined,
                messageHTML: this._replaceVariableToReadableText(text),
            }),
            socialUpdatedAt: new Date(),
            templateIdUsed: existingTemplate?._id,
            text: this._replaceVariableToReadableText(text),
            isRepliedFromAggregatedView: this.isAggregatedView(),
        });
    }

    private _editReview(selectedReview: Review | PrivateReview, updatedReview: Review | PrivateReview): void {
        if (selectedReview.isPrivate() && updatedReview.isPrivate()) {
            if (selectedReview.client) {
                updatedReview.setClient(selectedReview.client);
            }
        }
        this._store.dispatch(ReviewsActions.editReview({ review: updatedReview }));
    }

    private _upsertTemplate$(restaurantId: string): Observable<Template> {
        const template = this._getTemplateToSave();
        const templateId = template._id;
        if (templateId) {
            return this._templatesService.updateAndActivateTemplate$(templateId, template);
        } else {
            return this._templatesService.create(template, restaurantId);
        }
    }

    private _getTemplateToSave(): Partial<Template> {
        const existingTemplate = this.templates().find((t) => t.name === this.saveTemplateForm.value.saveTemplateName);
        const savedTemplate = {
            text: this._replaceReadableTextToVariableText(this.saveTemplateForm.value.replyText),
            name: this.saveTemplateForm.value.saveTemplateName,
            withComment: this.saveTemplateForm.value.withComment.value,
            rating: this.saveTemplateForm.value.rating,
            type: TemplateType.REVIEW,
        };
        return {
            ...(existingTemplate ?? {}),
            ...savedTemplate,
        };
    }

    private _replaceReadableTextToVariableText(text: string): string {
        return this.templateReplacer.replaceToVariableText(text);
    }

    private _resetSaveTemplateForm(): void {
        this.saveTemplateForm?.reset(this.DEFAULT_SAVE_TEMPLATE_VALUE);
        this.shouldDisplayTemplateUpdateWarning.set(false);
    }

    private _getTextTypeScore(review: Review | PrivateReview): KeywordScoreTextType {
        return review?.rating <= 2 ? KeywordScoreTextType.LOW_RATE_REVIEW : KeywordScoreTextType.HIGH_RATE_REVIEW;
    }

    private _onReviewChange(isFirstTimeNewReview: boolean, isNewReview: boolean): void {
        const review = this.selectedReview();
        this._resetSaveTemplateForm();
        this.isPrivateFormValid.set(false);
        const lang = review.hasText()
            ? (review.keywordsLang ?? review.lang)
            : (this.restaurantAiSettings()?.defaultLanguageResponse ?? this._translateService.currentLang ?? APP_DEFAULT_LANGUAGE);
        this.lang$.next(lang);
        if (isFirstTimeNewReview) {
            this.keywordsScoreGauge()?.brickLangControl.setValue(lang);
        }
        const firstReviewCommentDate = review.getFirstCommentDate();
        const responseTime = Math.floor(getTimeDifferenceInHours(firstReviewCommentDate ?? new Date(), new Date(review.socialCreatedAt)));
        this.responseTime$.next(responseTime);

        const currentReply = this.currentReply();
        this._editReviewsReply({ reviewId: review._id, replyText: currentReply?.replyText ?? '' });

        if (!review.isPrivate() && isNewReview) {
            this.isReplySectionHidden.set(review.hasToHideReplySection());
            const comments = review.comments || [];
            const comment = comments.find((comm) => ['posted', 'pending'].includes(comm.posted));
            this.isUpdate.set(!!comment);
            this._initialReplyText = comment ? comment.text : '';
            if (comment && review.canBeEdited() && !this.isTextAreaContentInitialized()) {
                this._editReviewsReply({ reviewId: review._id, replyText: comment.text });
                this.isTextAreaContentInitialized.set(true);
            }
        }

        if (!review.canBeReplied()) {
            this.saveTemplateForm.get('replyText')?.disable();
        }

        this._updateTemplateReplacer();
    }

    private _initOnRestaurantChange(): void {
        combineLatest([
            this.restaurant$.pipe(
                filter(Boolean),
                distinctUntilChanged((prev, curr) => prev._id === curr._id)
            ),
        ])
            .pipe(
                switchMap(([restaurant]) =>
                    forkJoin({
                        templates: this.restaurantTemplatesMap[restaurant._id]
                            ? of(this.restaurantTemplatesMap[restaurant._id])
                            : this._fetchTemplates$().pipe(
                                  tap((templates) => (this.restaurantTemplatesMap[restaurant._id] = templates)),
                                  catchError((err) => {
                                      console.error(err);
                                      return of([]);
                                  })
                              ), // Fetch templates for the restaurant
                        restaurantKeywords: this.restaurantKeywordsMap[restaurant._id]
                            ? of(this.restaurantKeywordsMap[restaurant._id])
                            : this._keywordsService.getKeywordsByRestaurantId(restaurant._id).pipe(
                                  tap(({ data: keywords }) => (this.restaurantKeywordsMap[restaurant._id] = keywords)),
                                  map((res) => res.data),
                                  catchError((err) => {
                                      console.error(err);
                                      return of([]);
                                  })
                              ),
                        restaurantAiSettings:
                            restaurant._id in this.restaurantAiSettingsMap
                                ? of(this.restaurantAiSettingsMap[restaurant._id])
                                : this._restaurantAiSettingsService.getRestaurantAiSettings(restaurant._id).pipe(
                                      tap((settings) => (this.restaurantAiSettingsMap[restaurant._id] = settings)),
                                      catchError((err) => {
                                          console.error(err);
                                          return of(undefined);
                                      })
                                  ),
                        restaurant: of(restaurant),
                    })
                ),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe(({ templates, restaurantKeywords, restaurantAiSettings, restaurant }) => {
                this._updateRestaurantAiConfig(restaurant);
                this.templates.set(templates);
                this.restaurantKeywords$.next(restaurantKeywords);
                this.restaurantAiSettings.set(restaurantAiSettings);
                this.aiRateLimitReached.set(false);
            });
    }

    private _updateReviewRestaurant(): void {
        const restaurantFound = this._selectedRestaurants.find((restaurant) => restaurant._id === this.selectedReview().restaurantId);
        if (!restaurantFound) {
            this._restaurantsService.show(this.selectedReview().restaurantId).subscribe((res) => {
                this.restaurant$.next(res.data);
            });
            return;
        }
        this.restaurant$.next(restaurantFound);
    }

    private _updateTemplateReplacer(): void {
        const readableReplacer: PartialRecord<TemplateVariable, string> = {
            [TemplateVariable.CLIENT_NAME]: this.selectedReview().getDisplayName()?.toLowerCase(),
            [TemplateVariable.BUSINESS_NAME]: this.restaurant$?.value?.name.toLowerCase(),
            [TemplateVariable.MY_FIRSTNAME]: this.currentUser()?.name.toLowerCase(),
        };

        this.templateReplacer.setReadableReplacer(readableReplacer);
    }

    private _initReplyForm(): INullableFormGroup<ReplyForm> {
        const form: INullableFormControlRecord<ReplyForm> = {
            saveTemplate: this._initSaveTemplateForm(),
        };
        return this._formBuilder.group(form);
    }

    private _initSaveTemplateForm(): INullableFormGroup<SaveTemplateForm> {
        const saveTemplate: INullableFormControlRecord<SaveTemplateForm> = getFormControlRecordFromDefaultValue(
            this.DEFAULT_SAVE_TEMPLATE_VALUE
        );
        return this._formBuilder.group(saveTemplate, { validators: [this._validateSaveTemplate()] });
    }

    private _initSelectedRestaurants(): void {
        this._reviewsContext.selectedRestaurants$.subscribe((restaurants) => {
            this._selectedRestaurants = restaurants;
        });
    }

    private _handleSetInteractionsSideEffects(interactions: Interaction[], reviewId: string): void {
        if (interactions.length > 0) {
            this.shouldDisplayAiInteractionHeadBand.set(true);
            this._currentInteraction = interactions[interactions.length - 1];
            this._editReviewsReply({ reviewId, replyText: this._currentInteraction?.text });
            this.isTextAreaContentInitialized.set(true);
        }
        this.interactions.set(interactions);
    }

    private _setInteractionsForReview(review: Review | PrivateReview): void {
        this.interactions.set([]);
        this.shouldDisplayAiInteractionHeadBand.set(false);
        this._currentInteraction = null;
        if (review?.hasReply() || review?.isPrivate()) {
            return;
        }
        let interactions = this.restaurantAiInteractionMap[review._id];
        if (interactions) {
            this._handleSetInteractionsSideEffects(interactions, review._id);
        } else {
            this._aiInteractionsService.getAiInteractions(review._id, AiInteractionRelatedEntityCollection.REVIEWS).subscribe({
                next: (aiInteractions) => {
                    interactions = aiInteractions
                        .filter(
                            (interaction) =>
                                interaction.isSuccessful() &&
                                [
                                    AiInteractionType.ANSWER_REVIEW,
                                    AiInteractionType.ANSWER_REVIEW_ADVANCED_SETTINGS,
                                    AiInteractionType.OPTIMIZE_REVIEW_ANSWER,
                                    AiInteractionType.OPTIMIZE_REVIEW_ANSWER_ADVANCED_SETTINGS,
                                    AiInteractionType.REVIEW_ANSWER_TRANSLATION,
                                ].includes(interaction.type)
                        )
                        .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())
                        .map((interaction) => ({
                            id: uuidv4(),
                            text: interaction.completionText ?? '',
                            isAiInteraction: true,
                        }));
                    this.restaurantAiInteractionMap[review._id] = interactions;
                    this._handleSetInteractionsSideEffects(interactions, review._id);
                },
            });
        }
    }

    private _updateInteractionsList(interaction: Interaction): void {
        const interactions = this.interactions();
        const update = Interaction.getUpdatedInteractions(interactions, interaction);
        if (!update) {
            return;
        }
        this.interactions.set(update);
    }

    private _updateRestaurantAiConfig(restaurant: Restaurant): void {
        this._aiService.setAiRemainingCredits(AI_HARD_LIMIT_CALL_COUNT - (restaurant.ai?.monthlyCallCount || 0));
        this.aiPromptButtonTooltip.set('');
    }

    private _removeReviewFromStore(reviewId: string): void {
        this._store.dispatch(ReviewsActions.deleteReviewById({ reviewId }));
    }

    private _getRedirectUrlToReplyManually(review: Review): string {
        const platformKey = review.key;
        switch (platformKey) {
            case PlatformKey.YELP:
                const yelpProUrlPrefixRegex = new RegExp(/^https:\/\/(www\.)?biz.yelp\.([^\/]+)\//);
                const yelpProUrlPrefix = review.businessSocialLink.match(yelpProUrlPrefixRegex);
                const yelpProUrlPrefixMatch = yelpProUrlPrefix?.[0];
                if (!yelpProUrlPrefixMatch) {
                    return review.businessSocialLink;
                }
                const yelpProUrlPrefixMatchLength = yelpProUrlPrefixMatch.length;
                const newPrefix = this._getYelpProUrlNewPrefix();
                return newPrefix + review.businessSocialLink.substring(yelpProUrlPrefixMatchLength);
            default:
                return review.businessSocialLink;
        }
    }

    private _getYelpProUrlNewPrefix(): string {
        const navigatorLang = this._langService.getLangFromNavigator();
        const currentLang = LocalStorage.getLang(navigatorLang ?? APP_DEFAULT_LANGUAGE);
        switch (currentLang) {
            case ApplicationLanguage.FR:
                return 'https://www.biz.yelp.fr/';
            case ApplicationLanguage.IT:
                return 'https://www.biz.yelp.it/';
            case ApplicationLanguage.ES:
                return 'https://www.biz.yelp.es/';
            case ApplicationLanguage.EN:
            default:
                return 'https://www.biz.yelp.com/';
        }
    }
}
