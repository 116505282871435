import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, Observable, of, take } from 'rxjs';

import { SocialPostDto } from '@malou-io/package-dto';
import { ApiResultV2, isNotNil, PlatformDefinitions, PlatformKey } from '@malou-io/package-utils';

import { mapsterPostCaptionTextLimit, postCaptionTextLimit } from ':core/constants';
import { environment } from ':environments/environment';
import { selectCurrentPlatforms } from ':modules/platforms/store/platforms.reducer';
import { IUpsertSocialPost, UpsertSocialPost } from ':modules/posts-v2/social-posts/models/upsert-social-post';
import { Platform } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class UpsertSocialPostService {
    private readonly _http = inject(HttpClient);
    private readonly _API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/posts/v2`;
    private readonly _store = inject(Store);
    private readonly _translateService = inject(TranslateService);

    getConnectedSocialPlatforms$(): Observable<Platform[]> {
        return this._store.select(selectCurrentPlatforms).pipe(
            filter(isNotNil),
            map((platforms) =>
                platforms.filter(
                    (platform) =>
                        PlatformDefinitions.getSocialPlatformKeys().includes(platform.key) &&
                        (platform.key !== PlatformKey.MAPSTR || !!platform.credentials?.length)
                )
            ),
            take(1)
        );
    }

    getPost$(postId: string): Observable<UpsertSocialPost | null> {
        return this._http
            .get<ApiResultV2<SocialPostDto>>(`${this._API_BASE_URL}/${postId}`)
            .pipe(map((res) => UpsertSocialPost.fromDto(res.data)));
    }

    createPost$(restaurantId: string): Observable<UpsertSocialPost> {
        return this._http
            .post<ApiResultV2<SocialPostDto>>(`${this._API_BASE_URL}`, { restaurantId })
            .pipe(map((res) => UpsertSocialPost.fromDto(res.data)));
    }

    updatePost(post: IUpsertSocialPost): Observable<UpsertSocialPost> {
        // TODO implement
        return of(new UpsertSocialPost(post));
    }

    deletePost(_postId: string): Observable<null> {
        // TODO implement
        return of(null);
    }

    // See "get formErrors(): string[] {" in new-social-post-modal.component.ts
    getPostErrors(post: IUpsertSocialPost): string[] {
        // TODO uncomment when post.published is available (right now it is never changed)
        // if (post.published === PostPublicationStatus.DRAFT) {
        //     return [];
        // }

        const errorMessages: string[] = [];

        if (post.platformKeys.includes(PlatformKey.MAPSTR)) {
            if (post.text.length > mapsterPostCaptionTextLimit) {
                errorMessages.push(this._translateService.instant('social_posts.upsert_social_post_modal.errors.mapstr_caption_too_long'));
            }
        } else {
            if (post.text.length > postCaptionTextLimit) {
                errorMessages.push(this._translateService.instant('social_posts.upsert_social_post_modal.errors.caption_too_long'));
            }
        }

        if (post.platformKeys.length === 0) {
            errorMessages.push(this._translateService.instant('social_posts.upsert_social_post_modal.errors.no_platform_selected'));
        }

        return errorMessages;
    }
}
