import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, computed, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { PageEvent } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { groupBy, isEqual, sortBy, uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, defaultIfEmpty, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import type { RequireAtLeastOne } from 'type-fest';
import { v4 as uuidv4 } from 'uuid';

import { errorReplacer, isNotNil, PlatformDefinitions, PlatformKey, PostPublicationStatus, PostSource } from '@malou-io/package-utils';

import { periodOptions } from ':core/constants';
import { selectPermissionsState } from ':core/credentials/store/permissions.reducer';
import { DialogService } from ':core/services/dialog.service';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { PlatformsService } from ':core/services/platforms.service';
import { PostsService } from ':core/services/posts.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { MediaService } from ':modules/media/media.service';
import { selectCurrentPlatform } from ':modules/platforms/store/platforms.reducer';
import { defaultPublicationStatus } from ':modules/posts/posts.reducer';
import { selectCurrentlyPublishingPosts } from ':modules/posts/posts.selectors';
import { PostDateStatus } from ':modules/social-posts/new-social-post-modal/context/types';
import { SocialPostsService } from ':modules/social-posts/social-posts.service';
import { UserState } from ':modules/user/store/user.reducer';
import { User } from ':modules/user/user';
import { GroupedDateFiltersComponent } from ':shared/components/grouped-date-filters/grouped-date-filters.component';
import { IsPublishedFiltersComponent } from ':shared/components/is-published-filters/is-published-filters.component';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import {
    PersonalizePostDuplicationComponent,
    PersonalizePostDuplicationData,
} from ':shared/components/personalize-post-duplication/personalize-post-duplication.component';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import {
    RestaurantsSelectionComponent,
    RestaurantsSelectionData,
} from ':shared/components/restaurants-selection/restaurants-selection.component';
import { PostSkeletonComponent } from ':shared/components/skeleton/templates/post-skeleton/post-skeleton.component';
import { StepperModalComponent } from ':shared/components/stepper-modal/stepper-modal.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { DuplicationDestination } from ':shared/enums/duplication-destination.enum';
import { addMinutes, getTimeStringFromDate, isControlValueInTimeFormat } from ':shared/helpers';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { KillSubscriptions } from ':shared/interfaces';
import { INullableFormGroup } from ':shared/interfaces/form-control-record.interface';
import { Step } from ':shared/interfaces/step.interface';
import {
    ActionsAfterEditStoriesClosed,
    AvailablePlatform,
    MalouPeriod,
    Media,
    Pagination,
    Platform,
    Post,
    PostsFilters,
    PostWithJob,
    Restaurant,
    Story,
} from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { CustomDialogService, DialogScreenSize } from ':shared/services/custom-dialog.service';

import { EditStoryModalComponent } from './edit-story-modal/edit-story-modal.component';
import * as StoriesActions from './store/stories.actions';
import { initialState } from './store/stories.reducer';
import { selectStoriesSyncState } from './store/stories.selector';
import { StoryCarouselComponent } from './story-carousel/story-carousel.component';
import { StoryComponent } from './story/story.component';

interface PlatformsStore {
    platformsData: Record<string, Platform[]>;
}
interface AppState {
    stories: {
        filters: PostsFilters;
    };
    user: UserState;
    lastRefresh: {
        socialPosts: number;
    };
    platforms: PlatformsStore;
}

export interface StoryDuplication {
    isSingleStory: boolean;
    destination: DuplicationDestination;
    storiesToDuplicate: Story[];
    inBackground: boolean;
}

const extractIgUserNameFromIgLink = (igLink?: string): string | undefined => {
    if (!igLink) {
        return;
    }
    return igLink.split('/').pop();
};
const DEFAULT_PAGINATION = { pageSize: 24, pageNumber: 0, total: 0 };
const DEFAULT_PAGE_EVENT = { pageIndex: 0, pageSize: 24, length: 0 };
const DEFAULT_PERIOD = MalouPeriod.LAST_AND_COMING_SIX_MONTH;

@AutoUnsubscribeOnDestroy()
@Component({
    selector: 'app-stories',
    templateUrl: './stories.component.html',
    styleUrls: ['./stories.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatTooltipModule,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        MatRippleModule,
        InfiniteScrollModule,
        MatTabsModule,
        MatButtonToggleModule,
        TranslateModule,
        GroupedDateFiltersComponent,
        IsPublishedFiltersComponent,
        PostSkeletonComponent,
        StoryComponent,
        StoryCarouselComponent,
        AsyncPipe,
        PlatformLogoComponent,
        IllustrationPathResolverPipe,
    ],
})
export class StoriesComponent implements OnInit, KillSubscriptions {
    readonly SvgIcon = SvgIcon;
    readonly trackByIdFn = TrackByFunctionFactory.get('_id');
    readonly trackByKeyFn = TrackByFunctionFactory.get('key');
    readonly storiesPlatformKeys = PlatformDefinitions.getPlatformKeysWithStories();

    restaurant: Restaurant;

    // null while stories are loading
    stories: WritableSignal<Story[] | null> = signal(null);

    storiesToBeOpened$: BehaviorSubject<Post[]> = new BehaviorSubject([]);
    pagination: Pagination = DEFAULT_PAGINATION;
    pageEvent$: BehaviorSubject<PageEvent> = new BehaviorSubject(DEFAULT_PAGE_EVENT);
    restaurant$: Observable<Restaurant | null> = this._restaurantsService.restaurantSelected$;
    currentUser$: Observable<UserState> = this._store.select('user');
    currentUser: UserState;
    selectedStories: Story[] = [];

    // true while the next page is being fetched from the API
    fetchingNextPage: WritableSignal<boolean> = signal(true);

    loading: Signal<boolean> = computed(() => this.stories() === null);

    canCreateStory: Signal<boolean> = computed(() => this.connectedPlatformsAcceptingStories() !== undefined);

    loadedMoreWithPagination = false;

    shouldDisplayNoMoreResultsText = false;
    igPlatform$ = this._store.select(selectCurrentPlatform({ platformKey: PlatformKey.INSTAGRAM }));
    igPictureUrl$: Observable<string>;
    igUserName$: Observable<string | undefined> = this.igPlatform$.pipe(
        map((platform) => extractIgUserNameFromIgLink(platform?.socialLink))
    );
    killSubscriptions$ = new Subject<void>();
    PlatformKey = PlatformKey;
    isAnyPlatformConnected$ = this._store
        .select(selectPermissionsState)
        .pipe(
            map(({ data: permissions }) =>
                permissions?.filter((p) => PlatformDefinitions.getPlatformKeysWithStories().includes(p.key))?.some((perm) => !!perm.isValid)
            )
        );
    filters = initialState.filters;
    restaurantManagers: User[];

    // null while the list is loading
    currentStories: WritableSignal<Story[] | null> = signal(null);
    currentStories$ = toObservable(this.currentStories);

    Illustration = Illustration;
    DuplicationDestination = DuplicationDestination;
    synchronize$ = new Subject<void>();

    activeFiltersCount = 0;
    filters$: Observable<PostsFilters> = this._store
        .select((state) => state.stories.filters)
        .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)));
    period$: Observable<MalouPeriod> = this._store.select((state) => state.stories.filters.period) as Observable<MalouPeriod>;
    start$: Observable<Date> = this._store.select((state) => state.stories.filters.startDate) as Observable<Date>;
    end$: Observable<Date> = this._store.select((state) => state.stories.filters.endDate) as Observable<Date>;
    postFilterStatus$: Observable<string[]> = this._store.select((state) => state.stories.filters.publicationStatus) as Observable<
        string[]
    >;
    periodOptions = periodOptions.map((period) => period.key);
    platformsSelected$: Observable<string[]> = this._store.select((state) => state.stories.filters.platforms ?? []) as Observable<string[]>;
    platformsStore$: Observable<PlatformsStore> = this._store.select((state) => state.platforms);
    readonly availablePlatforms$: BehaviorSubject<AvailablePlatform[]> = new BehaviorSubject([]);
    readonly availablePlatforms = toSignal(this.availablePlatforms$, { initialValue: this.availablePlatforms$.value });
    isAnyPlatformConnected = false;

    connectedPlatformsAcceptingStories: Signal<Platform[] | undefined> = toSignal(
        this._platformsService.getConnectedPlatformsAcceptingStories()
    );

    constructor(
        private readonly _postsService: PostsService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _store: Store<AppState>,
        private readonly _translate: TranslateService,
        private readonly _router: Router,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _dialogService: DialogService,
        private readonly _toastService: ToastService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _socialPostsService: SocialPostsService,
        private readonly _customDialogService: CustomDialogService,
        private readonly _mediaService: MediaService,
        private readonly _formBuilder: FormBuilder,
        private readonly _spinnerService: SpinnerService,
        private readonly _platformsService: PlatformsService,
        public readonly screenSizeService: ScreenSizeService
    ) {}

    ngOnInit(): void {
        this.currentUser$.subscribe((user) => (this.currentUser = user));

        this.getAvailablePlatforms$().subscribe((platforms) => {
            this.availablePlatforms$.next(platforms);
            this.isAnyPlatformConnected = platforms.some((platform) => platform.connected);
        });

        this.igPictureUrl$ = this.igPlatform$.pipe(
            switchMap((platform) => {
                if (!platform) {
                    return of(null);
                }
                return this._postsService
                    .igSearch(extractIgUserNameFromIgLink(platform.socialLink) ?? '', platform._id)
                    .pipe(map((res) => res.data?.business_discovery?.profile_picture_url));
            }),
            takeUntil(this.killSubscriptions$)
        );

        this._activatedRoute.queryParamMap
            .pipe(
                map((params) => params.get('postId')),
                filter((postId): postId is string => !!postId),
                switchMap((postId) =>
                    this._postsService.getAllUnpublishedStoriesFromPostId(postId).pipe(
                        filter((res) => !!res),
                        map((res) =>
                            res.data.map((p) => {
                                const post = new Post(p);
                                post.initWorkingPic('small');
                                return post;
                            })
                        ),
                        filter((posts) => posts.every((p) => p.restaurantId === this.restaurant._id)),
                        tap((posts) => {
                            if (posts?.length) {
                                this.storiesToBeOpened$.next(posts);
                            }
                        }),
                        catchError((err): any => {
                            if (err.status === 404) {
                                this._toastService.openErrorToast(this._translate.instant('posts.posts_list.post_not_found'));
                                this._router.navigate(['./'], { relativeTo: this._activatedRoute, queryParams: null });
                                return EMPTY;
                            }
                            return throwError(() => err);
                        })
                    )
                ),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe(() => {});

        this.restaurant$
            .pipe(
                switchMap((restaurant) => {
                    this.restaurant = restaurant ?? this.restaurant;
                    this.emptyPostsListAndShowLoader();
                    return this._restaurantsService.show(this.restaurant._id).pipe(map((res) => res.data));
                }),
                tap((restaurantWithManagers) => {
                    this.restaurantManagers = restaurantWithManagers?.managers.map((ru) => new User(ru.user));
                }),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                error: (err) => console.warn('err :>> ', err),
            });

        this.restaurant$
            .pipe(
                switchMap(() => this._store.select(selectStoriesSyncState)),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: (syncs) => {
                    const sync = syncs?.[this.restaurant?._id];
                    // if sync date is older than 1 hour, we refresh
                    if (!sync || sync < DateTime.local().minus({ hours: 1 }).toJSDate()) {
                        this.synchronize$.next();
                    } else {
                        this.pageEvent$.next(DEFAULT_PAGE_EVENT);
                    }
                },
                error: (err) => {
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                },
            });

        this.synchronize$
            .pipe(
                switchMap(() =>
                    this._postsService.synchronizeStories(this.restaurant._id).pipe(
                        tap(({ data: storiesData }) => {
                            this._store.dispatch(
                                StoriesActions.editLastSyncDate({ restaurantId: this.restaurant._id, lastSyncDate: new Date() })
                            );
                            this.currentStories.set(
                                sortBy(
                                    storiesData.map((p) => new Story(p)).filter((story) => story.key === PlatformKey.INSTAGRAM),
                                    'socialCreatedAt'
                                )
                            );
                        })
                    )
                ),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: () => {
                    this.pageEvent$.next(DEFAULT_PAGE_EVENT);
                },
                error: (err) => {
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                },
            });

        this.pageEvent$
            .pipe(
                tap(() => {
                    this.fetchingNextPage.set(true);
                    this.loadedMoreWithPagination = true;
                }),
                switchMap((pageEvent) => {
                    this.shouldDisplayNoMoreResultsText = false;
                    this.pagination.pageNumber = pageEvent.pageIndex;
                    this.pagination.pageSize = pageEvent.pageSize;
                    return this._postsService
                        .getRestaurantPostsPaginated(this.restaurant._id, this.pagination, {
                            ...this.filters,
                            category: PostSource.SOCIAL,
                            source: PostSource.SOCIAL,
                            isStory: true,
                        })
                        .pipe(map((res) => res.data));
                }),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: ({ posts, pagination }) => {
                    if (posts.length === 0 && this.loadedMoreWithPagination) {
                        this.shouldDisplayNoMoreResultsText = true;
                    }

                    this.stories.update((stories) =>
                        uniqBy(
                            [
                                ...posts.map(
                                    (p) =>
                                        new Story({
                                            ...p,
                                        })
                                ),
                                ...(stories ?? []),
                            ],
                            '_id'
                        ).sort((p1, p2) => p2.getPostDate().getTime() - p1.getPostDate().getTime())
                    );
                    this.pagination = pagination;
                    this.fetchingNextPage.set(false);
                },
                error: (err) => {
                    this.fetchingNextPage.set(false);
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                },
            });

        this.storiesToBeOpened$
            .pipe(
                filter((posts) => !!posts?.length),
                map((posts) => posts.sort((a, b) => (a.plannedPublicationDate > b.plannedPublicationDate ? 1 : -1))),
                switchMap((posts) =>
                    this._customDialogService
                        .open<
                            EditStoryModalComponent,
                            {
                                posts: Post[];
                                isDisabled: boolean;
                                restaurantManagers: User[];
                            },
                            { next: ActionsAfterEditStoriesClosed }
                        >(
                            EditStoryModalComponent,
                            {
                                width: '100%',
                                height: undefined,
                                panelClass: 'malou-dialog-panel--full',
                                data: {
                                    posts,
                                    isDisabled: false,
                                    restaurantManagers: this.restaurantManagers,
                                },
                            },
                            { animateScreenSize: DialogScreenSize.ALL }
                        )
                        .afterClosed()
                        .pipe(
                            filter(isNotNil),
                            switchMap(({ next }) => {
                                this._router.navigate(['.'], { relativeTo: this._activatedRoute });
                                if (next.reload) {
                                    this.synchronize$.next();
                                }
                                return EMPTY;
                            })
                        )
                ),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: () => {},
            });

        this.filters$.pipe().subscribe((filters) => {
            this.activeFiltersCount = this._computeFilterCount(filters);
            this.filters = filters;
            this.emptyPostsListAndShowLoader();
            this.pageEvent$.next(DEFAULT_PAGE_EVENT);
        });

        this._store
            .select(selectCurrentlyPublishingPosts)
            .pipe(
                distinctUntilChanged((a, b) => isEqual(a, b)),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: (newPosts) => {
                    if (this._areStoriesStillBeingProcessed(newPosts)) {
                        this.stories.update((stories) =>
                            (stories ?? [])
                                .filter((p) => !newPosts.map((currentPost) => currentPost._id).includes(p._id))
                                .concat(newPosts.map((p) => new Story(p)))
                                .sort((p1, p2) => p2.getPostDate().getTime() - p1.getPostDate().getTime())
                        );
                    } else {
                        this.synchronize$.next();
                    }
                },
            });
    }

    emptyPostsListAndShowLoader(): void {
        this.stories.set(null);
        this.loadedMoreWithPagination = false;
    }

    onScrollDown(): void {
        this.emitPageEvent({
            pageIndex: this.pagination.pageNumber + 1,
            pageSize: this.pagination.pageSize,
            length: this.pagination.total,
        });
    }

    onEditStory(storyId: string): void {
        this._router.navigate(['./'], { queryParams: { postId: storyId }, relativeTo: this._activatedRoute });
    }

    onDeleteStory(storyId: string): void {
        this.selectedStories = (this.stories() ?? []).filter((story) => story._id === storyId);
        this.deleteSelectedStories();
    }

    isRemovable(post: Post): boolean {
        return post.published !== PostPublicationStatus.PUBLISHED;
    }

    resetFiltersDates(): void {
        this._store.dispatch({
            type: StoriesActions.resetStoriesFiltersDates.type,
        });
    }

    emitPageEvent($event: PageEvent): void {
        this.pageEvent$.next($event);
    }

    isForLater(post: PostWithJob): boolean {
        return !!post.job && !post.job?.lastRunAt;
    }

    clarifyError(error: string): string {
        if (typeof error === 'object') {
            error = JSON.stringify(error, errorReplacer);
        }
        if (!error) {
            return '';
        }
        if (error.match(/aspect ratio/)) {
            return this._translate.instant('social_posts.ratio_error');
        }
        if (error.match(/session has been invalidated/)) {
            return this._translate.instant('social_posts.reconnect_platform');
        }
        if (error.match(/An unknown error occurred/)) {
            return this._translate.instant('social_posts.facebook_error');
        }
        return error;
    }

    editPostsFilters($event: { [key: string]: any }): void {
        this._store.dispatch({ type: StoriesActions.editStoriesFilters.type, filters: $event });
    }

    resetFilters(): void {
        this._store.dispatch({ type: StoriesActions.editStoriesFilters.type, filters: initialState.filters });
    }

    isChecked(post: Post): boolean {
        return !!this.selectedStories.find((p) => p._id === post._id);
    }

    synchronize(): void {
        this._store.dispatch(StoriesActions.editLastSyncDate({ lastSyncDate: null, restaurantId: this.restaurant._id }));
        this._restaurantsService.reloadSelectedRestaurant();
    }

    openSocialLink(post: Post): void {
        if (post?.socialLink) {
            window.open(post.socialLink, '_blank');
        }
    }

    createNewStory(): void {
        const malouStoryId = uuidv4();
        const platforms = this.connectedPlatformsAcceptingStories();
        if (!platforms) {
            // Can’t happen because the button to create new stories is disabled when
            // connectedPlatformsAcceptingStories() is undefined
            return;
        }
        this._postsService
            .createStory$(this.restaurant._id, {
                keys: platforms.map((p) => p.key),
                malouStoryId,
                plannedPublicationDate: DateTime.now().plus({ days: 1 }).toJSDate().toISOString(),
            })
            .subscribe({
                next: ({ data: post }) => {
                    this._router.navigate(['./'], { queryParams: { postId: post._id }, relativeTo: this._activatedRoute });
                },
                error: (err: HttpErrorResponse) => {
                    if (err.status === 404) {
                        this._toastService.openErrorToast(this._translate.instant('stories.stories_list.no_platform'));
                    } else {
                        this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                    }
                },
            });
    }

    getAvailablePlatforms$(): Observable<AvailablePlatform[]> {
        return combineLatest([this.platformsStore$, this._restaurantsService.restaurantSelected$, this.platformsSelected$]).pipe(
            filter(([platforms, restaurant]) => !!restaurant?._id && !!platforms.platformsData[restaurant._id]),
            map(([platforms, restaurant, platformSelected]) => {
                const connectedPlatforms = platforms.platformsData[(restaurant as Restaurant)._id].map((plat) => plat.key);
                return this.storiesPlatformKeys.map((p) => ({
                    key: p,
                    connected: connectedPlatforms.includes(p),
                    checked: platformSelected.includes(p),
                }));
            }),
            catchError((err) => {
                console.warn('err :>> ', err);
                return [];
            }),
            takeUntil(this.killSubscriptions$)
        );
    }

    toggleSelectedPlatforms(platform: string, currentPlatforms: AvailablePlatform[]): void {
        const platforms = currentPlatforms.map((p) => {
            if (p.key === platform) {
                p.checked = !p.checked;
            }
            return p;
        });

        this.availablePlatforms$.next(platforms);
        this._store.dispatch({
            type: StoriesActions.editStoriesFilters.type,
            filters: { ...this.filters, platforms: platforms.filter((p) => p.checked).map((p) => p.key) },
        });
    }

    emptySelectedPosts(): void {
        this.selectedStories = [];
    }

    getCombinedActionsBtnText(): string {
        return !this.selectedStories.length
            ? this._translate.instant('social_posts.select')
            : this._translate.instant('social_posts.combined_actions', { number: this.selectedStories.length });
    }

    getCombinedActionsBtnTextSmall(): string {
        return !this.selectedStories.length ? '(0)' : `(${this.selectedStories.length})`;
    }

    storyChecked(checked: boolean, post: Story): void {
        if (checked) {
            this.selectedStories.push(post);
        } else {
            this.selectedStories = this.selectedStories.filter((p) => p._id !== post._id);
        }
    }

    deleteSelectedStories(): void {
        this.selectedStories = this.selectedStories.filter((p) => this._canStoryBeDeleted(p));
        if (!this.selectedStories?.length) {
            this._toastService.openErrorToast(this._translate.instant('stories.cannot_delete_published_stories'));
            return;
        }
        this._dialogService.open({
            title: this._translate.instant('stories.delete_stories'),
            message: this._translate.instant('stories.want_delete_stories'),
            variant: DialogVariant.INFO,
            primaryButton: {
                label: this._translate.instant('common.delete'),
                action: () => {
                    this._socialPostsService.deletePost(this.selectedStories);
                    forkJoin(
                        this.selectedStories
                            .filter((story) => story.published === PostPublicationStatus.PENDING)
                            .map((story) => this._postsService.cancelStory$({ malouStoryId: story.malouStoryId }))
                    )
                        .pipe(
                            defaultIfEmpty(null),
                            switchMap(() => this._postsService.deletePosts(this.selectedStories.map((p) => p._id)))
                        )
                        .subscribe({
                            next: () => {
                                this.stories.update((stories) =>
                                    (stories ?? []).filter((p) => !this.selectedStories.map((s) => s._id).includes(p._id))
                                );
                                this.selectedStories = [];
                                this._toastService.openSuccessToast(this._translate.instant('stories.stories_deleted'));
                            },
                            error: (err) => {
                                console.warn('err :>> ', err);
                                this.selectedStories = [];
                                this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                            },
                        });
                },
            },
            secondaryButton: {
                label: this._translate.instant('common.cancel'),
            },
        });
    }

    duplicateSelectedStories(destination: DuplicationDestination): void {
        return this._prepareDuplicateStories({
            isSingleStory: false,
            destination,
            storiesToDuplicate: this.selectedStories,
            inBackground: false,
        });
    }

    duplicateSingleStory({ destination, story }: { destination: DuplicationDestination; story: Story }): void {
        return this._prepareDuplicateStories({
            isSingleStory: true,
            destination,
            storiesToDuplicate: [story],
            inBackground: false,
        });
    }

    private _computeFilterCount(postsFilters: PostsFilters): number {
        const isDefaultPeriod = postsFilters.period === DEFAULT_PERIOD;
        const isDefaultStatuses = postsFilters.publicationStatus?.length === defaultPublicationStatus.length;
        const isDefaultPlatforms = postsFilters.platforms?.length === PlatformDefinitions.getPlatformKeysWithStories().length;
        return [!isDefaultPeriod, !isDefaultStatuses, !isDefaultPlatforms].filter(Boolean).length;
    }

    private _areStoriesStillBeingProcessed(posts: Post[]): boolean {
        return posts.some((story) => story.published === PostPublicationStatus.PENDING);
    }

    private _prepareDuplicateStories({ isSingleStory, destination, storiesToDuplicate, inBackground }: StoryDuplication): void {
        if (destination === DuplicationDestination.HERE) {
            return this._duplicateStoriesHere(storiesToDuplicate, inBackground);
        }

        const duplicationSteps = this._getStepsForDuplication(isSingleStory, storiesToDuplicate, inBackground);

        const initialData: RestaurantsSelectionData = {
            skipOwnRestaurant: false,
            withoutBrandBusiness: false,
            selectedRestaurants: [],
            hasPlatform: [PlatformKey.INSTAGRAM],
        };

        this._customDialogService.open(StepperModalComponent, {
            width: isSingleStory ? '900px' : '600px',
            height: 'unset',
            data: {
                steps: duplicationSteps,
                initialData,
                sharedData: {
                    isStory: true,
                },
                title: this._translate.instant('duplicate_to_restaurants_dialog.title'),
                onSuccess: (stories: Story[]) => {
                    this._onDuplicationSuccess({
                        stories,
                        inBackground,
                        duplicateOutside: true,
                    });
                },
                onError: (error: unknown) => {
                    this._onDuplicationError(error, inBackground);
                },
                shouldDisplayConfirmationCloseModalAfterClosed: true,
            },
        });
    }

    private _duplicateStoryOnRestaurant$(
        story: RequireAtLeastOne<
            { attachments: Media[]; plannedPublicationDate: Date; key?: PlatformKey; keys?: PlatformKey[] },
            'key' | 'keys'
        >,
        restaurantId: string,
        malouStoryId: string
    ): Observable<Story> {
        const media: Media = story.attachments[0];
        return this._mediaService.duplicateMediaForRestaurants(this.restaurant._id, media ? [media] : [], [restaurantId]).pipe(
            switchMap((result) => {
                const duplicatedMedia = new Media(result.data[0]);
                return this._postsService
                    .createStory$(restaurantId, {
                        keys: story.key ? [story.key] : story.keys,
                        malouStoryId,
                        plannedPublicationDate: new Date(story.plannedPublicationDate).toISOString(),
                        attachmentId: duplicatedMedia.id,
                        duplicatedFromRestaurantId: this.restaurant._id,
                    })
                    .pipe(map((res) => new Story(res.data)));
            })
        );
    }

    private _duplicateStory$(
        story: RequireAtLeastOne<
            { attachments: Media[]; plannedPublicationDate: Date; key?: PlatformKey; keys?: PlatformKey[] },
            'key' | 'keys'
        >,
        malouStoryId: string
    ): Observable<Story> {
        const media: Media = story.attachments[0];
        return this._postsService
            .createStory$(this.restaurant._id, {
                // when key is set it means that the post has been published otherwise it's a draft
                keys: story.key ? [story.key] : story.keys,
                malouStoryId,
                plannedPublicationDate: new Date(story.plannedPublicationDate).toISOString(),
                attachmentId: media.id,
                duplicatedFromRestaurantId: this.restaurant._id,
            })
            .pipe(map((res) => new Story(res.data)));
    }

    private _canStoryBeDeleted(post: Story): boolean {
        return post.published !== PostPublicationStatus.PUBLISHED || PlatformDefinitions.canDeleteStory(post.key);
    }

    private _getStepsForDuplication(isSinglePost: boolean, storiesToDuplicate: Story[], inBackground: boolean): Step[] {
        return isSinglePost
            ? [
                  {
                      component: RestaurantsSelectionComponent,
                      subtitle: this._translate.instant('duplicate_to_restaurants_dialog.subtitle'),
                      primaryButtonText: this._translate.instant('common.next'),
                      nextFunction$: (data: RestaurantsSelectionData): Observable<PersonalizePostDuplicationData[]> =>
                          of(this._populateSelectedRestaurantsWithStory(data, storiesToDuplicate[0])),
                  },
                  {
                      component: PersonalizePostDuplicationComponent,
                      subtitle: this._translate.instant('duplicate_to_restaurants_dialog.personalize_post_data.story_subtitle'),
                      primaryButtonText: this._translate.instant('common.duplicate'),
                      nextFunction$: (data: PersonalizePostDuplicationData[]): Observable<(void | Story)[]> =>
                          this._duplicatePersonalizedPostOutside$(data, storiesToDuplicate[0], inBackground),
                  },
              ]
            : [
                  {
                      component: RestaurantsSelectionComponent,
                      subtitle: this._translate.instant('duplicate_to_restaurants_dialog.subtitle'),
                      primaryButtonText: this._translate.instant('common.duplicate'),
                      nextFunction$: (data: RestaurantsSelectionData): Observable<Story[]> =>
                          this._duplicateStoriesOutside$(data, storiesToDuplicate, inBackground),
                  },
              ];
    }

    private _duplicateStoriesHere(storiesToDuplicate: Story[], inBackground): void {
        if (!inBackground) {
            this._spinnerService.show();
        }
        const groupedStories = this._getStoriesListGroupedByMalouId(storiesToDuplicate);
        forkJoin(
            groupedStories.flatMap((stories) => {
                const malouStoryId = uuidv4();
                const plannedPublicationDate = DateTime.now().plus({ days: 1 }).toJSDate();
                return stories.map((story) =>
                    this._duplicateStory$(
                        { attachments: story.attachments ?? [], plannedPublicationDate, key: story.key, keys: story.keys },
                        malouStoryId
                    )
                );
            })
        ).subscribe({
            next: (stories) => {
                this._onDuplicationSuccess({
                    stories,
                    inBackground,
                    duplicateOutside: false,
                });
            },
            error: (err) => {
                this._onDuplicationError(err, inBackground);
            },
        });
    }

    private _duplicateStoriesOutside$(
        data: RestaurantsSelectionData,
        storiesToDuplicate: Story[],
        inBackground: boolean
    ): Observable<Story[]> {
        const { selectedRestaurants } = data;
        const groupedStories = this._getStoriesListGroupedByMalouId(storiesToDuplicate);
        if (!inBackground) {
            this._spinnerService.show();
        }
        return forkJoin(
            selectedRestaurants?.flatMap((restaurant) =>
                groupedStories.flatMap((stories) => {
                    const malouStoryId = uuidv4();
                    const plannedPublicationDate = DateTime.now().plus({ days: 1 }).toJSDate();
                    return stories.map((story) =>
                        restaurant._id === this.restaurant._id
                            ? this._duplicateStory$(
                                  { attachments: story.attachments ?? [], plannedPublicationDate, key: story.key, keys: story.keys },
                                  malouStoryId
                              )
                            : this._duplicateStoryOnRestaurant$(
                                  { attachments: story.attachments ?? [], plannedPublicationDate, key: story.key, keys: story.keys },
                                  restaurant._id,
                                  malouStoryId
                              )
                    );
                })
            ) || []
        );
    }

    private _duplicatePersonalizedPostOutside$(
        storyDuplicationDataPerRestaurant: PersonalizePostDuplicationData[],
        initialStory: Story,
        inBackground: boolean
    ): Observable<(Story | void)[]> {
        const storiesToDuplicate = storyDuplicationDataPerRestaurant.map(({ restaurant, post }) => {
            const published = post.value.status === PostDateStatus.DRAFT ? PostPublicationStatus.DRAFT : PostPublicationStatus.PENDING;
            const plannedPublicationDate = this._getPostDuplicationDate(post);
            const malouStoryId = uuidv4();
            if (restaurant._id === this.restaurant._id) {
                return this._duplicateStory$(
                    {
                        attachments: initialStory.attachments ?? [],
                        plannedPublicationDate,
                        key: initialStory.key,
                        keys: initialStory.keys,
                    },
                    malouStoryId
                );
            }

            if (published === PostPublicationStatus.DRAFT) {
                return this._duplicateStoryOnRestaurant$(
                    { attachments: initialStory.attachments ?? [], plannedPublicationDate, key: initialStory.key, keys: initialStory.keys },
                    restaurant._id,
                    malouStoryId
                );
            }

            return this._duplicateStoryOnRestaurant$(
                { attachments: initialStory.attachments ?? [], plannedPublicationDate, key: initialStory.key, keys: initialStory.keys },
                restaurant._id,
                malouStoryId
            ).pipe(
                switchMap((duplicateRes) =>
                    this._postsService
                        .prepareStory$({ malouStoryId: duplicateRes.malouStoryId, keys: PlatformDefinitions.getPlatformKeysWithStories() })
                        .pipe(map(() => duplicateRes))
                )
            );
        });

        if (!inBackground) {
            this._spinnerService.show();
        }

        return forkJoin(storiesToDuplicate);
    }

    private _getStoriesListGroupedByMalouId(stories: Story[]): Story[][] {
        const groupedStoriesByMalouId = groupBy(stories, 'malouStoryId');
        const storiesWithoutMalouId = groupedStoriesByMalouId.undefined;
        delete groupedStoriesByMalouId.undefined;
        let groupedStories = Object.values(groupedStoriesByMalouId);
        if (storiesWithoutMalouId?.length) {
            groupedStories = groupedStories.concat(storiesWithoutMalouId.map((story) => [story]));
        }
        return groupedStories;
    }

    private _populateSelectedRestaurantsWithStory(
        restaurantSelectionData: RestaurantsSelectionData,
        storyToDuplicate: Story
    ): PersonalizePostDuplicationData[] {
        return (
            restaurantSelectionData.selectedRestaurants?.map((restaurant) => {
                const date = new Date(
                    storyToDuplicate.plannedPublicationDate || storyToDuplicate.socialCreatedAt || addMinutes(2, new Date())
                );

                const post = this._formBuilder.group({
                    status: [
                        storyToDuplicate.published === PostPublicationStatus.PENDING ? PostDateStatus.LATER : PostDateStatus.DRAFT,
                        Validators.required,
                    ],
                    text: [''],
                    date: [date],
                    time: [getTimeStringFromDate(date), isControlValueInTimeFormat(this._translate)],
                });
                return {
                    restaurant,
                    post,
                };
            }) || []
        );
    }

    private _getPostDuplicationDate(
        post: INullableFormGroup<{
            status: PostDateStatus;
            date: Date;
            text: string;
            time: string;
        }>
    ): Date {
        switch (post.value.status) {
            case PostDateStatus.DRAFT:
            case PostDateStatus.LATER:
                const date = post.value.date ?? new Date();
                if (date && post.value.time) {
                    date.setHours(parseInt(post.value.time.substring(0, 2), 10));
                    date.setMinutes(parseInt(post.value.time.substring(3), 10));
                }
                return date;

            case PostDateStatus.NOW:
            default:
                return new Date();
        }
    }

    private _onDuplicationSuccess = ({
        stories: storiesToDuplicate,
        inBackground,
        duplicateOutside,
    }: {
        stories: Story[];
        inBackground: boolean;
        duplicateOutside: boolean;
    }): void => {
        if (!inBackground) {
            this._spinnerService.hide();
        }
        if (storiesToDuplicate.length) {
            if (duplicateOutside) {
                this._toastService.openSuccessToast(this._translate.instant('stories.stories_duplicated'));
            }
            this.stories.update((stories) =>
                (stories ?? [])
                    .concat(storiesToDuplicate.filter((story) => story.restaurantId === this.restaurant._id))
                    .sort((p1, p2) => p2.getPostDate().getTime() - p1.getPostDate().getTime())
            );
        }
        this.selectedStories = [];
        this._customDialogService.closeAll();
    };

    private _onDuplicationError = (error: unknown, inBackground: boolean): void => {
        console.warn('err :>>', error);
        this.selectedStories = [];
        if (!inBackground) {
            this._spinnerService.hide();
        }
        this._toastService.openErrorToast(this._translate.instant('stories.duplication_failed'));
    };
}
