import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { fromBuffer } from 'file-type/core';
import { omit } from 'lodash';
import { DateTime } from 'luxon';
import { forkJoin, from, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
    AiPostDuplicationCaptionResponseDto,
    CreateStoryBodyDto,
    GetTiktokQueryCreatorInfoDto,
    GetTop3PostsInsightsBodyDto,
    GetTop3PostsInsightsResponseDto,
    SwapPlannedPublicationDatesPayloadDto,
} from '@malou-io/package-dto';
import {
    ApiResultV2,
    MediaCategory,
    PlatformDefinitions,
    PlatformKey,
    PostPublicationStatus,
    PostSource,
    PostType,
} from '@malou-io/package-utils';

import { BindingIdKey } from ':core/constants';
import { environment } from ':environments/environment';
import * as JimoActions from ':modules/jimo/jimo.actions';
import { MediaService } from ':modules/media/media.service';
import { DialogDataType, DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import {
    getfileExtensionFromUrl,
    isSameDay,
    objectToSnakeCase,
    removeNullOrUndefinedField,
    toEndOfDay,
    toStartOfDay,
} from ':shared/helpers';
import {
    AgendaJobApiResponse,
    ApiResult,
    Keyword,
    Media,
    Pagination,
    Post,
    PostDto,
    PostsFilters,
    PostsWithInsightsByPlatforms,
    PostWithJob,
} from ':shared/models';

import { DialogService } from './dialog.service';
import { RestaurantsService } from './restaurants.service';

export interface TemporaryAiPostDuplicationCaptions {
    restaurantId: string;
    postCaption: string;
    keywords: Keyword[];
}

@Injectable({
    providedIn: 'root',
})
export class PostsService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/posts`;

    constructor(
        private readonly _http: HttpClient,
        private readonly _mediaService: MediaService,
        private readonly _dialogService: DialogService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _translate: TranslateService,
        private readonly _store: Store
    ) {}

    getPost(postId: string, withJobs: boolean = false): Observable<ApiResult<PostWithJob>> {
        return this._http.get<ApiResult<PostWithJob>>(`${this.API_BASE_URL}/${postId}${withJobs ? '?with_jobs=true' : ''}`).pipe(
            map((res) => {
                const post = new PostWithJob(res.data);
                post.http = this._http;
                res.data = post;
                return res;
            })
        );
    }

    createPost(restaurantId: string, data: { post: Partial<Post>; keys?: string[] }): Observable<ApiResult<Post>> {
        return this._http.post<ApiResult<Post>>(`${this.API_BASE_URL}/restaurants/${restaurantId}`, data, { withCredentials: true }).pipe(
            map((res) => {
                const p = new Post(res.data);
                p.http = this._http;
                res.data = p;
                return res;
            })
        );
    }

    duplicatePost(
        restaurantId: string,
        data: { post: Partial<Post>; keys?: string[]; draft?: boolean; duplicate_post_id: string }
    ): Observable<ApiResult<Post>> {
        return this._http
            .post<ApiResult<Post>>(
                `${this.API_BASE_URL}/restaurants/${restaurantId}/duplicate`,
                {
                    ...data,
                    post: mapToDuplicateDto(data.post),
                },
                { withCredentials: true }
            )
            .pipe(
                map((res) => {
                    const p = new Post(res.data);
                    p.http = this._http;
                    res.data = p;
                    return res;
                })
            );
    }

    preparePost(
        postId: string,
        data: { post?: Partial<Post>; keys?: string[]; draft?: boolean },
        restaurantId = this._restaurantsService.currentRestaurant._id
    ): Observable<ApiResult<Post>> {
        return this._http
            .put<ApiResult<Post>>(`${this.API_BASE_URL}/${postId}/prepare`, data, { withCredentials: true, params: { restaurantId } })
            .pipe(
                map((res) => {
                    const p = new Post(res.data);
                    p.http = this._http;
                    res.data = p;
                    return res;
                })
            );
    }

    swapPlannedPublicationDates(body: SwapPlannedPublicationDatesPayloadDto): Observable<ApiResult<void>> {
        return this._http.post<ApiResult<void>>(`${this.API_BASE_URL}/swap-planned-publication-dates`, body, { withCredentials: true });
    }

    updatePost(postId: string, data: Partial<PostDto>): Observable<ApiResult<PostDto>> {
        return this._http.put<ApiResult<PostDto>>(`${this.API_BASE_URL}/${postId}`, mapToUpdateDto(data), { withCredentials: true });
    }

    deletePost(postId: string, restaurantId = this._restaurantsService.currentRestaurant._id): Observable<ApiResult> {
        return this._http.delete<ApiResult>(`${this.API_BASE_URL}/${postId}`, { withCredentials: true, params: { restaurantId } });
    }

    deletePosts(postIds: string[], restaurantId = this._restaurantsService.currentRestaurant._id): Observable<ApiResult> {
        return this._http.post<ApiResult>(
            `${this.API_BASE_URL}/delete`,
            { ids: postIds },
            { withCredentials: true, params: { restaurantId } }
        );
    }

    queryTiktokCreatorInfo(restaurantId: string): Observable<ApiResultV2<GetTiktokQueryCreatorInfoDto>> {
        return this._http.get<ApiResultV2<GetTiktokQueryCreatorInfoDto>>(
            `${this.API_BASE_URL}/restaurants/${restaurantId}/tiktok/query_creator_info`
        );
    }

    getRestaurantPostsPaginated(
        restaurantId: string,
        pagination: Pagination,
        filters: PostsFilters
    ): Observable<
        ApiResult<{
            posts: Post[];
            pagination: Pagination;
            jobs: AgendaJobApiResponse[];
        }>
    > {
        const cleanFilters = removeNullOrUndefinedField(objectToSnakeCase({ ...filters, ...pagination }));
        return this._http
            .get<
                ApiResult<{ posts: Post[]; pagination: Pagination; jobs: AgendaJobApiResponse[] }>
            >(`${this.API_BASE_URL}/restaurants/${restaurantId}`, { params: cleanFilters })
            .pipe(
                map((res) => {
                    res.data.posts = res.data.posts.map((p) => {
                        const post = new Post(p);
                        post.http = this._http;
                        return post;
                    });
                    return res;
                })
            );
    }

    getPostsWithoutHashtagsByBindingId(bindingId: string, bindingIdKey = BindingIdKey.BINDING_ID): Observable<ApiResult<Post[]>> {
        return this._http
            .get<ApiResult<Post[]>>(`${this.API_BASE_URL}/by_binding_id?binding_id=${bindingId}&binding_id_key=${bindingIdKey}`)
            .pipe(
                map((res) => {
                    res.data = res.data.map((p) => {
                        const post = new Post(omit(p, 'hashtags'));
                        post.http = this._http;
                        return post;
                    });
                    return res;
                })
            );
    }

    getPostsByBindingId(bindingId: string, bindingIdKey = BindingIdKey.BINDING_ID): Observable<ApiResult<Post[]>> {
        return this._http
            .get<ApiResult<Post[]>>(`${this.API_BASE_URL}/by_binding_id?binding_id=${bindingId}&binding_id_key=${bindingIdKey}`)
            .pipe(
                map((res) => {
                    res.data = res.data.map((p) => {
                        const post = new Post(p);
                        post.http = this._http;
                        return post;
                    });
                    return res;
                })
            );
    }

    synchronizePosts(restaurantId: string, platformKeys: PlatformKey[]): Observable<ApiResult> {
        return this._http.post<ApiResult>(`${this.API_BASE_URL}/restaurants/${restaurantId}/synchronize`, { platformKeys });
    }

    synchronizeStories(restaurantId: string): Observable<ApiResult<PostDto[]>> {
        return this._http.get<ApiResult<PostDto[]>>(`${this.API_BASE_URL}/restaurants/${restaurantId}/stories/synchronize`, {
            withCredentials: true,
        });
    }

    deleteJobsForPost(postId: string): Observable<ApiResult> {
        return this._http.delete<ApiResult>(`${this.API_BASE_URL}/${postId}/jobs`, {});
    }

    igSearch(text: string, platformId: string): Observable<ApiResult> {
        return this._http.get<ApiResult>(`${this.API_BASE_URL}/platforms/${platformId}/igsearch?text=${text}`);
    }

    getCompetitorsPosts(platformId: string): Observable<ApiResult<Post[]>> {
        return this._http.get<ApiResult<Post[]>>(`${this.API_BASE_URL}/platforms/${platformId}/competitors`);
    }

    getPostsWithInsights(
        restaurantId: string,
        platforms: string[],
        startDate: Date | null,
        endDate: Date | null
    ): Observable<ApiResult<PostsWithInsightsByPlatforms>> {
        return this._http.get<ApiResult<PostsWithInsightsByPlatforms>>(`${this.API_BASE_URL}/restaurants/${restaurantId}/insights`, {
            params: objectToSnakeCase({ platforms, startDate, endDate }),
        });
    }

    getLastSocialPostWithHashtags(restaurantId: string): Observable<ApiResult<Post>> {
        return this._http.get<ApiResult<Post>>(`${this.API_BASE_URL}/restaurants/${restaurantId}/lastsocial`).pipe(
            map((res) => {
                res.data = new Post(res.data);
                return res;
            })
        );
    }

    refresh(postId: string): Observable<ApiResult<Post>> {
        return this._http.get<ApiResult<Post>>(`${this.API_BASE_URL}/${postId}/refresh`);
    }

    refreshIfPostLacksSocialAttachments(post: Post): void {
        if (
            post.published === PostPublicationStatus.PUBLISHED &&
            post.socialId &&
            post.attachments?.length &&
            !post.socialAttachments?.length
        ) {
            this.refresh(post._id).subscribe();
        }
    }

    createStory$(restaurantId: string, body: CreateStoryBodyDto): Observable<ApiResult<PostDto>> {
        this._store.dispatch({ type: JimoActions.incrementJimoUserStoriesCreatedCount.type });
        return this._http.post<ApiResult>(
            `${this.API_BASE_URL}/restaurants/${restaurantId}/stories`,
            { ...body },
            { withCredentials: true }
        );
    }

    prepareStory$(body: Partial<Pick<Post, 'keys' | 'malouStoryId'>>): Observable<ApiResult<void>> {
        return this._http.post<ApiResult<void>>(`${this.API_BASE_URL}/stories/prepare`, { ...body }, { withCredentials: true });
    }

    cancelStory$(body: Partial<Pick<Post, 'malouStoryId'>>): Observable<ApiResult<void>> {
        return this._http.post<ApiResult<void>>(`${this.API_BASE_URL}/stories/cancel`, { ...body }, { withCredentials: true });
    }

    getPostsBetweenDates$(
        restaurantId: string,
        startDate: string,
        endDate: string
    ): Observable<ApiResult<{ posts: Post[]; jobs: AgendaJobApiResponse[] }>> {
        try {
            startDate = toStartOfDay(startDate);
            endDate = toEndOfDay(endDate);
            return this._http
                .get<
                    ApiResult<{ posts: Post[]; jobs: AgendaJobApiResponse[] }>
                >(`${this.API_BASE_URL}/restaurants/${restaurantId}/date/${startDate}/${endDate}`)
                .pipe(
                    map((res) => {
                        res.data = {
                            posts: res.data?.posts?.map((p) => new Post(p)),
                            jobs: res.data?.jobs,
                        };
                        return res;
                    })
                );
        } catch (error) {
            return throwError(() => error);
        }
    }

    getAllUnpublishedStoriesFromPostId(postId: string): Observable<ApiResult<Post[]>> {
        return this._http.get<ApiResult<Post[]>>(`${this.API_BASE_URL}/${postId}/stories`);
    }

    findPostsByMedia(restaurantId: string, mediaIds: string[]): Observable<ApiResult<Post[]>> {
        return this._http.post<ApiResult<Post[]>>(`${this.API_BASE_URL}/restaurants/${restaurantId}/media`, { mediaIds });
    }

    updatePostsMedia(posts: Post[], mediaIds: string[]): Observable<ApiResult<Post[]>> {
        return this._http.post<ApiResult<Post[]>>(`${this.API_BASE_URL}/deleted_media`, { posts, mediaIds });
    }

    duplicatePostTextsForRestaurants({
        postIdToDuplicate,
        restaurantIds,
    }: {
        postIdToDuplicate: string;
        restaurantIds: string[];
    }): Observable<ApiResult<AiPostDuplicationCaptionResponseDto>> {
        return this._http.post<ApiResult<AiPostDuplicationCaptionResponseDto>>(`${this.API_BASE_URL}/duplicate_seo_posts_with_ai`, {
            postIdToDuplicate,
            restaurantIds,
        });
    }

    duplicatePost$(fromPost: Post, toKeys: PlatformKey[], draft = true): Observable<Post> {
        let postData: Partial<Post> = {};
        const to = getPostCategoryFromKeys(toKeys);
        switch (to) {
            case PostSource.SOCIAL:
                postData = Object.assign({}, fromPost.getSocialPostData(), { keys: toKeys });
                break;
            case PostSource.SEO:
                postData = Object.assign({}, fromPost.getSeoPostData(), { keys: toKeys });
                break;
            default:
                break;
        }
        return this.willCreateMediaBefore$(fromPost, postData, to).pipe(
            switchMap((duplicateData) =>
                this.duplicatePost(fromPost.restaurantId, {
                    post: duplicateData,
                    keys: toKeys,
                    draft,
                    duplicate_post_id: fromPost._id,
                }).pipe(map((res) => res.data))
            )
        );
    }

    willCreateMediaBefore$(fromPost: Post, postData: Partial<Post>, to: PostSource): Observable<Partial<Post>> {
        const dialogconfig: DialogDataType = {
            title: this._translate.instant('posts.info'),
            message: this._translate.instant('posts.duplicate_media_info'),
            variant: DialogVariant.INFO,
            primaryButton: {
                label: this._translate.instant('common.ok'),
                action: () => {},
            },
        };
        const { socialAttachments, attachments, thumbnail } = fromPost;
        let duplicate$ = of(postData);
        if (socialAttachments[0] && !attachments?.[0]) {
            // platform media only
            const { type } = getfileExtensionFromUrl(socialAttachments[0].urls.original);
            if (type === 'video' && to === PostSource.SEO) {
                // try to duplicate a video on gmb
                duplicate$ = this._dialogService
                    .open(dialogconfig)
                    .afterClosed()
                    .pipe(map(() => postData));
            } else {
                duplicate$ = this._http.get(socialAttachments[0].urls.original, { responseType: 'arraybuffer' }).pipe(
                    switchMap((response) => forkJoin([from(fromBuffer(response)), of(response)])),
                    switchMap(([fileTypeResult, buffer]) => {
                        if (!fileTypeResult?.mime) {
                            return throwError('file type not found');
                        }
                        const [fileType, extension] = fileTypeResult?.mime?.split('/');
                        const blob = new Blob([buffer]);
                        const file = new File([blob], new Date() + 'duplicate.' + extension, { type: fileType + '/' + extension });
                        return this._mediaService.uploadAndCreateMedia([
                            {
                                data: file,
                                metadata: {
                                    category: MediaCategory.ADDITIONAL,
                                    restaurantId: fromPost.restaurantId,
                                },
                            },
                        ]);
                    }),
                    map(({ data }) => {
                        (postData.attachments as Media[])[0] = new Media(data[0]);
                        return postData;
                    }),
                    catchError((error) => {
                        console.warn('error :>> ', error);
                        return of(postData);
                    })
                );
            }
        } else if (attachments?.[0]) {
            if (this._restaurantsService.currentRestaurant._id === fromPost.restaurantId) {
                return duplicate$;
            }
            if (to === PostSource.SEO && attachments?.[0].type === 'video') {
                // try to duplicate a video on gmb
                duplicate$ = this._dialogService
                    .open(dialogconfig)
                    .afterClosed()
                    .pipe(
                        map(() => {
                            postData.attachments = undefined;
                            postData.attachmentsName = undefined;
                            return postData;
                        })
                    );
            } else {
                const restaurantId = fromPost.restaurantId;
                duplicate$ = this._mediaService.duplicateMediaForRestaurants(restaurantId, [attachments[0]], [restaurantId]).pipe(
                    map(({ data }) => {
                        (postData.attachments as Media[])[0] = new Media(data[0]);
                        return postData;
                    }),
                    switchMap(() => {
                        if (thumbnail) {
                            return this._mediaService.duplicateMediaForRestaurants(restaurantId, [thumbnail], [restaurantId]).pipe(
                                map(({ data }) => {
                                    postData.thumbnail = new Media(data[0]);
                                    return postData;
                                })
                            );
                        }
                        return of(postData);
                    }),
                    catchError((error) => {
                        console.warn('error :>> ', error);
                        return of(postData);
                    })
                );
            }
        }
        return duplicate$;
    }

    createEmptyDraft$(
        restaurantId: string,
        checkedPlatforms: string[],
        {
            postType = PostType.IMAGE,
            postDate = DateTime.now().plus({ days: 1, hours: 1 }).toJSDate(),
            isStory = false,
        }: { postType?: PostType; postDate?: Date; isStory?: boolean } = {
            postType: PostType.IMAGE,
            postDate: DateTime.now().plus({ days: 1, hours: 1 }).toJSDate(),
            isStory: false,
        }
    ): Observable<ApiResult<Post>> {
        const inOneHour = new Date(new Date().setHours(new Date().getHours() + 1));
        const plannedPublicationDate = isSameDay(postDate, new Date()) ? inOneHour : postDate;
        return this.createPost(restaurantId, {
            post: {
                text: '',
                createdAt: new Date(),
                plannedPublicationDate,
                postType,
                isStory,
            },
            keys: checkedPlatforms,
        });
    }

    createEmptySocialDraft$(
        restaurantId: string,
        {
            postType = PostType.IMAGE,
            postDate = DateTime.now().plus({ days: 1, hours: 1 }).toJSDate(),
        }: { postType?: PostType; postDate?: Date } = {
            postType: PostType.IMAGE,
            postDate: DateTime.now().plus({ days: 1, hours: 1 }).toJSDate(),
        }
    ): Observable<ApiResult<Post>> {
        const checkedPlatforms = PlatformDefinitions.getSocialDefaultPlatformKeysForPost();
        return this.createEmptyDraft$(restaurantId, checkedPlatforms, { postType, postDate });
    }

    createEmptySeoDraft$(
        restaurantId: string,
        {
            postType = PostType.IMAGE,
            postDate = DateTime.now().plus({ days: 1, hours: 1 }).toJSDate(),
        }: { postType?: PostType; postDate?: Date } = {
            postType: PostType.IMAGE,
            postDate: DateTime.now().plus({ days: 1, hours: 1 }).toJSDate(),
        }
    ): Observable<ApiResult<Post>> {
        const checkedPlatforms = PlatformDefinitions.getSeoPlatformKeysWithPost();
        return this.createEmptyDraft$(restaurantId, checkedPlatforms, { postType, postDate });
    }

    getUserStoryCreatedCount(): Observable<ApiResult<number>> {
        return this._http.get<ApiResult<number>>(`${this.API_BASE_URL}/stories/count`);
    }

    resizePostAttachments$(
        medias: Media[],
        postSource: PostSource,
        options?: {
            shouldForceResizeToRecommendedSize?: boolean;
        }
    ): Observable<Media[]> {
        return this._http
            .post<ApiResult<Media[]>>(`${this.API_BASE_URL}/attachments/resize`, {
                postMedias: medias,
                postSource: postSource.toLowerCase(),
                options,
            })
            .pipe(
                map((res) => {
                    res.data = res.data.map((m) => new Media(m));
                    return res.data;
                })
            );
    }

    getTop3PostsInsights(body: GetTop3PostsInsightsBodyDto): Observable<GetTop3PostsInsightsResponseDto[]> {
        return this._http.post<ApiResultV2<GetTop3PostsInsightsResponseDto[]>>(`${this.API_BASE_URL}/get-top-3-posts-insights`, body).pipe(
            map((res) => res.data),
            catchError(() => of([]))
        );
    }

    getTop3PostsInsightsV2(body: GetTop3PostsInsightsBodyDto): Observable<GetTop3PostsInsightsResponseDto[]> {
        return this._http
            .post<ApiResultV2<GetTop3PostsInsightsResponseDto[]>>(`${this.API_BASE_URL}/get-top-3-posts-insights-v2`, body)
            .pipe(
                map((res) => res.data),
                catchError(() => of([]))
            );
    }
}

export const getPostCategoryFromKeys = (keys: PlatformKey[]): PostSource => {
    if (keys?.some((k) => PlatformDefinitions.getSocialPlatformKeysWithPost().indexOf(k) >= 0)) {
        return PostSource.SOCIAL;
    }
    return PostSource.SEO;
};

export const mapToUpdateDto = (data: Partial<PostDto>): any => ({
    ...data,
    attachments: data.attachments?.map((a) => a.id),
});

export const mapToDuplicateDto = (data: Partial<PostDto>): any => ({
    ...data,
    attachments: data.attachments?.map((a) => a.id),
    ...(data.thumbnail && { thumbnail: data.thumbnail.id }),
});
