import { PlatformKey } from '@malou-io/package-utils';

import {
    AggregatedBoostersStatisticsData,
    AggregatedBoostersStatisticsDataV2,
} from ':modules/aggregated-statistics/boosters/booster.interface';
import { ReviewsRatingsAverageData } from ':modules/aggregated-statistics/e-reputation/reviews-ratings-average/reviews-ratings-average.component';
import {
    ChartReviewsStats,
    DatesAndPeriod,
    InsightsByPlatformByRestaurant,
    MalouTimeScalePeriod,
    ReviewWithAnalysis,
} from ':shared/models';

export interface AggregatedStatisticsState {
    filters: {
        dates: DatesAndPeriod;
        platforms: Record<PlatformFilterPage, PlatformKey[]>;
        restaurantIds: string[];
        roiRestaurantIds: string[];
        totemIds: string[];
        timeScale: MalouTimeScalePeriod;
    };
    data: {
        reviewsWithAnalysisByRestaurant: Record<string, ReviewWithAnalysis[]>;
        platformsRatingsByRestaurant: InsightsByPlatformByRestaurant;
        reviewCounts: ChartReviewsStats[];
        averageReviewsRatings: ReviewsRatingsAverageData[];
        boosterStatsData: AggregatedBoostersStatisticsData | undefined;
        boosterStatsDataV2: AggregatedBoostersStatisticsDataV2 | undefined;
    };
    page: PlatformFilterPage;
    loaded: boolean;
}

export enum PlatformFilterPage {
    E_REPUTATION = 'E_REPUTATION',
    SOCIAL_NETWORKS = 'SOCIAL_NETWORKS',
    BOOSTERS = 'BOOSTERS',
    ROI = 'ROI',
    SEO = 'SEO',
}
