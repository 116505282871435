import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, inject, Signal, signal, ViewChild, WritableSignal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { APP_DEFAULT_LANGUAGE, ApplicationLanguage, CommentOptionValue, KeywordScoreTextType } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { AiService } from ':core/services/ai.service';
import { ExperimentationService } from ':core/services/experimentation.service';
import { RestaurantAiSettingsService } from ':core/services/restaurant-ai-settings.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { LocalStorage } from ':core/storage/local-storage';
import { AdvancedAiSettingsComponent } from ':modules/automations/review-reply-automations/restaurant-ai-settings/edit-restaurant-ai-settings-modal/advanced-ai-settings/advanced-ai-settings.component';
import { GeneralAiSettingsComponent } from ':modules/automations/review-reply-automations/restaurant-ai-settings/edit-restaurant-ai-settings-modal/general-ai-settings/general-ai-settings.component';
import { CloseWithoutSavingModalComponent } from ':shared/components/close-without-saving-modal/close-without-saving-modal.component';
import { Indication, KeywordsScoreGaugeComponent } from ':shared/components/keywords-score-gauge/keywords-score-gauge.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { StarGaugeComponent } from ':shared/components/star-gauge/star-gauge.component';
import { formatDate } from ':shared/helpers';
import { highlightKeywordsInText, Keyword } from ':shared/models';
import { CommentOption } from ':shared/models/comment-option';
import { AiSettingsSignatureWithId, RestaurantAiSettings, RestaurantAiSettingsFormData } from ':shared/models/restaurant-ai-settings';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

export interface LangOption {
    value: ApplicationLanguage;
    text: string;
}

export interface EditRestaurantAiSettingsModalInputData {
    restaurantAiSettings: RestaurantAiSettings;
    selectedKeywords: Keyword[];
}

interface RestaurantAiSettingsTab {
    label: string;
    key: TabKeys;
}

enum TabKeys {
    GENERAL = 'general',
    ADVANCED = 'advanced',
}

@Component({
    selector: 'app-edit-restaurant-ai-settings-modal',
    templateUrl: './edit-restaurant-ai-settings-modal.component.html',
    styleUrls: ['./edit-restaurant-ai-settings-modal.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        MatTabsModule,
        MatTooltipModule,
        TranslateModule,
        AdvancedAiSettingsComponent,
        CloseWithoutSavingModalComponent,
        GeneralAiSettingsComponent,
        MalouSpinnerComponent,
        StarGaugeComponent,
        SelectComponent,
        FormsModule,
        ReactiveFormsModule,
        ImagePathResolverPipe,
        KeywordsScoreGaugeComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditRestaurantAiSettingsModalComponent {
    @ViewChild('keywordsScoreGauge') keywordsScoreGauge: KeywordsScoreGaugeComponent;

    private readonly _data: EditRestaurantAiSettingsModalInputData = inject(MAT_DIALOG_DATA);
    private readonly _restaurantAiSettingsService = inject(RestaurantAiSettingsService);
    private readonly _screenSizeService = inject(ScreenSizeService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _aiService = inject(AiService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _dialogRef = inject(MatDialogRef<EditRestaurantAiSettingsModalComponent>);
    private readonly _experimentationService = inject(ExperimentationService);

    readonly SvgIcon = SvgIcon;
    readonly restaurantAiSettings = signal<RestaurantAiSettingsFormData | undefined>(undefined);
    readonly selectedKeywords = signal<Keyword[]>([]);

    readonly TabKeys = TabKeys;
    private readonly _GENERAL_TAB: RestaurantAiSettingsTab = {
        key: TabKeys.GENERAL,
        label: this._translateService.instant('restaurant_ai_settings.modals.upsert.tabs.general.title'),
    };
    private readonly _ADVANCED_TAB: RestaurantAiSettingsTab = {
        key: TabKeys.ADVANCED,
        label: this._translateService.instant('restaurant_ai_settings.modals.upsert.tabs.advanced.title'),
    };
    readonly TABS = signal<RestaurantAiSettingsTab[]>([this._GENERAL_TAB, this._ADVANCED_TAB]);
    readonly selectedTabIndex = signal<number>(0);
    readonly selectedTab = computed<RestaurantAiSettingsTab>(() => this.TABS()[this.selectedTabIndex()]);

    readonly areRestaurantAiSettingsValid = computed(() => !!this.restaurantAiSettings()?.restaurantName);

    readonly displayCloseModal = signal(false);
    readonly isSubmitting = signal(false);
    readonly currentDate = signal(formatDate(new Date()));

    readonly CommentOptionValue = CommentOptionValue;
    readonly AVAILABLE_COMMENT_OPTIONS: CommentOption[] = [
        { value: CommentOptionValue.WITH, text: this._translateService.instant('restaurant_ai_settings.modals.upsert.with_comments') },
        {
            value: CommentOptionValue.WITHOUT,
            text: this._translateService.instant('restaurant_ai_settings.modals.upsert.without_comments'),
        },
    ];

    readonly AVAILABLE_LANG_OPTIONS: LangOption[] = Object.values(ApplicationLanguage).map((lang) => this._getLangOptionFromLang(lang));

    readonly DEFAULT_LANG = this.AVAILABLE_LANG_OPTIONS.find((lang) => lang.value === LocalStorage.getLang());

    readonly keywordScoreReviewSpecificData = computed<{ reviewRelatedBricksCount?: number; reviewText: string } | null>(() =>
        this.isReviewRevampFeatureEnabled()
            ? {
                  reviewRelatedBricksCount: 0,
                  reviewText:
                      this.previewReviewReplyForm.get('withComment')?.value?.value === CommentOptionValue.WITH
                          ? this._translateService.instant('restaurant_ai_settings.modals.upsert.example_preview')
                          : '',
              }
            : null
    );

    readonly previewReviewReplyForm = new FormGroup({
        withComment: new FormControl(this.AVAILABLE_COMMENT_OPTIONS[0]),
        language: new FormControl(this.DEFAULT_LANG),
    });
    readonly currentLang = signal<ApplicationLanguage>(this.DEFAULT_LANG?.value ?? APP_DEFAULT_LANGUAGE);
    readonly currentWithCommentOption = signal<CommentOptionValue>(this.AVAILABLE_COMMENT_OPTIONS[0].value);

    readonly isLoadingPreview = signal<boolean>(false);
    readonly previewReviewReply = signal<string>('');

    readonly formattedPreviewReviewReply: Signal<string> = computed(() =>
        highlightKeywordsInText({
            text: this.previewReviewReply(),
            keywords: this._data.selectedKeywords,
            restaurantName: this.restaurantAiSettings()?.restaurantName,
            currentLang: this.currentLang(),
        })
    );

    readonly previewReviewReply$ = toObservable(this.previewReviewReply);
    readonly oneHour$ = of(1);
    readonly reviewerName$ = of(this._translateService.instant('restaurant_ai_settings.modals.upsert.reviewer_name'));
    readonly restaurantAiSettings$ = toObservable(this.restaurantAiSettings);
    readonly keywords$ = of(this._data.selectedKeywords);
    readonly restaurant$ = this._restaurantsService.restaurantSelected$;
    readonly textType$ = of(KeywordScoreTextType.HIGH_RATE_REVIEW);
    readonly lang$ = toObservable(this.currentLang);
    readonly keywordsIndicationList: WritableSignal<Indication[]> = signal([]);
    readonly isReviewRevampFeatureEnabled = toSignal(this._experimentationService.isFeatureEnabled$('release-review-revamp'));

    readonly isPhoneScreen = toSignal(this._screenSizeService.isPhoneScreen$, { initialValue: this._screenSizeService.isPhoneScreen });
    isTouched = false;
    isFirstEffectCall = true;

    constructor() {
        this.restaurantAiSettings.set(
            new RestaurantAiSettingsFormData({
                ...this._data.restaurantAiSettings,
                signaturesForForm: this._data.restaurantAiSettings?.signatures?.length
                    ? this._data.restaurantAiSettings?.signatures?.map((signature) => ({
                          id: uuid(),
                          text: signature,
                      }))
                    : [this._getNewSignature()],
            })
        );
        this.selectedKeywords.set(this._data.selectedKeywords);

        effect(() => {
            this.restaurantAiSettings(); // only used to trigger the effect
            if (this.isFirstEffectCall) {
                this.isFirstEffectCall = false;
                return;
            }
            this.isTouched = true;
        });

        effect(() => {
            const defaultLang = this.restaurantAiSettings()?.defaultLanguageResponse;
            const isWithoutComment = this.currentWithCommentOption() === CommentOptionValue.WITHOUT;
            if (isWithoutComment && defaultLang) {
                this.previewReviewReplyForm.get('language')?.setValue(this._getLangOptionFromLang(defaultLang));
            }
        });
    }

    confirmClose(restaurantAiSettings: RestaurantAiSettings | null = null): void {
        this._dialogRef.close(restaurantAiSettings);
    }

    getLangOptions(): LangOption[] {
        const defaultLang = this.restaurantAiSettings()?.defaultLanguageResponse;
        if (this.currentWithCommentOption() === CommentOptionValue.WITHOUT && defaultLang) {
            return [
                {
                    value: defaultLang,
                    text: this._translateService.instant(`common.langs.${defaultLang}`),
                },
            ];
        }
        return this.AVAILABLE_LANG_OPTIONS;
    }

    close(): void {
        if (this.isSubmitting()) {
            return;
        }
        if (this.isTouched) {
            this.displayCloseModal.set(true);
        } else {
            this.confirmClose();
        }
    }

    displayOption(option: { value: any; text: string }): string {
        return option.text;
    }

    generateResponsePreview(): void {
        this.isLoadingPreview.set(true);
        const restaurantAiSettings = this.restaurantAiSettings();
        if (!restaurantAiSettings) {
            this._toastService.openErrorToast(
                this._translateService.instant('restaurant_ai_settings.modals.upsert.error_generating_preview')
            );
            return;
        }
        restaurantAiSettings.signatures = this.restaurantAiSettings()?.signaturesForForm?.map(({ text }) => text);
        this._aiService
            .answerReviewPreview({
                restaurantId: restaurantAiSettings?.restaurantId ?? '',
                restaurantAiSettings: restaurantAiSettings
                    ? new RestaurantAiSettings({
                          ...restaurantAiSettings,
                          shouldTranslateSignature: restaurantAiSettings.shouldTranslateSignature ?? false,
                          shouldTranslateCatchphrase: restaurantAiSettings.shouldTranslateCatchphrase ?? false,
                      })
                    : null,
                sourceLanguage: LocalStorage.getLang(),
                lang: this.currentLang() ?? APP_DEFAULT_LANGUAGE,
                reviewerName: this._translateService.instant('restaurant_ai_settings.modals.upsert.reviewer_name'),
                text:
                    this.currentWithCommentOption() === CommentOptionValue.WITH
                        ? this._translateService.instant('restaurant_ai_settings.modals.upsert.example_preview')
                        : '',
            })
            .subscribe({
                next: (res) => {
                    this.isLoadingPreview.set(false);
                    this.previewReviewReply.set(res.data);
                },
                error: (err) => {
                    this.isLoadingPreview.set(false);
                    console.warn(err);
                    this._toastService.openErrorToast(
                        this._translateService.instant('restaurant_ai_settings.modals.upsert.error_generating_preview')
                    );
                },
            });
    }

    submit(): void {
        const restaurantAiSettings = this.restaurantAiSettings();

        if (!this.areRestaurantAiSettingsValid() || !restaurantAiSettings) {
            return;
        }

        this.isSubmitting.set(true);

        const filledSignatures = restaurantAiSettings.signaturesForForm?.map(({ text }) => text).filter((signature) => !!signature);

        this._restaurantAiSettingsService
            .updateRestaurantAiSettings({
                ...restaurantAiSettings,
                signatures: filledSignatures,
                shouldTranslateSignature: restaurantAiSettings?.shouldTranslateSignature ?? false,
                shouldTranslateCatchphrase: restaurantAiSettings?.shouldTranslateCatchphrase ?? false,
                sourceLanguage: LocalStorage.getLang(),
            })
            .subscribe({
                next: (updatedRestaurantAiSettings) => {
                    this.confirmClose(updatedRestaurantAiSettings);
                    this._toastService.openSuccessToast(this._translateService.instant('restaurant_ai_settings.modals.upsert.success'));
                    this.isSubmitting.set(false);
                },
                error: () => {
                    this._toastService.openErrorToast(
                        this._translateService.instant('restaurant_ai_settings.modals.upsert.errors.upsert_error')
                    );
                    this.isSubmitting.set(false);
                },
            });
    }

    handleTabChange(index: number): void {
        this.selectedTabIndex.set(index);
    }

    onWithCommentChange(event: CommentOption): void {
        this.currentWithCommentOption.set(event.value);
        if (event.value === CommentOptionValue.WITHOUT) {
            const defaultLang = this.restaurantAiSettings()?.defaultLanguageResponse ?? APP_DEFAULT_LANGUAGE;
            this.previewReviewReplyForm.get('language')?.setValue(this._getLangOptionFromLang(defaultLang));
            this.currentLang.set(defaultLang);
        }
    }

    onLangOptionChange(event: LangOption): void {
        this.updateCurrentLang(event.value);
    }

    updateCurrentLang(value: ApplicationLanguage): void {
        this.currentLang.set(value);
    }

    private _getLangOptionFromLang(lang: ApplicationLanguage): LangOption {
        return {
            text: this._translateService.instant(`common.langs.${lang}`),
            value: lang,
        };
    }

    private _getNewSignature(): AiSettingsSignatureWithId {
        return { id: uuid(), text: '' };
    }
}
