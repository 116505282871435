import { isEqual } from 'lodash';

import { SocialPostItemDto } from '@malou-io/package-dto';
import { isNotNil, PlatformKey, PostPublicationStatus, PostType, RemoveMethodsFromClass } from '@malou-io/package-utils';

import { SocialPostMedia } from ':modules/posts-v2/social-posts/models/social-post-media';
import { Hashtag, PostHashtags } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

export type ISocialPostItem = RemoveMethodsFromClass<SocialPostItem> & { id: string };

export class SocialPostItem implements ISocialPostItem {
    id: string;
    text: string;
    platformKeys: PlatformKey[];
    published: PostPublicationStatus;
    postType: PostType;
    feedbackMessageCount: number;
    plannedPublicationDate: Date | null;
    thumbnail: SocialPostMedia | null;
    hashtags?: PostHashtags;
    error: PostError | null;
    socialLink?: string;
    socialCreatedAt?: Date;

    constructor(data: ISocialPostItem) {
        this.id = data.id;
        this.text = data.text;
        this.platformKeys = data.platformKeys;
        this.published = data.published;
        this.postType = data.postType;
        this.feedbackMessageCount = data.feedbackMessageCount;
        this.plannedPublicationDate = data.plannedPublicationDate;
        this.thumbnail = data.thumbnail;
        this.hashtags = data.hashtags;
        this.error = data.error;
        this.socialLink = data.socialLink;
        this.socialCreatedAt = data.socialCreatedAt;
    }

    static fromDto(dto: SocialPostItemDto): SocialPostItem {
        return new SocialPostItem({
            id: dto.id,
            text: dto.text,
            platformKeys: dto.platformKeys,
            published: dto.published,
            postType: dto.postType,
            feedbackMessageCount: dto.feedbackMessageCount,
            plannedPublicationDate: dto.plannedPublicationDate ? new Date(dto.plannedPublicationDate) : null,
            thumbnail: dto.thumbnail ? SocialPostMedia.fromDto(dto.thumbnail) : null,
            hashtags: dto.hashtags
                ? {
                      selected: dto.hashtags.selected.map((hashtag) => new Hashtag(hashtag)),
                      suggested: dto.hashtags.suggested.map((hashtag) => new Hashtag(hashtag)),
                  }
                : undefined,
            error: dto.error,
            socialLink: dto.socialLink,
            socialCreatedAt: dto.socialCreatedAt ? new Date(dto.socialCreatedAt) : undefined,
        });
    }

    getHashtagsText(): string {
        return this.hashtags ? this.hashtags.selected.map((hashtag) => hashtag.text).join(' ') : '';
    }

    getPostDate(): Date | undefined {
        return this.published === PostPublicationStatus.PUBLISHED && this.socialCreatedAt
            ? this.socialCreatedAt
            : (this.plannedPublicationDate ?? undefined);
    }

    getPostTypeIcon(): SvgIcon | undefined {
        switch (this.postType) {
            case PostType.IMAGE:
                return SvgIcon.IMAGE;
            case PostType.VIDEO:
            case PostType.REEL:
                return SvgIcon.VIDEO;
            case PostType.CAROUSEL:
                return SvgIcon.IMAGES;
            default:
                return SvgIcon.IMAGE;
        }
    }

    canOpenSocialLink = (): boolean => this.published === PostPublicationStatus.PUBLISHED && isNotNil(this.socialLink);

    canEdit = (): boolean =>
        this.published !== PostPublicationStatus.PUBLISHED ||
        (isEqual(this.platformKeys, [PlatformKey.FACEBOOK]) && this.postType !== PostType.REEL);

    canDelete = (): boolean => this.published !== PostPublicationStatus.PUBLISHED;

    canSchedule = (): boolean =>
        // TODO add PostPublicationStatus.PUBLISHING to the condition when the feature is implemented
        // and handle the error cases
        ![PostPublicationStatus.PUBLISHED].includes(this.published);
}

interface PostError {
    code: string;
    rawData: string;
}
