import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, output, Signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { combineLatest, debounceTime } from 'rxjs';

import { PostPublicationStatus, SocialPostsListFilter } from '@malou-io/package-utils';

import { FeedHeaderComponent } from ':modules/posts-v2/social-posts/components/feed-header/feed-header.component';
import { FeedComponent } from ':modules/posts-v2/social-posts/components/feed/feed.component';
import { SocialPostItemComponent } from ':modules/posts-v2/social-posts/components/social-posts-list/social-post-item/social-post-item.component';
import { SocialPostsListHeaderComponent } from ':modules/posts-v2/social-posts/components/social-posts-list/social-posts-list-header/social-posts-list-header.component';
import { FeedItem } from ':modules/posts-v2/social-posts/models/feed-item';
import { SocialPostItem } from ':modules/posts-v2/social-posts/models/social-post-item';
import { SocialPostsContext } from ':modules/posts-v2/social-posts/social-posts.context';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { Restaurant } from ':shared/models';

@Component({
    selector: 'app-social-posts-list-v2',
    templateUrl: './social-posts-list.component.html',
    styleUrls: ['./social-posts-list.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        FeedComponent,
        FeedHeaderComponent,
        SocialPostsListHeaderComponent,
        SocialPostItemComponent,
        InfiniteScrollDirective,
        SkeletonComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialPostsListV2Component implements OnInit {
    readonly createPost = output<void>();
    readonly createReelOrTikTok = output<void>();
    readonly updatePost = output<{ postId: string; shouldOpenFeedbacks?: boolean }>();
    readonly deletePost = output<{ postId: string }>();

    private readonly _socialPostsContext = inject(SocialPostsContext);
    private readonly _destroyRef = inject(DestroyRef);

    readonly posts: Signal<SocialPostItem[]> = this._socialPostsContext.posts;
    readonly isFetchingPosts: Signal<boolean> = this._socialPostsContext.isFetchingPosts;
    readonly isFetchingMorePosts: Signal<boolean> = this._socialPostsContext.isFetchingMorePosts;
    readonly selectedFilter: WritableSignal<SocialPostsListFilter> = this._socialPostsContext.selectedFilter;
    readonly selectedFilter$ = toObservable(this.selectedFilter);
    readonly fetchNextPage$ = this._socialPostsContext.fetchNextPage$;

    readonly feed: Signal<FeedItem[]> = this._socialPostsContext.feed;
    readonly isFetchingFeed: Signal<boolean> = this._socialPostsContext.isFetchingFeed;
    readonly isFetchingMoreFeed: Signal<boolean> = this._socialPostsContext.isFetchingMoreFeed;
    readonly fetchNextPageFeed$ = this._socialPostsContext.fetchFeedNextPage$;

    readonly restaurant$ = this._socialPostsContext.restaurant$;
    readonly restaurant = toSignal<Restaurant | null>(this.restaurant$, { initialValue: null });

    readonly FETCH_POSTS_LIMIT = 20;
    readonly FETCH_FEED_LIMIT = 30;

    ngOnInit(): void {
        this.selectedFilter$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
            this._socialPostsContext.resetPagination();
        });

        this.restaurant$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
            this._socialPostsContext.resetFilter();
            this._socialPostsContext.resetPagination();
            this._socialPostsContext.resetFeedPagination();
        });

        // debounceTime(0) is used to make sure the observable is triggered after the previous observables
        combineLatest([this.restaurant$, this.selectedFilter$, this.fetchNextPage$])
            .pipe(debounceTime(0), takeUntilDestroyed(this._destroyRef))
            .subscribe(([restaurant, selectedFilter]) => {
                this._socialPostsContext.fetchPosts(selectedFilter, restaurant.id, this.FETCH_POSTS_LIMIT);
            });

        combineLatest([this.restaurant$, this.fetchNextPageFeed$])
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(([restaurant]) => {
                this._socialPostsContext.fetchFeed(restaurant.id, this.FETCH_FEED_LIMIT);
            });
    }

    onScrollDown(): void {
        const hasNextPage = this._socialPostsContext.hasNextPage();
        if (hasNextPage) {
            this._socialPostsContext.goNextPage();
        }
    }

    onCreatePost(): void {
        this.createPost.emit();
    }

    onCreateReelOrTikTok(): void {
        this.createReelOrTikTok.emit();
    }

    onUpdatePost(data: { postId: string; shouldOpenFeedbacks?: boolean }): void {
        this.updatePost.emit(data);
    }

    onDeletePost(data: { postId: string }): void {
        this.deletePost.emit(data);
    }

    onFeedItemClicked(feedItem: FeedItem): void {
        if (feedItem?.published !== PostPublicationStatus.PUBLISHED) {
            this.updatePost.emit({ postId: feedItem.postId });
        }
    }
}
