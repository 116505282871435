import { UpsertSocialPost } from ':modules/posts-v2/social-posts/models/upsert-social-post';

export interface UpsertSocialPostModalProps {
    postId?: string;
    shouldOpenFeedbacks?: boolean;
}

export interface UpsertSocialPostModalResult {
    post: UpsertSocialPost | null;
}

// Special case, we whitelist the "Very French Beans" page because it does not contain location
const VERYFRENCHBEANS_PAGE_ID = '100270695184218';
export const WHITELISTED_PAGE_IDS = [VERYFRENCHBEANS_PAGE_ID];
