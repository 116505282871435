<app-select-base
    [formControl]="control"
    [title]="title()"
    [subtitle]="subtitle()"
    [placeholder]="placeholder()"
    [required]="required()"
    [disabled]="disabled()"
    [errorMessage]="errorMessage()"
    [values]="values()"
    [selectedValues]="selectedValues()"
    [displayWith]="displayWith()"
    [multiSelection]="multiSelection()"
    [checkboxOption]="multiSelection()"
    [groupSelectedValuesAtTop]="false"
    [showSelectAllCheckbox]="multiSelection()"
    [selectAllCheckboxMessage]="'common.all_languages' | translate"
    [testId]="testId()"
    (selectBaseChange)="selectLanguagesChange.emit($event)">
    @if (multiSelection()) {
        <ng-template let-value="value" #selectedValueTemplate>
            @if (selectedLangTemplate(); as selectedLangTemplate) {
                <ng-container [ngTemplateOutlet]="selectedLangTemplate" [ngTemplateOutletContext]="{ value }"></ng-container>
            } @else {
                <img class="h-6 w-6" [src]="value | flagPathResolver" [alt]="value" />
            }
        </ng-template>
    } @else {
        <ng-template let-value="value" #simpleSelectedValueTemplate>
            @if (simpleSelectedLangTemplate(); as simpleSelectedLangTemplate) {
                <ng-container [ngTemplateOutlet]="simpleSelectedLangTemplate" [ngTemplateOutletContext]="{ value }"></ng-container>
            } @else {
                <span class="flex items-center gap-x-2">
                    <img class="h-6 w-6" [lazyLoad]="value | flagPathResolver" [alt]="value" />
                    <span class="malou-text-14--regular">{{ displayWith() | applyPure: value }}</span>
                </span>
            }
        </ng-template>
    }
    <ng-template let-value="value" #optionTemplate>
        @if (langOptionTemplate(); as langOptionTemplate) {
            <ng-container [ngTemplateOutlet]="langOptionTemplate" [ngTemplateOutletContext]="{ value }"></ng-container>
        } @else {
            <span class="flex items-center gap-x-2">
                <img class="h-4 w-4" [lazyLoad]="value | flagPathResolver" [alt]="value" />
                <span class="malou-text-13--regular">{{ displayWith() | applyPure: value }}</span>
            </span>
        }
    </ng-template>
</app-select-base>
