import { NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    DestroyRef,
    forwardRef,
    inject,
    Injector,
    input,
    model,
    OnInit,
    output,
    signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, map, skip, startWith, tap } from 'rxjs/operators';

import { ControlValueAccessorConnectorComponent } from ':shared/components/control-value-accessor-connector/control-value-accessor-connector';
import { EmojiPickerComponent } from ':shared/components/emoji-picker/emoji-picker.component';
import { InputTextTheme } from ':shared/components/input-text/input-text.interface';
import { createTextWithEmoji, CursorPosition, focusAfterEmoji } from ':shared/helpers/text-area-emoji.helpers';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-input-text',
    templateUrl: 'input-text.component.html',
    styleUrls: ['./input-text.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => InputTextComponent),
        },
    ],
    standalone: true,
    imports: [NgClass, FormsModule, ReactiveFormsModule, MatIconModule, MatRippleModule, TranslateModule, EmojiPickerComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputTextComponent extends ControlValueAccessorConnectorComponent implements OnInit {
    /**
     * Title
     */
    readonly title = input<string>('');

    /**
     * Placeholder
     */
    readonly placeholder = input<string>('');

    /**
     * default value
     */
    readonly defaultValue = input<string | undefined>();

    /**
     * Required option, will add an asterix after the title
     */
    readonly required = input<boolean>(false);

    /**
     * Error message, will add a colored border and will display the error below the input-text
     */
    readonly errorMessage = input<string | undefined>();

    /**
     * Will hide value
     */
    readonly password = model<boolean>(false);

    /**
     * Will show an icon to switch 'password' input-text between true and false
     */
    readonly showEyeIcon = input<boolean>(false);

    /**
     * Capitalization for virtual keyboard (mobile)
     */
    readonly autocapitalize = input<'on' | 'off' | 'words' | 'characters' | 'none'>('on');

    readonly showClearIcon = input<boolean>(false);

    readonly showSvgIconWhenUserIsTyping = input<boolean>(true);

    /**
     * Svg icon
     */
    readonly svgIcon = input<string | undefined>();

    /**
     * Svg img
     */
    readonly svgImg = input<string | undefined>();

    /**
     * Subtitle
     */
    readonly subtitle = input<string | undefined>();

    readonly debounceTime = input<number>(0);

    readonly inputType = model<'text' | 'password' | 'email' | 'number' | 'time' | 'url'>('text');

    readonly min = input<number | null>(null);

    readonly max = input<number | null>(null);

    readonly maxLength = input<number | null>(null);

    readonly showMaxLength = input<boolean>(false);

    readonly showConfirmIcon = input<boolean>(false);

    readonly showRequiredStar = input<boolean>(false);

    readonly hideRequiredStar = input<boolean>(false);

    readonly confirmationIconId = input<string | undefined>();

    readonly customActionBtn = input<string | undefined>();

    readonly showMaxLengthHelper = input<boolean>(false);

    readonly autocomplete = input<string | undefined>();

    readonly theme = input<InputTextTheme>(InputTextTheme.FILL);

    readonly readOnly = input<boolean>(false);

    /**
     * Is emoji picker enabled
     */
    readonly isEmojiPickerEnabled = input<boolean>(false);

    /**
     * Emoji button color
     * Default is malou-color-primary
     */
    readonly emojiButtonColor = input<string>('malou-color-primary');

    /**
     * Text area id
     */
    readonly inputId = input<string>('input');

    /**
     * Disable the input
     */
    readonly disabled = input<boolean>(false);
    /**
     * Disable the input
     */
    readonly testId = input<string>();

    /**
     * On value change output
     */
    readonly inputTextChange = output<string>();
    readonly confirmControlValue = output<string>();
    readonly customAction = output<void>();

    readonly SvgIcon = SvgIcon;
    readonly InputTextTheme = InputTextTheme;

    readonly isFocused = signal(false);
    readonly isEmptyValue = signal(true);
    readonly shouldShowSvgIcon = computed(() => !!this.svgIcon() && (this.showSvgIconWhenUserIsTyping() || !this.isEmptyValue()));

    private _isPassword = false;

    private readonly _destroyRef = inject(DestroyRef);

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        this.inputType.update((inputType) => (this.password() ? 'password' : inputType));
        this._isPassword = this.password();

        if (!this.control) {
            return;
        }

        if (this.required() && !this.control.hasValidator(Validators.required)) {
            this.control.addValidators(Validators.required);
        }

        const defaultValue = this.defaultValue();
        if (defaultValue) {
            this.control.setValue(defaultValue);
        }
        this.control.valueChanges
            .pipe(
                debounceTime(this.debounceTime()),
                startWith(this.control.value),
                map((value) => (typeof value === 'string' ? value?.trim() : value)),
                tap((value) => this.setEmptyValue(value)),
                skip(1),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe((value) => this.inputTextChange.emit(value));
    }

    setEmptyValue(value: string): void {
        this.isEmptyValue.set(!value);
    }

    clearControl(): void {
        this.control?.setValue('');
    }

    toggleEyeIcon(): void {
        if (!this._isPassword) {
            return;
        }
        this.password.update((password) => !password);
        this.inputType.set(this.password() ? 'password' : 'text');
    }

    emitControlValue(): void {
        this.confirmControlValue.emit(this.control.value);
    }

    emitCustomAction(): void {
        this.customAction.emit();
    }

    emojiSelected(event: EmojiEvent): void {
        const MAX_LENGTH = 1000;
        if (this.control?.value?.length >= (this.maxLength() ?? MAX_LENGTH) - 1 || this.control.disabled) {
            return;
        }
        const inputElement = this._getInput(this.inputId());
        const currentValue = inputElement.value;
        const cursorPosition: CursorPosition = {
            startPosition: inputElement.selectionStart ?? 0,
            endPosition: inputElement.selectionEnd ?? 0,
        };
        const textWithEmoji = createTextWithEmoji(cursorPosition, currentValue, event);
        inputElement.value = textWithEmoji;
        focusAfterEmoji(inputElement, cursorPosition, event);
        this.setEmptyValue(textWithEmoji);
        this.control.setValue(textWithEmoji);
    }

    private _getInput(inputId: string): HTMLInputElement {
        return document.getElementById(inputId) as HTMLInputElement;
    }
}
