<div class="flex h-full flex-col gap-2">
    @if (loading()) {
        <div class="flex items-center justify-center">
            <mat-spinner diameter="20"></mat-spinner>
        </div>
    }
    <ng-container *ngTemplateOutlet="list"></ng-container>
    <ng-container *ngTemplateOutlet="chatBar"></ng-container>
</div>

<ng-template #list>
    <div class="grow overflow-y-auto" [id]="FEEDBACK_MESSAGE_LIST_CONTAINER_ID" [ngClass]="{ 'opacity-70': feedbacksAreClosed() }">
        @if (feedbackMessages().length > 0) {
            @for (message of feedbackMessages(); track message.id) {
                @let messageIsFromCurrentUser = isFromCurrentUser | applyPure: message;
                <div class="feedback-message py-5">
                    <div class="relative flex items-center justify-between">
                        <div class="flex items-center">
                            <img
                                class="h-8.5 w-8.5 rounded-full"
                                [lazyLoad]="message.author.profilePictureUrl ?? (Illustration.Karl | illustrationPathResolver)" />
                            <div class="ml-2">
                                <div class="malou-color-text-1 flex items-center">
                                    <p class="malou-text-12--semibold">{{ getFullname | applyPure: message.author }}</p>
                                    @if (messageIsFromCurrentUser) {
                                        <p class="malou-text-10--regular">&nbsp;{{ 'feedbacks.you' | translate }}</p>
                                    }
                                </div>
                                <p class="malou-text-10--regular malou-color-text-2 italic">{{ getFormattedDate | applyPure: message }}</p>
                            </div>
                        </div>
                        <div class="absolute right-0 hidden">
                            @if (!feedbacksAreClosed() && messageIsFromCurrentUser) {
                                <div class="flex">
                                    @if (!editingMessageId()) {
                                        <mat-icon
                                            class="malou-color-primary h-2 w-2 cursor-pointer p-1"
                                            [svgIcon]="SvgIcon.TRASH"
                                            (click)="deleteMessage(message)"></mat-icon>

                                        <mat-icon
                                            class="malou-color-primary h-2 w-2 cursor-pointer p-1"
                                            [svgIcon]="SvgIcon.EDIT"
                                            (click)="startEditingMessage(message)"></mat-icon>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <div>
                        @switch (message.type) {
                            @case (FeedbackMessageType.TEXT) {
                                <div>
                                    @if (editingMessageId() !== message.id) {
                                        <div class="feedback-text">
                                            <p class="malou-color-text-2 py-2" [innerHtml]="getMessageTextHtml | applyPure: message"></p>
                                            @if (message.visibility === FeedbackMessageVisibility.ADMIN) {
                                                <mat-checkbox color="primary" [disabled]="true" [checked]="true">
                                                    <span class="malou-text-10--regular">{{
                                                        'feedbacks.admin_visibility' | translate
                                                    }}</span>
                                                </mat-checkbox>
                                            }
                                        </div>
                                    } @else {
                                        <mat-checkbox
                                            class="!mb-2 !mt-4"
                                            color="primary"
                                            appShowIfAdmin
                                            [checked]="message.visibility === FeedbackMessageVisibility.ADMIN"
                                            (change)="changeMessageVisibility(message)">
                                            <span class="malou-text-10--regular">{{ 'feedbacks.admin_visibility' | translate }}</span>
                                        </mat-checkbox>
                                        <div class="flex w-full items-end gap-1">
                                            <div class="grow">
                                                <form [formGroup]="messagesForm">
                                                    <app-text-area
                                                        formControlName="editingMessage"
                                                        [textAreaId]="'feedback-edit-input'"
                                                        [rows]="1"
                                                        [mentionConfiguration]="mentionConfig()"></app-text-area>
                                                </form>
                                            </div>
                                        </div>
                                        <div class="flex flex-row justify-end">
                                            <div class="flex flex-row space-x-4">
                                                @if (editingMessageId() === message.id) {
                                                    <button
                                                        class="malou-btn-flat btn-sm malou-color-text-2"
                                                        (click)="cancelEditingMessage()">
                                                        {{ 'common.cancel' | translate }}
                                                    </button>
                                                }
                                                <button
                                                    class="malou-btn-flat btn-sm"
                                                    [disabled]="!editingMessage.length"
                                                    (click)="updateMessage(message)">
                                                    {{ 'common.save' | translate }}
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            @case (FeedbackMessageType.REOPEN) {
                                <div class="feedback-text">
                                    <p class="malou-color-dark-lighter">
                                        <strong>{{ 'feedbacks.opened_feedback' | translate }}</strong>
                                    </p>
                                </div>
                            }
                            @case (FeedbackMessageType.CLOSE) {
                                <div class="feedback-text">
                                    <p class="malou-color-dark-lighter">
                                        <strong>{{ 'feedbacks.closed_feedback' | translate }}</strong>
                                    </p>
                                </div>
                            }
                        }
                    </div>
                </div>
            }
        } @else {
            <div class="flex h-full flex-col items-center justify-center p-4">
                <img class="h-20 w-20" alt="Plate illustration" [src]="'Plate' | illustrationPathResolver" />
                <p class="malou-text-10--regular mt-4 text-center text-malou-color-text-1">{{ 'feedbacks.no_feedback_yet' | translate }}</p>
            </div>
        }
    </div>
</ng-template>

<ng-template #chatBar>
    @if (!editingMessageId() && !feedbacksAreClosed()) {
        <div class="border-t border-malou-color-background-dark pt-3">
            <p class="malou-text-14--bold malou-color-text-1">
                {{ 'feedbacks.write_feedback' | translate }}
            </p>
            <mat-checkbox color="primary" appShowIfAdmin [(ngModel)]="adminVisibility">
                <span class="malou-text-10--regular">{{ 'feedbacks.admin_visibility' | translate }}</span>
            </mat-checkbox>
            <form [formGroup]="messagesForm">
                <div class="flex items-center gap-1">
                    <div class="min-w-0 grow">
                        <app-text-area
                            formControlName="newMessage"
                            [textAreaId]="'feedback-input'"
                            [rows]="1"
                            [mentionConfiguration]="mentionConfig()"></app-text-area>
                    </div>
                    <button
                        class="malou-btn-icon--primary btn-xl"
                        mat-icon-button
                        [id]="trackingId()"
                        [disabled]="!newMessage.length"
                        (click)="createMessage()">
                        <mat-icon [svgIcon]="SvgIcon.PAPER_PLANE"></mat-icon>
                    </button>
                </div>
            </form>
        </div>
    }
    @if (feedbacksAreClosed() || feedbackMessages().length > 0) {
        <div class="flex items-center">
            @if (feedbacksAreClosed()) {
                <p class="malou-text-11--regular mr-2">{{ 'feedbacks.is_closed' | translate }}</p>
            }
            @if (feedbackMessages().length > 0) {
                <button class="malou-btn-flat !-ml-4 !px-4" mat-button (click)="toggleFeedbackStatus()">
                    {{ !feedbacksAreClosed() ? ('feedbacks.close' | translate) : ('feedbacks.open' | translate) }}
                </button>
            }
        </div>
    }
</ng-template>
