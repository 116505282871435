<app-modal-structure
    [title]="'edit_media_modal.title' | translate"
    [primaryButtonText]="'common.save' | translate"
    [secondaryButtonText]="'common.cancel' | translate"
    [buttonStyle]="ButtonStyle.CLASSIC"
    (close)="onClose()"
    (primaryClick)="onPrimaryClick()"
    (secondaryClick)="onSecondaryClick()">
    @let selectedMediaValue = selectedMedia();
    <div class="flex h-full flex-col gap-y-4">
        @if (!selectedMediaValue) {
            <ng-container [ngTemplateOutlet]="noSelectedMediaTemplate"></ng-container>
        } @else {
            <app-image-transform-buttons
                [initialAspectRatio]="selectedMediaAspectRatio()!"
                (aspectRatioChange)="onAspectRatioChange($event)"
                (rotationDirectionChange)="onRotationDirectionChange($event)"></app-image-transform-buttons>

            <div class="relative min-h-0 grow">
                <app-image-editor
                    [url]="selectedMediaValue.thumbnail1024OutsideUrl"
                    [transformData]="selectedMediaValue.transformData"
                    (transformAreaChange)="onTransformAreaChange($event)" />
            </div>
        }

        <div>
            <app-upload-and-edit-medias
                [(medias)]="medias"
                [uploadingMediaCount]="uploadingMediaCount()"
                [showEditMediaButton]="false"
                (mediaClicked)="onMediaClicked($event)"
                (fileAdded)="onFileAdded($event)" />
        </div>
    </div>
</app-modal-structure>

<ng-template #noSelectedMediaTemplate>
    <div class="flex min-h-0 grow items-center justify-center">
        <div class="malou-text-12--regular malou-color-text-2">
            {{ 'edit_media_modal.no_media' | translate }}
        </div>
    </div>
</ng-template>
