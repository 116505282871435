import { NfcSnapshotDto, ReviewResponseDto, ScanDto, ScanForAggregatedInsightsDto } from '@malou-io/package-dto';
import {
    NfcsPlatformKey,
    NfcStar,
    NfcType,
    WHEEL_OF_FORTUNE_PLATFORM_KEY,
    WheelOfFortuneRedirectionPlatformKey,
} from '@malou-io/package-utils';

import { FAKE_NFC_ID_FOR_WHEEL_OF_FORTUNE_SCANS, NfcSnapshot } from './nfc';

export class ScanForStats {
    id: string;
    nfcId: string;
    scannedAt: Date;
    nfcSnapshot?: NfcSnapshotDto & { statsPlatformKey?: NfcsPlatformKey };
    redirectedAt?: Date;
    starClicked?: NfcStar;
    isCheckedForMatchingReview: boolean;
    matchedReviewId?: string;
    matchedReview?: ReviewResponseDto;

    constructor(data: ScanDto) {
        this.id = data.id;
        this.nfcId = data.nfcId;
        this.scannedAt = new Date(data.scannedAt);
        this.redirectedAt = data.redirectedAt ? new Date(data.redirectedAt) : undefined;
        this.starClicked = data.starClicked;
        this.matchedReviewId = data.matchedReviewId;
        this.matchedReview = data.matchedReview ? data.matchedReview : undefined;
        this.nfcSnapshot = data.nfcSnapshot ? this.buildSnapshot(data.nfcSnapshot) : undefined;
    }

    buildSnapshot(snapshot: NfcSnapshotDto): NfcSnapshotDto & { statsPlatformKey?: NfcsPlatformKey } {
        return {
            ...snapshot,
            statsPlatformKey:
                NfcSnapshot.fromNfcSnapshotDto(snapshot).isRedirectingToWheelOfFortune() ||
                snapshot.platformKey === WheelOfFortuneRedirectionPlatformKey.NO_REDIRECTION
                    ? WHEEL_OF_FORTUNE_PLATFORM_KEY
                    : (snapshot.platformKey ?? undefined),
        };
    }

    isWheelOfFortuneRelated(): boolean {
        return (
            this.nfcId === FAKE_NFC_ID_FOR_WHEEL_OF_FORTUNE_SCANS || this.nfcSnapshot?.statsPlatformKey === WHEEL_OF_FORTUNE_PLATFORM_KEY
        );
    }

    nfcIsTotem(): boolean {
        return this.nfcSnapshot?.type === NfcType.TOTEM;
    }
}

// -----------------------------------------------------------------------------------------

export class ScanForAggregatedInsights {
    id: string;
    nfcId: string;
    nfcSnapshot: ScanForAggregatedInsightsDto['scans'][0]['nfcSnapshot'] & { statsPlatformKey?: NfcsPlatformKey };

    constructor(data: ScanForAggregatedInsightsDto['scans'][0]) {
        this.id = data.id;
        this.nfcId = data.nfcId;
        this.nfcSnapshot = this.buildSnapshot(data.nfcSnapshot);
    }

    buildSnapshot(
        snapshot: ScanForAggregatedInsightsDto['scans'][0]['nfcSnapshot']
    ): ScanForAggregatedInsightsDto['scans'][0]['nfcSnapshot'] & { statsPlatformKey?: NfcsPlatformKey } {
        return {
            ...snapshot,
            statsPlatformKey:
                snapshot.isRedirectingToWof || snapshot.platformKey === WheelOfFortuneRedirectionPlatformKey.NO_REDIRECTION
                    ? WHEEL_OF_FORTUNE_PLATFORM_KEY
                    : (snapshot.platformKey ?? undefined),
        };
    }

    isWheelOfFortuneRelated(): boolean {
        return (
            this.nfcId === FAKE_NFC_ID_FOR_WHEEL_OF_FORTUNE_SCANS || this.nfcSnapshot?.statsPlatformKey === WHEEL_OF_FORTUNE_PLATFORM_KEY
        );
    }
}
