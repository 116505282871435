import { NgTemplateOutlet, SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { omit } from 'lodash';
import { forkJoin, map, Observable, of, Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { isNotNil } from '@malou-io/package-utils';

import { DialogService } from ':core/services/dialog.service';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { OrganizationsService } from ':core/services/organizations.service';
import { ToastService } from ':core/services/toast.service';
import { AdminService } from ':modules/admin/admin.service';
import { RestaurantsListModalComponent } from ':modules/admin/restaurants-list-modal/restaurants-list-modal.component';
import { OrganizationsListModalComponent } from ':modules/admin/users/organizations-list-modal/organizations-list-modal.component';
import { ChangePasswordComponent } from ':modules/admin/users/reset-password/change-password.component';
import { UpsertUserModalComponent, UserUpsertForm } from ':modules/admin/users/upsert-user-modal/upsert-user-modal.component';
import * as UserActions from ':modules/user/store/user.actions';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { User, UserRestaurant } from ':modules/user/user';
import { UsersService } from ':modules/user/users.service';
import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { PaginatorComponent } from ':shared/components/paginator/paginator.component';
import { SearchComponent } from ':shared/components/search/search.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { UpsertKind } from ':shared/enums/upsert-kind.enum';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { Organization } from ':shared/models/organization';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

interface UserRestaurantWithRestaurantDetails extends UserRestaurant {
    restaurantDetails: {
        name: string;
    };
}
interface UserPopulated extends User {
    restaurants: UserRestaurantWithRestaurantDetails[];
    organizations: Organization[];
}

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        PaginatorComponent,
        SearchComponent,
        SlideToggleComponent,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
        FormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatSortModule,
        MatTableModule,
        ReactiveFormsModule,
        RouterLink,
        TranslateModule,
        SlicePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    readonly UpsertKind = UpsertKind;

    readonly DISPLAYED_COLUMNS: string[] = ['name', 'email', 'role', 'organizations', 'restaurants', 'caslRole', 'active', 'id', 'actions'];
    readonly DEFAULT_SORT: Sort = {
        active: 'createdAt',
        direction: 'desc',
    };
    readonly killSubscriptions$: Subject<void> = new Subject<void>();
    readonly MAX_ITEMS_PER_COLUMN = 5;

    dataSource: MatTableDataSource<UserPopulated> = new MatTableDataSource<UserPopulated>([]);
    refresh$: Subject<void> = new Subject<void>();

    readonly trackByIdFn = TrackByFunctionFactory.get('_id');

    constructor(
        private readonly _usersService: UsersService,
        private readonly _adminService: AdminService,
        private readonly _spinnerService: SpinnerService,
        private readonly _translate: TranslateService,
        private readonly _organizationsService: OrganizationsService,
        private readonly _toastService: ToastService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _dialogService: DialogService,
        private readonly _customDialogService: CustomDialogService,
        private readonly _store: Store
    ) {}

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        this.dataSource.sort = sort;
    }

    @ViewChild(PaginatorComponent) set paginator(paginatorComponent: PaginatorComponent) {
        if (paginatorComponent) {
            this.dataSource.paginator = paginatorComponent.matPaginator;
        }
    }

    ngOnInit(): void {
        this._organizationsService
            .index()
            .pipe(map((res) => res.data))
            .subscribe((allOrganizations) => {
                this.refresh$
                    .pipe(
                        tap(() => this._spinnerService.show()),
                        switchMap(() => this._usersService.index()),
                        takeUntil(this.killSubscriptions$)
                    )
                    .subscribe((users: User[]) => {
                        this.dataSource.data = users.map((user) =>
                            Object.assign(user, {
                                organizations: allOrganizations.filter((organization) => user.organizationIds?.includes(organization._id)),
                            })
                        );
                        this._spinnerService.hide();
                    });

                this.refresh$.next();
            });
    }

    onSearchChange(searchValue: string): void {
        this.dataSource.filter = searchValue.trim().toLowerCase();
    }

    displayOrganizationListModal(organizations: Organization[]): void {
        this._customDialogService.open(OrganizationsListModalComponent, {
            disableClose: false,
            data: {
                organizations,
            },
        });
    }

    displayRestaurantsListModal(user: UserPopulated): void {
        this._customDialogService.open(RestaurantsListModalComponent, {
            disableClose: false,
            data: {
                restaurants: user.restaurants.map(({ restaurantId, restaurantDetails }) => ({
                    _id: restaurantId,
                    name: restaurantDetails.name,
                })),
            },
        });
    }

    updateActive(userId: string, active: boolean): void {
        this._spinnerService.show();
        this._adminService.updateAccount(userId, { verified: active }).subscribe({
            next: () => {
                this.refresh$.next();
            },
            error: (err) => {
                console.error('err :>> ', err);
                this._spinnerService.hide();
                this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
            },
        });
    }

    changePassword(userId: string): void {
        this._customDialogService
            .open(ChangePasswordComponent, {
                height: 'unset',
            })
            .afterClosed()
            .subscribe((newPassword: string | undefined) => {
                if (newPassword) {
                    this._spinnerService.show();
                    this._adminService.updateAccount(userId, { password: newPassword }).subscribe({
                        next: () => {
                            this.refresh$.next();
                        },
                        error: (err) => {
                            console.error('err :>> ', err);
                            this._spinnerService.hide();
                            this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                        },
                    });
                }
            });
    }

    delete(userId: string): void {
        this._dialogService.open({
            title: this._translate.instant('common.are_you_sure'),
            variant: DialogVariant.ALERT,
            primaryButton: {
                label: this._translate.instant('common.yes'),
                action: () => {
                    this._spinnerService.show();
                    this._adminService.deleteAccount(userId).subscribe({
                        next: () => {
                            this.refresh$.next();
                        },
                        error: (err) => {
                            console.error('err :>> ', err);
                            this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                            this._spinnerService.hide();
                        },
                    });
                },
            },
            secondaryButton: {
                label: this._translate.instant('common.no'),
            },
        });
    }

    upsert(upsertKind: UpsertKind, data?: UserPopulated): void {
        this._customDialogService
            .open(UpsertUserModalComponent, {
                data: {
                    upsertKind,
                    user: data,
                    organizations: data?.organizations ?? [],
                },
            })
            .afterClosed()
            .subscribe((result: UserUpsertForm) => {
                if (!result) {
                    return;
                }
                this._spinnerService.show();

                const partialUser: Partial<User> = {
                    ...omit(result, ['organizations']),
                    organizationIds: result.organizations.map((organization) => organization._id),
                };

                const upsert$ =
                    upsertKind === UpsertKind.INSERT ? this._getCreateUser$(partialUser) : this._getUpdateUser$(data?._id, partialUser);
                upsert$.subscribe({
                    complete: () => {
                        this.refresh$.next();
                    },
                    error: (err) => {
                        console.error('err :>> ', err);
                        this._spinnerService.hide();
                        this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                    },
                });
            });
    }

    private _getCreateUser$(partial: Partial<User>): Observable<User> {
        return this._adminService.createAccount(partial);
    }

    private _getUpdateUser$(userId: string | undefined, partial: Partial<User>): Observable<User | null> {
        if (!userId) {
            return of(null);
        }
        return forkJoin([
            this._adminService.updateAccount(userId, partial),
            this._store.select(selectUserInfos).pipe(take(1), filter(isNotNil)),
        ]).pipe(
            switchMap(([_, currentUser]) => {
                if (currentUser._id === userId) {
                    return this._usersService.getUser(userId);
                }
                return of(null);
            }),
            tap((user) => {
                if (user) {
                    this._store.dispatch(UserActions.editUserInfos({ infos: user }));
                }
            })
        );
    }
}
