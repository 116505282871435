import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, switchMap } from 'rxjs';

import { isNotNil, PlatformKey } from '@malou-io/package-utils';

import { PlatformsService } from ':core/services/platforms.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';

@Component({
    selector: 'app-feed-header',
    templateUrl: './feed-header.component.html',
    styleUrls: ['./feed-header.component.scss'],
    standalone: true,
    imports: [PlatformLogoComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedHeaderComponent {
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _platformsService = inject(PlatformsService);

    private readonly _DEFAULT_IG_USERNAME = '--';
    readonly igUsername = signal<string>(this._DEFAULT_IG_USERNAME);

    readonly PlatformKey = PlatformKey;

    constructor() {
        this._restaurantsService.restaurantSelected$
            .pipe(
                filter(isNotNil),
                switchMap((restaurant) => this._platformsService.getPlatformSocialLink(restaurant._id, PlatformKey.INSTAGRAM)),
                takeUntilDestroyed()
            )
            .subscribe((res) => {
                this.igUsername.set(res.data?.socialLink?.match(/^https:\/\/www.instagram.com\/(.*)/)?.[1] ?? this._DEFAULT_IG_USERNAME);
            });
    }
}
