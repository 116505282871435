import { KeywordStatsV3Dto, ReviewResponseDto } from '@malou-io/package-dto';
import { PlatformKey } from '@malou-io/package-utils';

import { ReviewsEvolutionWithRange, ReviewsRating } from ':modules/reviews/reviews.interface';
import {
    DatesAndPeriod,
    InsightsByPlatform,
    Keyword,
    MalouTimeScalePeriod,
    PostsWithInsightsByPlatforms,
    Review,
    ReviewWithAnalysis,
    TimeScaleToMetricToDataValues,
} from ':shared/models';

import { BoostersStatisticsData } from '../boosters/boosters.component';
import { StoriesAndInsights } from '../social-networks/posts-insights-table/stories/stories.component';

export interface StatisticsState {
    filters: {
        dates: DatesAndPeriod;
        platforms: {
            [PlatformFilterPage.E_REPUTATION]: PlatformKey[];
            [PlatformFilterPage.SOCIAL_NETWORKS]: PlatformKey[];
        };
        totemIds: string[];
        timeScale: MalouTimeScalePeriod | undefined;
    };
    data: {
        keywords: {
            keywords: Keyword[];
            stats: KeywordStatsV3Dto[];
        };
        actions: TimeScaleToMetricToDataValues | undefined;
        reviews: Review[];
        postsWithInsights: PostsWithInsightsByPlatforms;
        followers: InsightsByPlatform;
        storiesAndInsights: StoriesAndInsights;
        reviewsWithAnalysis: ReviewWithAnalysis[];
        platformsRatings: InsightsByPlatform | undefined;
        reviewsRatingsEvolution: ReviewsEvolutionWithRange | undefined;
        reviewsRatingsTotal: ReviewsRating[];
        boosterStatsData: BoostersStatisticsData | undefined;
        privateReviewsData: ReviewResponseDto[];
    };
    loaded: boolean;
}

export enum PlatformFilterPage {
    E_REPUTATION = 'E_REPUTATION',
    SOCIAL_NETWORKS = 'SOCIAL_NETWORKS',
    SEO = 'SEO',
}
