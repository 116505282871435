import { APP_DEFAULT_LANGUAGE, ApplicationLanguage } from '@malou-io/package-utils';

import { LocalStorageKey } from ':shared/enums/local-storage-key';

export namespace LocalStorage {
    let isLocalStorageSupported: boolean | null = null;

    export function isSupported(): boolean {
        if (isLocalStorageSupported === null) {
            isLocalStorageSupported = checkLocalStorageSupport();
        }
        return isLocalStorageSupported;
    }

    export function getItem(key: LocalStorageKey): string | null {
        if (!isSupported()) {
            console.log('Local storage is not supported', key);
            return null;
        }
        try {
            return localStorage.getItem(key);
        } catch (error) {
            console.error('Error while trying to access local storage', { key, error });
            throw error;
        }
    }

    export function setItem(key: LocalStorageKey, value: string): void {
        if (!isSupported()) {
            console.log('Local storage is not supported', key);
            return;
        }
        try {
            localStorage.setItem(key, value);
        } catch (error) {
            console.error('Error while trying to access local storage', { key, error });
            throw error;
        }
    }

    export function removeItem(key: LocalStorageKey): void {
        if (!isSupported()) {
            console.log('Local storage is not supported', key);
            return;
        }
        try {
            localStorage.removeItem(key);
        } catch (error) {
            console.error('Error while trying to access local storage', { key, error });
            throw error;
        }
    }

    export function setLang(lang: ApplicationLanguage): void {
        if (!isSupported()) {
            return;
        }
        localStorage.setItem(LocalStorageKey.LANG, lang);
    }

    export function getLang(defaultLang = APP_DEFAULT_LANGUAGE): ApplicationLanguage {
        if (!isSupported()) {
            return defaultLang;
        }

        const lang = localStorage.getItem(LocalStorageKey.LANG);
        return lang && isLangSupported(lang) ? lang : defaultLang;
    }

    function checkLocalStorageSupport(): boolean {
        // We may have an error when trying to access local storage on some Firefox versions : https://stackoverflow.com/q/23269532/19313155
        // or if the user has blocked cookies in Safari : https://stackoverflow.com/q/46632093/19313155

        // So we need to check if it is supported before using it
        // Solution found from this post https://stackoverflow.com/a/39584377/19313155
        try {
            localStorage.setItem('testLocalStorage', 'testLocalStorage');
            localStorage.removeItem('testLocalStorage');
            return true;
        } catch (e) {
            console.error('Local storage is not supported');
            return false;
        }
    }

    function isLangSupported(lang: string): lang is ApplicationLanguage {
        return Object.values(ApplicationLanguage).includes(lang as ApplicationLanguage);
    }
}
