import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { errorReplacer, MalouErrorCode } from '@malou-io/package-utils';

interface MalouError {
    malouErrorCode: MalouErrorCode;
    message: string;
}

@Pipe({
    name: 'httpError',
    standalone: true,
})
export class HttpErrorPipe implements PipeTransform {
    public constructor(private readonly _translate: TranslateService) {}

    transform(error: any): string {
        if (error?.error?.message?.match(/No access to this page/)) {
            return this._translate.instant('errors.social.no_access_to_this_page');
        }
        if (error?.error?.message?.match(/downgrade the last owner/)) {
            return this._translate.instant('errors.roles.cannot_downgrade_last_owner');
        }
        if (error?.error?.message?.match(/Start date must occur before end/)) {
            return this._translate.instant('errors.social.start_date_before_end_date');
        }
        if (error?.error?.message?.match(/platform_required/) || error?.error?.malouErrorCode === MalouErrorCode.QUERY_VALIDATION_ERROR) {
            return this._translate.instant('errors.social.platform_required');
        }
        if (error?.error?.message?.match(/Time range too short/)) {
            return this._translate.instant('errors.social.time_range_too_short');
        }
        if (error?.error?.message?.match(/Time range too long/)) {
            return this._translate.instant('errors.social.time_range_too_long');
        }
        if (error?.error?.message?.match(/Application request limit reached/)) {
            return this._translate.instant('errors.social.request_limit_reached');
        }
        if (error?.error?.message?.match(/ETIMEDOUT/)) {
            return this._translate.instant('errors.social.timed_out');
        }
        if (String(error?.error?.message).match(/gmb_restaurant_already_exists/)) {
            return this._translate.instant('platforms.connection.choose_restaurant.restaurant_already_exists');
        }
        if (error?.error?.message === 'spotted_forbidden') {
            return this._translate.instant('platforms.connection.choose_restaurant.too_many_retries');
        }
        if (error?.error?.message === 'BUSINESS_UNAVAILABLE') {
            return this._translate.instant('platforms.connection.choose_restaurant.business_unavailable');
        }
        if (typeof error === 'string' && error?.match(/could_not_match/)) {
            return this._translate.instant('platforms.connection.choose_restaurant.could_not_match');
        }
        if (typeof error === 'string' && error?.match(/\(#200\) Permissions error/)) {
            return this._translate.instant('errors.social.no_platform_credential', { platformKey: '' });
        }
        if (error?.error?.message?.match(/cannot be loaded due to missing permission/)) {
            return this._translate.instant('errors.social.no_platform_credential', { platformKey: error?.error?.platformKeyError ?? '' });
        }
        if (error?.error?.message?.match(/cannot be loaded due to missing permission/)) {
            return this._translate.instant('errors.social.no_platform_credential', { platformKey: error?.error?.platformKeyError ?? '' });
        }
        if (error?.error?.message?.match(/Or check if page token has enough IG permissions granular scopes for IG send api./)) {
            return this._translate.instant('errors.social.account_related_ig_messaging_error');
        }
        if (error?.error?.message?.match(/Or check if page token has enough IG permissions granular scopes for IG send api./)) {
            return this._translate.instant('errors.social.account_related_ig_messaging_error');
        }
        if (error?.error?.message?.match(/Duplicate entry/)) {
            return this._translate.instant('errors.profile.duplicate_entry');
        }
        if (this._isMalouError(error?.error)) {
            if (error.error.malouErrorCode === MalouErrorCode.CREDENTIALS_FACEBOOK_ERROR) {
                return this._translate.instant('errors.social.no_platform_credential', { platformKey: '' });
            }
            if (error.error.malouErrorCode === MalouErrorCode.PLATFORM_CREDENTIAL_NOT_FOUND) {
                return this._translate.instant('errors.social.no_platform_credential', { platformKey: error?.error?.message ?? '' });
            }
            if (error.error.malouErrorCode === MalouErrorCode.USER_HAS_CREDENTIALS) {
                return this._translate.instant('errors.users.user_has_credentials');
            }
        }
        return this._translate.instant('errors.unknown', {
            rawError: error?.error?.message ?? error?.message ?? JSON.stringify(error, errorReplacer),
        });
    }

    _isMalouError = (err: unknown): err is MalouError =>
        typeof err === 'object' &&
        err !== null &&
        err !== undefined &&
        'malouErrorCode' in err &&
        typeof err.malouErrorCode === 'string' &&
        err.malouErrorCode in MalouErrorCode &&
        'message' in err &&
        typeof err.message === 'string';
}
