import { z } from 'zod';

import { AspectRatio } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const getMediaForEditionPathValidator = z.object({
    mediaId: objectIdValidator,
});

export type GetMediaForEditionPathDto = z.infer<typeof getMediaForEditionPathValidator>;

export type GetMediaForEditionResponseDto = {
    id: string;
    thumbnail1024OutsideUrl: string;
    thumbnail256OutsideUrl: string;
    aspectRatio: number;
    transformData: {
        aspectRatio: AspectRatio;
        rotationInDegrees: number;
        left: number;
        top: number;
        width: number;
        height: number;
    };
};
