import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, signal, SimpleChanges, WritableSignal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MentionConfig } from 'angular-mentions';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { of, switchMap } from 'rxjs';

import { FeedbackMessageVisibility, MalouErrorCode, PlatformKey, PostType, Role, TimeInMilliseconds } from '@malou-io/package-utils';

import { FeedbacksService } from ':core/services/feedbacks.service';
import { ToastService } from ':core/services/toast.service';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { User } from ':modules/user/user';
import { TextAreaComponent } from ':shared/components/text-area/text-area.component';
import { ShowIfAdminDirective } from ':shared/directives/show-if-admin.directive';
import { formatDate } from ':shared/helpers';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { Post } from ':shared/models';
import { Feedback, FeedbackMessage, FeedbackMessageType, FeedbackUser } from ':shared/models/feedback';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

const USER_TAGGED_REGEX = '{USER}';
const FAKE_ID = 'fakeId';

@Component({
    selector: 'app-feedbacks-panel',
    templateUrl: './feedbacks-panel.component.html',
    styleUrls: ['./feedbacks-panel.component.scss'],
    standalone: true,
    imports: [
        MatProgressSpinnerModule,
        NgTemplateOutlet,
        NgClass,
        MatIconModule,
        MatCheckboxModule,
        FormsModule,
        ReactiveFormsModule,
        TextAreaComponent,
        MatButtonModule,
        ShowIfAdminDirective,
        TranslateModule,
        IllustrationPathResolverPipe,
        ApplyPurePipe,
        LazyLoadImageModule,
        ApplyPurePipe,
    ],
    providers: [IllustrationPathResolverPipe],
})
export class FeedbacksPanelComponent implements OnInit, OnChanges {
    @Input() post?: Post | null;
    @Input() shouldHideTitle = false;
    @Input() restaurantManagers: User[];
    @Output() updateDisplayedMessages = new EventEmitter<FeedbackMessage[]>();
    @Output() currentFeedbackSet = new EventEmitter<Feedback>();

    readonly SvgIcon = SvgIcon;
    feedback: WritableSignal<Feedback | undefined> = signal(undefined);
    currentUser$ = this._store.select(selectUserInfos);
    currentUser: User;
    editingMessageId: string | null;
    mentionConfig: MentionConfig;
    taggedUsers: FeedbackUser[] = [];
    adminVisibility = false;
    FeedbackMessageVisibility = FeedbackMessageVisibility;
    FeedbackMessageType = FeedbackMessageType;
    userRole: WritableSignal<Role> = signal(Role.MALOU_BASIC);
    loading = false;
    messagesForm: FormGroup<{
        newMessage: FormControl<string | null>;
        editingMessage: FormControl<string | null>;
    }> = this._fb.group({
        newMessage: '',
        editingMessage: '',
    });
    readonly trackByIdFn = TrackByFunctionFactory.get('_id');
    trackingId = '';

    constructor(
        private readonly _translate: TranslateService,
        private readonly _feedbacksService: FeedbacksService,
        private readonly _store: Store,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _toastService: ToastService,
        private readonly _fb: FormBuilder,
        private readonly _illustrationPathResolverPipe: IllustrationPathResolverPipe
    ) {}

    get newMessageVisibility(): FeedbackMessageVisibility {
        return this.adminVisibility ? FeedbackMessageVisibility.ADMIN : FeedbackMessageVisibility.BASIC;
    }

    get newMessage(): string {
        return this.messagesForm.get('newMessage')?.value ?? '';
    }

    get editingMessage(): string {
        return this.messagesForm.get('editingMessage')?.value ?? '';
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.post?.currentValue?._id) {
            this.initFeedback();
        }
    }

    ngOnInit(): void {
        this.trackingId = this.post?.isStory
            ? 'tracking_story_send_feedback'
            : this.post?.postType === PostType.REEL
              ? 'tracking_reel_send_feedback'
              : this.post?.keys?.[0] === PlatformKey.GMB
                ? 'tracking_seo_send_feedback'
                : 'tracking_social_send_feedback';
        this.mentionConfig = {
            mentions: [
                {
                    items: this.restaurantManagers,
                    labelKey: 'fullname',
                    dropUp: true,
                    mentionSelect: (user: User): string => {
                        this.taggedUsers.push({
                            ...user,
                            profilePictureUrl: user.profilePicture?.urls?.small as string,
                        });
                        return `@${user?.name} `;
                    },
                },
            ],
        };
        this.currentUser$.subscribe({
            next: (user: User) => {
                this.currentUser = user;
                this.userRole.set(user.role);
            },
        });
    }

    getDisplayedMessages = (feedback: Feedback | undefined, userRole: Role): FeedbackMessage[] => {
        const messages = feedback?.feedbackMessages?.filter((message) => this.shouldDisplayMessage(message, userRole)) || [];
        this.updateDisplayedMessages.emit(messages);
        return messages;
    };

    initFeedback(): void {
        if (!this.post) {
            return;
        }
        const feedback$ = this.post.feedbackId
            ? this._feedbacksService.getFeedback(this.post.feedbackId)
            : of({
                  data: new Feedback({
                      feedbackMessages: [],
                      isOpen: true,
                      postId: this.post._id,
                      participants: [],
                  }),
              });
        feedback$.subscribe({
            next: (res) => {
                this.feedback.set(res.data);
                if (this.post?.feedbackId) {
                    this.currentFeedbackSet.emit(this.feedback());
                }
                this._scrollFeedbacksToBottom();
            },
            error: (err) => {
                console.warn('err :>>', err);
                this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
            },
        });
    }

    shouldDisplayMessage(message: FeedbackMessage, userRole: Role): boolean {
        return message.visibility !== FeedbackMessageVisibility.ADMIN || userRole === Role.ADMIN;
    }

    isFromCurrentUser = (message: FeedbackMessage): boolean => message?.author?._id === this.currentUser?._id;

    getFormattedDate = (message: FeedbackMessage): string => formatDate(message?.createdAt);

    isMyMessage = (message: FeedbackMessage): boolean => this.currentUser._id === message.author._id;

    getMessageTextHtml = (message: FeedbackMessage): string => {
        const messageWords = message?.text?.split(' ');
        const messageWordsHtml = messageWords?.map((word) =>
            word.match(new RegExp(USER_TAGGED_REGEX)) ? `<strong>@${word.replace(USER_TAGGED_REGEX, '')}</strong>` : word
        );
        return messageWordsHtml?.join(' ') ?? '';
    };

    getUserPicture = (message: FeedbackMessage): string =>
        message?.author?.profilePictureUrl || this._illustrationPathResolverPipe.transform(Illustration.Karl);

    getFullname = (user: User | FeedbackUser): string => new User(user).getFullname();

    startEditingMessage(message: FeedbackMessage): void {
        this.taggedUsers = [];
        this.messagesForm.patchValue({ editingMessage: this._formatHtmlTextToTextareaValue(message?.text ?? '') });
        this.editingMessageId = message?._id;
    }

    cancelEditingMessage(): void {
        this.editingMessageId = null;
        this.messagesForm.patchValue({ editingMessage: null });
    }

    updateMessage(message: FeedbackMessage): void {
        if (!this.feedback) {
            return;
        }
        this.loading = true;
        this._feedbacksService
            .updateFeedbackMessage(
                this.feedback()!._id,
                message._id,
                { text: this._formatMessageToSend(this.editingMessage), visibility: message.visibility },
                this.taggedUsers.filter((user) => user._id !== FAKE_ID)
            )
            .subscribe({
                next: (res) => {
                    this.loading = false;
                    this.taggedUsers = [];
                    this.cancelEditingMessage();
                    this.feedback.set(res.data);
                    this.currentFeedbackSet.emit(this.feedback());
                },
                error: (err) => {
                    this.loading = false;
                    if (err.status === 403) {
                        this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                        return;
                    }
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                },
            });
    }

    deleteMessage(message: FeedbackMessage): void {
        if (!this.feedback) {
            return;
        }
        this.loading = true;
        this._feedbacksService.deleteFeedbackMessage(this.feedback()!._id, message._id).subscribe({
            next: (res) => {
                this.loading = false;
                this.feedback.set(res.data);
                this.currentFeedbackSet.emit(this.feedback());
            },
            error: (err) => {
                this.loading = false;
                if (err.status === 403) {
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                    return;
                }
                this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
            },
        });
    }

    changeMessageVisibility(message: FeedbackMessage): void {
        if (message.visibility === FeedbackMessageVisibility.ADMIN) {
            message.visibility = FeedbackMessageVisibility.BASIC;
            return;
        }
        if (message.visibility === FeedbackMessageVisibility.BASIC) {
            message.visibility = FeedbackMessageVisibility.ADMIN;
        }
    }

    createMessage(): void {
        if (!this.newMessage || !this.post) {
            return;
        }
        const messageToSend = this._formatMessageToSend(this.newMessage);
        const newFeedbackMessage = {
            author: {
                ...this.currentUser,
                profilePictureUrl: this.currentUser.profilePicture?.urls?.small as string,
            },
            createdAt: new Date(),
            type: FeedbackMessageType.TEXT,
            text: messageToSend,
            visibility: this.newMessageVisibility,
        };
        this.loading = true;

        const shouldCreateFeedbackFirst$ = this.feedback()?._id
            ? of({ data: this.feedback() })
            : this._feedbacksService.createFeedback(this.post._id);
        shouldCreateFeedbackFirst$
            .pipe(
                switchMap((res) => {
                    this.feedback.set(res.data);
                    return this._feedbacksService.addFeedbackMessage(
                        this.feedback()!._id,
                        newFeedbackMessage,
                        this.taggedUsers.filter((user) => user._id !== FAKE_ID)
                    );
                })
            )
            .subscribe({
                next: (res) => {
                    this.loading = false;
                    this.taggedUsers = [];
                    this.feedback.set(res.data);
                    this.currentFeedbackSet.emit(this.feedback());
                    this.messagesForm.patchValue({ newMessage: null });
                    this._scrollFeedbacksToBottom();
                },
                error: (err) => {
                    console.warn('err :>>', err);
                    this.loading = false;
                    if (err.status === 403) {
                        return;
                    }
                    if (err?.error?.malouErrorCode === MalouErrorCode.EMAIL_NOT_SENT) {
                        this.taggedUsers = [];
                        this.feedback()?.feedbackMessages.push({ _id: 'tempId', ...newFeedbackMessage });
                        this.messagesForm.patchValue({ newMessage: null });
                        this._scrollFeedbacksToBottom();
                        this._toastService.openErrorToast(
                            this._httpErrorPipe.transform(this._translate.instant('feedbacks.email_not_sent'))
                        );
                        return;
                    }
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                },
            });
    }

    toggleFeedbackStatus(): void {
        if (!this.feedback() || !this.post) {
            return;
        }
        const newFeedbackMessage = {
            author: {
                ...this.currentUser,
                profilePictureUrl: this.currentUser.profilePicture?.urls?.small as string,
            },
            createdAt: new Date(),
            type: this.feedback()!.isOpen ? FeedbackMessageType.CLOSE : FeedbackMessageType.REOPEN,
            visibility: FeedbackMessageVisibility.BASIC,
            text: '',
        };
        const lastMessage = this.feedback()!.feedbackMessages[this.feedback()!.feedbackMessages.length - 1];
        const shouldRemoveLastMessage = this._shouldRemoveLastMessage(lastMessage);

        const shouldCreateFeedbackFirst$ = this.feedback()?._id
            ? of({ data: this.feedback() })
            : this._feedbacksService.createFeedback(this.post._id);
        shouldCreateFeedbackFirst$
            .pipe(
                switchMap((res) => {
                    this.feedback.set(res.data);
                    return shouldRemoveLastMessage
                        ? this._feedbacksService.deleteFeedbackMessage(this.feedback()!._id, lastMessage._id, true)
                        : this._feedbacksService.addFeedbackMessage(this.feedback()!._id, newFeedbackMessage, []);
                }),
                switchMap(() => {
                    if (!this.feedback()) {
                        return of({ data: this.feedback() });
                    }
                    return this._feedbacksService.updateFeedback(this.feedback()!._id, { isOpen: !this.feedback()!.isOpen });
                })
            )
            .subscribe({
                next: (res) => {
                    this.feedback.set(res.data);
                    this.currentFeedbackSet.emit(this.feedback());
                },
                error: (err) => {
                    console.warn('err :>>', err);
                    if (err.status === 403) {
                        return;
                    }
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                },
            });
    }

    private _formatMessageToSend(message: string): string {
        let formattedMessage = message;
        const taggedUsersValue = this.taggedUsers.map((user) => `@${user.name}`);
        taggedUsersValue.forEach((taggedUserValue) => {
            formattedMessage = formattedMessage.replace(taggedUserValue, `${USER_TAGGED_REGEX}${taggedUserValue.replace('@', '')}`);
        });
        return formattedMessage;
    }

    private _formatHtmlTextToTextareaValue(text: string): string {
        return text
            ?.split(' ')
            ?.map((word) => {
                const cleanedName = word.replace(USER_TAGGED_REGEX, '');
                const fakeUserWithName = {
                    _id: FAKE_ID,
                    name: cleanedName,
                    lastname: null,
                    email: null,
                };
                this.taggedUsers.push(fakeUserWithName as unknown as FeedbackUser);
                return word.match(new RegExp(USER_TAGGED_REGEX)) ? `@${cleanedName}` : word;
            })
            ?.join(' ');
    }

    private _scrollFeedbacksToBottom(): void {
        setTimeout(() => {
            const feedbackMessagesList = document.getElementById('feedbackMessages');
            feedbackMessagesList?.scrollTo({
                top: feedbackMessagesList.scrollHeight,
                behavior: 'smooth',
            });
        }, 500);
    }

    private _shouldRemoveLastMessage(lastMessage): boolean {
        if (!lastMessage) {
            return false;
        }
        const tenMinutesInMs = 10 * TimeInMilliseconds.MINUTE;
        const isClosingFeedbackMessage = lastMessage.type !== FeedbackMessageType.TEXT;
        const isFromCurrentUser = lastMessage.author?._id === this.currentUser._id;
        const isWithinLastTenMinutes = new Date().getTime() - new Date(lastMessage.createdAt).getTime() < tenMinutesInMs;
        return isClosingFeedbackMessage && isFromCurrentUser && isWithinLastTenMinutes;
    }
}
