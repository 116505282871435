import { CdkDrag, CdkDragDrop, CdkDragEnter, CdkDropList, CdkDropListGroup, DragRef } from '@angular/cdk/drag-drop';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, input, OnInit, output, viewChild } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { catchError, combineLatest, EMPTY, filter, map, Observable, switchMap, take, tap } from 'rxjs';

import { SwapPlannedPublicationDatesPayloadDto } from '@malou-io/package-dto';
import { isNotNil, PlatformDefinitions, PlatformKey, PostPublicationStatus } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { PostsService } from ':core/services/posts.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { SocialPostMediaItemComponent } from ':modules/posts-v2/social-posts/components/social-posts-list/social-post-item/social-post-media-item/social-post-media-item.component';
import { FeedItem } from ':modules/posts-v2/social-posts/models/feed-item';
import { SocialPostsContext } from ':modules/posts-v2/social-posts/social-posts.context';
import { updateRefreshDates } from ':modules/posts/posts.actions';
import { selectRefreshDates } from ':modules/posts/posts.selectors';
import { SizeStrategy } from ':shared/components/media-item/media-item.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { shouldRefreshPost } from ':shared/helpers/should-refresh-post';
import { Platform, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';

interface PlatformsStore {
    platformsData: {
        [key: string]: Platform[];
    };
}

/** A preview of the Instagram feed */
@Component({
    selector: 'app-feed',
    templateUrl: './feed.component.html',
    styleUrls: ['./feed.component.scss'],
    standalone: true,
    imports: [
        CdkDrag,
        CdkDropList,
        CdkDropListGroup,
        NgClass,
        NgTemplateOutlet,
        MatButtonModule,
        MatCheckboxModule,
        MatTooltipModule,
        TranslateModule,
        SocialPostMediaItemComponent,
        SkeletonComponent,
        InfiniteScrollDirective,
        ApplyPurePipe,
        IllustrationPathResolverPipe,
        IncludesPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedComponent implements OnInit {
    readonly feed = input.required<FeedItem[]>();
    readonly shouldShowLoading = input.required<boolean>();
    readonly shouldShowLoadingMore = input.required<boolean>();
    readonly draggable = input.required<boolean>();

    readonly feedItemClicked = output<FeedItem>();

    readonly placeholder = viewChild<CdkDropList>(CdkDropList);

    private readonly _socialPostsContext = inject(SocialPostsContext);
    private readonly _postsService = inject(PostsService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _router = inject(Router);
    private readonly _store = inject(Store);
    private readonly _experimentationService = inject(ExperimentationService);

    readonly PostPublicationStatus = PostPublicationStatus;
    readonly SizeStrategy = SizeStrategy;

    private readonly _platformsStore$: Observable<PlatformsStore> = this._store.select((state) => state.platforms);
    private readonly _availablePlatforms$ = this._getAvailablePlatforms$();
    private readonly _availablePlatforms = toSignal(this._availablePlatforms$, { initialValue: [] });

    readonly isIgConnected = computed(() => this._availablePlatforms().some((p) => p === PlatformKey.INSTAGRAM));

    private _target: CdkDropList | null = null;
    private _targetIndex: number;
    private _source: CdkDropList | null = null;
    private _sourceIndex: number;
    private _dragRef: DragRef | null = null;

    private readonly _BOX_WIDTH = '200px';
    private readonly _BOX_HEIGHT = '200px';

    readonly isFeed45Enabled = toSignal(this._experimentationService.isFeatureEnabled$('release-feed-4-5'), {
        initialValue: this._experimentationService.isFeatureEnabled('release-feed-4-5'),
    });

    ngOnInit(): void {
        // Initial fetch
        this._socialPostsContext.goNextPageFeed();
    }

    onScroll(): void {
        const hasNextPage = this._socialPostsContext.hasNextPageFeed();
        if (hasNextPage) {
            this._socialPostsContext.goNextPageFeed();
        }
    }

    onRefreshPost(feedItem: FeedItem): void {
        this._store
            .select(selectRefreshDates)
            .pipe(
                take(1),
                map((dates) => dates[feedItem.postId]),
                switchMap((date) => {
                    if (shouldRefreshPost(date)) {
                        return this._postsService.refresh(feedItem.postId).pipe(
                            map((res) => res.data),
                            tap((_newPost) => {
                                // TODO with context
                                // this.igPosts.update((currentPosts) =>
                                //     currentPosts.map((currentPost) =>
                                //         currentPost.id === newPost.id ? currentPost.copyWith(newPost) : currentPost
                                //     )
                                // );
                                this._store.dispatch(updateRefreshDates({ postId: feedItem.postId }));
                            })
                        );
                    }
                    return EMPTY;
                })
            )
            .subscribe();
    }

    navigateToPlatforms(): void {
        this._router.navigate([`/restaurants/${this._restaurantsService.currentRestaurant._id}/settings/platforms`]);
    }

    cdkDropListEnterPredicate(drag: CdkDrag<FeedItem>, drop: CdkDropList<FeedItem>): boolean {
        return (
            drop.data.published !== PostPublicationStatus.PUBLISHED &&
            drop.data.getPostDate() > new Date() &&
            isNotNil(drag.data.plannedPublicationDate) &&
            new Date(drag.data.plannedPublicationDate).getTime() > Date.now()
        );
    }

    onDropListDropped(event: CdkDragDrop<FeedItem>): void {
        const movingPost: FeedItem = event?.item?.data;
        if (!movingPost) {
            return;
        }

        if (movingPost.published === PostPublicationStatus.DRAFT && movingPost.getPostDate().getTime() < Date.now()) {
            this._toastService.openErrorToast(this._translateService.instant('social-posts.old_drafts_cannot_be_dragged'));
        }

        try {
            if (!this._target || event?.container?.data?.published === PostPublicationStatus.PUBLISHED) {
                event.item.reset();
                return;
            }

            const placeholder = this.placeholder();
            if (!placeholder) {
                return;
            }

            const placeholderElement: HTMLElement = placeholder.element.nativeElement;
            const placeholderParentElement: HTMLElement = placeholderElement.parentElement as HTMLElement;

            placeholderElement.style.display = 'none';

            placeholderParentElement.removeChild(placeholderElement);
            placeholderParentElement.appendChild(placeholderElement);
            if (this._source) {
                placeholderParentElement.insertBefore(
                    this._source?.element?.nativeElement,
                    placeholderParentElement.children[this._sourceIndex]
                );
            }

            if (placeholder._dropListRef.isDragging() && this._dragRef) {
                placeholder._dropListRef.exit(this._dragRef);
            }

            this._target = null;
            this._source = null;
            this._dragRef = null;

            if (this._sourceIndex !== this._targetIndex) {
                this._updatePostsDatesBetweenIndexes(this._sourceIndex, this._targetIndex);
            }
        } catch (error) {
            console.error(error);
            event.item.reset();
        }
    }

    onDropListEntered({ item, container }: CdkDragEnter<FeedItem>): void {
        try {
            const placeholder = this.placeholder();
            if (!placeholder) {
                return;
            }
            if (container === placeholder || container.data.published === PostPublicationStatus.PUBLISHED) {
                item.reset();
                return;
            }

            const placeholderElement: HTMLElement = placeholder.element.nativeElement;
            const sourceElement: HTMLElement = item.dropContainer.element.nativeElement;
            const dropElement: HTMLElement = container.element.nativeElement;
            const dragIndex: number = Array.prototype.indexOf.call(
                dropElement.parentElement?.children,
                this._source ? placeholderElement : sourceElement
            );
            const dropIndex: number = Array.prototype.indexOf.call(dropElement.parentElement?.children, dropElement);

            if (!this._source) {
                this._sourceIndex = dragIndex;
                this._source = item.dropContainer;

                placeholderElement.style.width = this._BOX_WIDTH;
                placeholderElement.style.height = this._BOX_HEIGHT;
                placeholderElement.style.aspectRatio = '1/1';

                sourceElement.parentElement?.removeChild(sourceElement);
            }

            this._targetIndex = dropIndex;
            this._target = container;
            this._dragRef = item._dragRef;

            placeholderElement.style.display = '';

            dropElement.parentElement?.insertBefore(placeholderElement, dropIndex > dragIndex ? dropElement.nextSibling : dropElement);

            placeholder._dropListRef.enter(item._dragRef, item.element.nativeElement.offsetLeft, item.element.nativeElement.offsetTop);
        } catch (e) {
            item.reset();
        }
    }

    onFeedItemClicked(feedItem: FeedItem): void {
        this.feedItemClicked.emit(feedItem);
    }

    getFeedItemStatusIcon = (feedItem: FeedItem): SvgIcon | undefined => {
        switch (feedItem.published) {
            case PostPublicationStatus.PENDING:
                return SvgIcon.ALARM;
            case PostPublicationStatus.DRAFT:
                return SvgIcon.DRAFT;
            case PostPublicationStatus.ERROR:
                return SvgIcon.CROSS;
            default:
                return undefined;
        }
    };

    /** Reorder unpublished posts by updating plannedPublicationDate (drag and drop) */
    private _updatePostsDatesBetweenIndexes(sourceIndex: number, destinationIndex: number): void {
        if (sourceIndex === destinationIndex) {
            return;
        }

        const currentFeed = this.feed();

        const reassign: SwapPlannedPublicationDatesPayloadDto['reassign'] = [];
        const updatedFeedItems: FeedItem[] = [];

        for (let i = 0; i < currentFeed.length; i++) {
            const feedItem = currentFeed[i];
            // Sets feedItem.plannedPublicationDate from the plannedPublicationDate of the given feedItem
            const setPublicationDate = (sourceFeedItem: FeedItem): void => {
                // to send the update to the server:
                reassign.push({ destinationPostId: feedItem.postId, sourcePostId: sourceFeedItem.postId });

                // to show the update immediately to the user:
                updatedFeedItems.push(feedItem.copyWith({ plannedPublicationDate: sourceFeedItem.plannedPublicationDate }));
            };

            if (i === sourceIndex) {
                setPublicationDate(currentFeed[destinationIndex]);
            } else if (i > sourceIndex && i <= destinationIndex) {
                setPublicationDate(currentFeed[i - 1]);
            } else if (i >= destinationIndex && i < sourceIndex) {
                setPublicationDate(currentFeed[i + 1]);
            }
        }

        if (reassign.length !== Math.abs(sourceIndex - destinationIndex) + 1) {
            throw new Error('should not happen');
        }

        // TODO maybe in social post context ?
        // to send the update to the server:
        // this._postsService.swapPlannedPublicationDates({ reassign }).subscribe({
        //     error: (err) => {
        //         this._toastService.openErrorToast(
        //             this._translateService.instant('common.unknown_error') + this._httpErrorPipe.transform(err)
        //         );
        //     },
        // });

        // TODO same as above
        // to show the update immediately to the user:
        // this._socialPostsService.editPosts(updatedFeedItems);
    }

    private _getAvailablePlatforms$(): Observable<PlatformKey[]> {
        return combineLatest([this._platformsStore$, this._restaurantsService.restaurantSelected$]).pipe(
            filter(([platforms, restaurant]) => !!restaurant && !!platforms.platformsData[restaurant._id]),
            map(([platforms, restaurant]: [PlatformsStore, Restaurant]) => {
                const socialPostsPlatforms = PlatformDefinitions.getPlatformKeysWithFeed();
                const connectedPlatforms = platforms.platformsData[restaurant._id].map((plat) => plat.key);
                return socialPostsPlatforms.filter((platform) => connectedPlatforms.includes(platform));
            }),
            catchError((err) => {
                console.warn('err :>> ', err);
                return [];
            }),
            takeUntilDestroyed(this._destroyRef)
        );
    }
}
