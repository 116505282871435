import { ChangeDetectionStrategy, Component, computed, inject, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { PostDatePickerComponent } from ':modules/posts-v2/post-date-picker/post-date-picker.component';
import { PostDatePickerSize } from ':modules/posts-v2/post-date-picker/post-date-picker.interface';
import { UpsertSocialPostContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post.context';
import { MenuButtonV2Component } from ':shared/components/menu-button-v2/menu-button-v2.component';
import { MenuButtonSize } from ':shared/components/menu-button-v2/menu-button-v2.interface';

@Component({
    selector: 'app-upsert-social-post-modal-footer',
    templateUrl: './upsert-social-post-modal-footer.component.html',
    styleUrls: ['./upsert-social-post-modal-footer.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, MatTooltipModule, TranslateModule, MenuButtonV2Component, PostDatePickerComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpsertSocialPostModalFooterComponent {
    // TODO maybe not an input but directly from the context?
    readonly selectedDate = input<Date | null>(new Date());
    readonly cancel = output<void>();

    private readonly _upsertSocialPostContext = inject(UpsertSocialPostContext);

    readonly MenuButtonSize = MenuButtonSize;
    readonly PostDatePickerSize = PostDatePickerSize;

    readonly postErrors = this._upsertSocialPostContext.postErrors;
    readonly postErrorsMessage = computed(() => this.postErrors().join('\n'));

    onCancel(): void {
        this.cancel.emit();
    }
}
