import { FeedItemDto } from '@malou-io/package-dto';
import { PostPublicationStatus, RemoveMethodsFromClass } from '@malou-io/package-utils';

import { SocialPostMedia } from ':modules/posts-v2/social-posts/models/social-post-media';

export type IFeedItem = RemoveMethodsFromClass<FeedItem> & { updatedAt: Date };

export class FeedItem {
    postId: string;
    thumbnail: SocialPostMedia | null;
    published: PostPublicationStatus;
    plannedPublicationDate: Date | null;
    socialCreatedAt?: Date;
    updatedAt: Date;

    constructor(data: IFeedItem) {
        this.postId = data.postId;
        this.thumbnail = data.thumbnail;
        this.published = data.published;
        this.plannedPublicationDate = data.plannedPublicationDate;
        this.socialCreatedAt = data.socialCreatedAt;
        this.updatedAt = data.updatedAt;
    }

    copyWith(data: Partial<IFeedItem>): FeedItem {
        return new FeedItem({ ...this, ...data });
    }

    getPostDate(): Date {
        return new Date(this.socialCreatedAt ?? this.plannedPublicationDate ?? this.updatedAt);
    }

    static fromDto(dto: FeedItemDto): FeedItem {
        return new FeedItem({
            postId: dto.postId,
            thumbnail: dto.thumbnail ? new SocialPostMedia(dto.thumbnail) : null,
            published: dto.published,
            plannedPublicationDate: dto.plannedPublicationDate ? new Date(dto.plannedPublicationDate) : null,
            socialCreatedAt: dto.socialCreatedAt ? new Date(dto.socialCreatedAt) : undefined,
            updatedAt: new Date(dto.updatedAt),
        });
    }
}
