import { computed, inject, Injectable, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { filter, Observable, Subject } from 'rxjs';

import { isNotNil, SocialPostsListFilter } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { FeedItem } from ':modules/posts-v2/social-posts/models/feed-item';
import { SocialPostItem } from ':modules/posts-v2/social-posts/models/social-post-item';
import { SocialPostsV2Service } from ':modules/posts-v2/social-posts/social-posts.service';
import { Restaurant } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class SocialPostsContext {
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _socialPostsService = inject(SocialPostsV2Service);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);

    readonly posts = signal<SocialPostItem[]>([]);
    readonly isFetchingPosts = signal<boolean>(false);
    readonly isFetchingMorePosts = signal<boolean>(false);
    readonly hasFetchedPostsAtLeastOnce = signal<boolean>(false);
    readonly selectedFilter = signal<SocialPostsListFilter>(SocialPostsListFilter.ALL);
    private readonly _socialPostsCursor = signal<string | null>(null);
    readonly hasNextPage = computed(() => this._socialPostsCursor() !== null);
    readonly fetchNextPage$ = new Subject<void>();

    readonly feed = signal<FeedItem[]>([]);
    readonly isFetchingFeed = signal<boolean>(false);
    readonly isFetchingMoreFeed = signal<boolean>(false);
    private readonly _feedCursor = signal<string | null>(null);
    readonly hasNextPageFeed = computed(() => this._feedCursor() !== null);
    readonly fetchFeedNextPage$ = new Subject<void>();

    readonly restaurant$: Observable<Restaurant> = this._restaurantsService.restaurantSelected$.pipe(filter(isNotNil));
    readonly restaurant = toSignal(this.restaurant$, { initialValue: this._restaurantsService.currentRestaurant });

    readonly restaurantHasNoPost = computed(
        (): boolean =>
            this.hasFetchedPostsAtLeastOnce() && this.posts().length === 0 && !this.isFetchingPosts() && !this.isFetchingMorePosts()
    );

    init(): void {
        this.hasFetchedPostsAtLeastOnce.set(false);
    }

    fetchPosts(postsFilter: SocialPostsListFilter, restaurantId: string, limit: number): void {
        const cursor = this._socialPostsCursor();
        this.isFetchingPosts.set(cursor === null);
        this.isFetchingMorePosts.set(cursor !== null);

        this._socialPostsService.getSocialPosts$(postsFilter, cursor, restaurantId, limit).subscribe(({ socialPostItems, nextCursor }) => {
            const newPosts = socialPostItems.map((post) => SocialPostItem.fromDto(post));
            if (cursor === null) {
                this.posts.set(newPosts);
            } else {
                this.posts.update((currentPosts) => [...currentPosts, ...newPosts]);
            }
            this.isFetchingPosts.set(false);
            this.isFetchingMorePosts.set(false);
            this.hasFetchedPostsAtLeastOnce.set(true);
            this._socialPostsCursor.set(nextCursor);
        });
    }

    fetchFeed(restaurantId: string, limit: number): void {
        const feedCursor = this._feedCursor();
        this.isFetchingFeed.set(feedCursor === null);
        this.isFetchingMoreFeed.set(feedCursor !== null);

        this._socialPostsService.getFeed$(restaurantId, feedCursor, limit).subscribe(({ feed, nextCursor }) => {
            const newFeed = feed.map((feedItem) => FeedItem.fromDto(feedItem));
            if (feedCursor === null) {
                this.feed.set(newFeed);
            } else {
                this.feed.update((currentFeed) => [...currentFeed, ...newFeed]);
            }
            this.isFetchingFeed.set(false);
            this.isFetchingMoreFeed.set(false);
            this._feedCursor.set(nextCursor);
        });
    }

    deleteSocialPost(postId: string): void {
        const postToDelete = this.posts().find((p) => p.id === postId);
        if (!postToDelete) {
            return;
        }

        // Optimistic update
        this.posts.update((currentPosts) => currentPosts.filter((post) => post.id !== postId));
        this._socialPostsService.deleteSocialPost$(postId).subscribe((result) => {
            if (!result.success) {
                // Revert optimistic update
                this.posts.update((currentPosts) =>
                    [...currentPosts, postToDelete].sort(
                        (a, b) => (b.getPostDate() ?? new Date()).getTime() - (a.getPostDate() ?? new Date()).getTime()
                    )
                );

                this._toastService.openErrorToast(this._translateService.instant('social_posts.error_delete_post'));
            }
        });
    }

    resetPagination(): void {
        this._socialPostsCursor.set(null);
        this.goNextPage();
    }

    resetFilter(): void {
        this.selectedFilter.set(SocialPostsListFilter.ALL);
    }

    resetFeedPagination(): void {
        this._feedCursor.set(null);
        this.goNextPageFeed();
    }

    goNextPage(): void {
        this.fetchNextPage$.next();
    }

    goNextPageFeed(): void {
        this.fetchFeedNextPage$.next();
    }
}
