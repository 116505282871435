<div class="flex h-full w-full flex-col border-y !border-malou-color-border-primary">
    <div class="flex h-full bg-malou-color-background-light">
        <div class="hide-scrollbar mx-8 mt-6 flex flex-[0_0_38%] flex-col gap-4 overflow-y-auto">
            <form
                class="flex flex-col rounded-[10px] border border-malou-color-border-primary bg-white p-5"
                [formGroup]="customizedDatePostForm">
                <div class="flex items-center justify-between">
                    <span class="malou-text-13--bold text-malou-color-text-1">{{
                        'posts.duplicate_post_modal.post_all_same_time' | translate
                    }}</span>
                    <app-slide-toggle [checked]="willPostAllAtSameTime()" (onToggle)="toggleWillPostAllAtSameTime()"></app-slide-toggle>
                </div>

                @if (willPostAllAtSameTime()) {
                    <div class="mt-4 flex gap-2">
                        <app-select
                            class="flex-1"
                            formControlName="status"
                            [displayWith]="statusDisplayWith"
                            [values]="PostDateStatus"
                            [inputReadOnly]="true">
                        </app-select>
                        <app-input-date-picker
                            class="max-w-40"
                            formControlName="plannedPublicationDate"
                            [ngClass]="{ invisible: (statusNowIsSelected$ | async) }"
                            [ownInputValidation]="true"
                            [min]="MIN_DATE"></app-input-date-picker>
                        <div class="flex flex-col" [ngClass]="{ invisible: (statusNowIsSelected$ | async) }">
                            <mat-select
                                class="!m-0 mt-8 !h-0 !opacity-0"
                                panelClass="malou-select-panel"
                                formControlName="postTime"
                                [hideSingleSelectionIndicator]="true"
                                (selectionChange)="changeSelectedTime($event)">
                                @for (time of TIMES; track time) {
                                    <mat-option
                                        [value]="time"
                                        [disabled]="isPastHour | applyPure: { hourWithMinute: time, date: plannedPublicationDate }">
                                        {{ time | formatTime: currentLang() === 'en' }}
                                    </mat-option>
                                }
                            </mat-select>
                            <app-input-text
                                class="grow"
                                formControlName="postTime"
                                inputType="time"
                                [svgIcon]="SvgIcon.CLOCK"></app-input-text>
                        </div>
                    </div>
                    @if (customizedDatePostForm.errors?.publicationDateInTheFuture) {
                        <span class="malou-text-10 malou-color-state-error py-1 pl-1 italic"> {{ 'common.date_past' | translate }} </span>
                    }
                }
            </form>
            <app-keep-same-post-caption-toggle
                [(shouldKeepSameCaptionForAllPosts)]="shouldKeepSameCaptionForAllPosts"
                [disabled]="isLoading() || !isLoaderMinDurationReached()"></app-keep-same-post-caption-toggle>
            <div class="flex flex-col rounded-[10px] bg-malou-color-background-dark">
                <span class="malou-text-13--bold m-4 text-malou-color-text-1">{{
                    'posts.duplicate_post_modal.original_post' | translate
                }}</span>

                <div class="flex gap-4 border-y-[1px] border-white px-4 pb-5 pt-4">
                    <img
                        class="h-20 w-20 rounded-lg object-cover"
                        alt="Image du post"
                        [defaultImage]="'default-picture-grey' | imagePathResolver"
                        [lazyLoad]="
                            (sharedData.postToDuplicate | applySelfPure: 'getSocialMediaUrl') ||
                            (sharedData.postToDuplicate | applySelfPure: 'getMalouMediaUrl')
                        " />
                    <div class="flex w-[calc(100%-5.25rem)] flex-col">
                        <div
                            class="malou-text-10--regular italic text-malou-color-text-2"
                            [innerHTML]="originalPostCaptionHighlighted()"></div>
                        @if (hasCallToAction()) {
                            <div class="mt-2 flex">
                                <span class="malou-text-10--semibold pr-1">{{ 'common.button' | translate }} </span>
                                <span class="malou-text-10 pr-1"
                                    >"{{ sharedData.postToDuplicate.callToAction.actionType | enumTranslate: 'call_to_action_type' }}"</span
                                >
                                @if (sharedData.postToDuplicate.callToAction.url) {
                                    <span class="malou-text-10 max-w-[70%] truncate pr-1 italic"
                                        >{{ '(' }} {{ sharedData.postToDuplicate.callToAction.url }}</span
                                    >
                                    <span class="malou-text-10 italic">{{ ')' }}</span>
                                }
                            </div>
                        }
                    </div>
                </div>

                <app-keywords-score-gauge
                    class="px-5 py-5"
                    [text$]="originalPostCaption$"
                    [restaurant$]="restaurant$"
                    [textType$]="textType$"
                    [keywords$]="sharedData.restaurantKeywords$"
                    [lang$]="postLang$"
                    [shouldDisplayKeywords]="true"
                    [shouldDisplayKeywordsInTooltip]="true"
                    [shouldOnlyDisplayScore]="false"
                    [withLargeDetails]="false"
                    [shouldDisplayTips]="true"
                    [reverseTipsAndKeywords]="true"
                    #keywordsScoreGauge></app-keywords-score-gauge>
            </div>
        </div>
        <mat-divider class="!border-malou-color-border-primary" [vertical]="true"></mat-divider>
        <div class="flex w-full flex-1 flex-col overflow-y-auto bg-white">
            @if (isLoading() || !isLoaderMinDurationReached()) {
                <ng-container [ngTemplateOutlet]="loadingTemplate"></ng-container>
            } @else if (isCaptionsGenerationError()) {
                <ng-container [ngTemplateOutlet]="errorCaptionsGeneration"></ng-container>
            } @else {
                <ng-container [ngTemplateOutlet]="postCaptionsList"></ng-container>
            }
        </div>
    </div>
</div>

<ng-template #loadingTemplate>
    <div class="m-4 flex h-full items-center justify-center overflow-x-hidden">
        <app-multiple-steps-loader [steps]="loaderSteps"></app-multiple-steps-loader>
    </div>
</ng-template>

<ng-template #errorCaptionsGeneration>
    <div class="flex h-[442px] flex-col items-center justify-center py-6">
        <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
        <span class="malou-text-14--bold mb-2">{{ 'common.unknown_error' | translate }}</span>
    </div>
</ng-template>

<ng-template #postCaptionsList>
    @for (data of restaurantsData(); track data.restaurant._id; let isLast = $last; let index = $index) {
        <app-duplicate-single-post-card
            [shouldDisplayDateForm]="!willPostAllAtSameTime()"
            [restaurantsData]="data"
            [restaurantIndex]="index"
            [postDateForm]="newPostsDateForms[index]"
            [postLang$]="postLang$"
            [shouldDisableCallToActionUrlInput]="!missingCallToActionUrlError()(data.restaurant) && shouldDisableCallToActionUrlInput()"
            [initialCaption]="initialRestaurantsHtmlCaptions()[index]"
            [callToActionUrl]="data.postCallToActionUrl"
            [missingCallToActionUrlError]="missingCallToActionUrlError()(data.restaurant)"
            [shouldKeepSameCaptionForAllPosts]="shouldKeepSameCaptionForAllPosts()"
            (onUpdateRestaurantsData)="onUpdateRestaurantsData($event)"></app-duplicate-single-post-card>
        @if (!isLast) {
            <mat-divider class="!mx-5 !border-malou-color-border-primary"></mat-divider>
        }
    }
</ng-template>
